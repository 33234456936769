/* -------------------------------------------------------------- */
/*******************************************************/
/******************* 01. Default Style *****************/
/*******************************************************/
* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  
  body {
    color: #696e7b;
    background: white;
    font-weight: 400;
    line-height: 32px;
    font-size: 16px;
    font-family: "Roboto", sans-serif; }
  
  a {
    color: #696e7b;
    cursor: pointer;
    outline: none;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none; }
    a:hover, a:focus, a:visited {
      text-decoration: none;
      outline: none; }
    a:hover {
      color: #fc653c; }
  
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #293043; }
  
  .text-white h1,
  .text-white h2,
  .text-white h3,
  .text-white h4,
  .text-white h5,
  .text-white h6,
  .text-white h1 a,
  .text-white h2 a,
  .text-white h3 a,
  .text-white h4 a,
  .text-white h5 a,
  .text-white h6 a {
    color: white; }
  
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0; }
  
  img {
    max-width: 100%;
    display: inline-block; }
  
  mark {
    color: #01c9f5;
    background: transparent;
    text-decoration: underline; }
  
  header:after, section:after, footer:after {
    display: block;
    clear: both;
    content: ""; }
  
  /*======= Input Styles =======*/
  input,
  select,
  textarea,
  .nice-select,
  .form-control {
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 18px 30px;
    background-color: #fff;
    border: 1px solid rgba(0, 102, 255, 0.1); }
  
  .nice-select {
    font-size: 18px;
    line-height: 1.3; }
    .nice-select:after {
      height: 8px;
      width: 8px;
      right: 25px;
      border-color: #293043; }
    .nice-select .list {
      width: 100%;
      border-radius: 0; }
  
  textarea {
    display: inherit;
    padding-top: 20px; }
  
  label {
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 5px;
    color: #696e7b; }
  
  .form-group {
    position: relative;
    margin-bottom: 20px; }
  
  input:focus,
  button:focus,
  .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #cfdbf1; }
  
  input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0; }
  
  input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }
  
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
    display: none; }
  
  input[type=checkbox], input[type=radio] {
    height: auto;
    width: auto; }
  
  /* For Scroll Animation */
  html {
    scroll-behavior: unset !important; }
  
  /*******************************************************/
  /*****************  02. Common Classes *****************/
  /*******************************************************/
  .page-wrapper {
    position: relative;
    z-index: 9;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    min-width: 300px; }
  
  @media only screen and (min-width: 1200px) {
    .container {
      max-width: 1320px;
      padding-left: 15px;
      padding-right: 15px; } }
  
  @media only screen and (min-width: 1400px) {
    .container-fluid.gap-wide {
      padding-left: 55px;
      padding-right: 55px; } }
  
  .no-gap {
    margin-left: 0;
    margin-right: 0; }
    .no-gap > div {
      padding-left: 0;
      padding-right: 0; }
  
  .small-gap {
    margin-left: -5px;
    margin-right: -5px; }
    .small-gap > div {
      padding-left: 5px;
      padding-right: 5px; }
  
  @media only screen and (min-width: 1400px) {
    .medium-gap {
      margin-left: -32.5px;
      margin-right: -32.5px; }
      .medium-gap > div {
        padding-left: 32.5px;
        padding-right: 32.5px; } }
  
  @media only screen and (min-width: 1400px) {
    .large-gap {
      margin-left: -50px;
      margin-right: -50px; }
      .large-gap > div {
        padding-left: 50px;
        padding-right: 50px; } }
  
  @media only screen and (max-width: 375px) {
    .col-small {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  
  .row {
    --bs-gutter-x: 30px; }
  
  @media only screen and (min-width: 1200px) {
    .row-cols-xl-7 > * {
      width: 14.2857%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto; } }
  
  /** Section Title style **/
  .section-title {
    margin-top: -7px; }
    .section-title .sub-title {
      font-size: 18px;
      font-weight: 700;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      color: #fc653c;
      text-decoration: underline; }
      .section-title .sub-title:after, .section-title .sub-title:before {
        float: left;
        line-height: 0;
        font-size: 40px;
        font-weight: 300;
        content: "\2026";
        margin-bottom: 1rem;
        font-family: 'Font Awesome 5 Pro'; }
      .section-title .sub-title:before {
        margin-right: 20px; }
      .section-title .sub-title:after {
        margin-left: 20px;
        display: none; }
      .section-title .sub-title.style-two {
        color: #0066ff;
        text-decoration: none; }
        .section-title .sub-title.style-two:before {
          content: url("../public/images/shapes/section-subtitle-line.png");
          margin: -15px 15px 0 -25px; }
        .section-title .sub-title.style-two:after {
          display: none; }
    .section-title h2 {
      margin-bottom: 25px; }
      @media only screen and (max-width: 767px) {
        .section-title h2 {
          font-size: 35px; } }
      @media only screen and (max-width: 479px) {
        .section-title h2 {
          font-size: 30px;
          line-height: 1.3; } }
    .section-title.text-center .sub-title:after {
      display: block; }
    .section-title.text-center .sub-title.style-two:after, .section-title.text-center .sub-title.style-two:before {
      display: none; }
  
  /** Button style **/
  .theme-btn,
  a.theme-btn {
    z-index: 1;
    color: white;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-weight: 700;
    border-radius: 5px;
    text-align: center;
    padding: 12px 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    background: #fc653c;
    border-radius: 27px;
    font-family: "Catamaran", sans-serif;
}
.theme-btn2,
a.theme-btn2 {
  z-index: 1;
  color: white;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
  padding: 12px 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize;
  background: #c8ad94 !important;
  border-radius: 27px;
  font-family: "Catamaran", sans-serif;
}
    .theme-btn i,
    a.theme-btn i {
      font-size: 13px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-left: 10px; }
    .theme-btn:after,
    a.theme-btn:after {
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      top: 0;
      -webkit-transition: 0.75s;
      -o-transition: 0.75s;
      transition: 0.75s;
      position: absolute;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
      background: #000; }
    .theme-btn:hover:after,
    a.theme-btn:hover:after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .theme-btn:hover i,
    a.theme-btn:hover i {
      margin-left: 13px;
      margin-right: -3px; }
      .theme-btn2 i,
    a.theme-btn2 i {
      font-size: 13px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-left: 10px; }
    .theme-btn2:after,
    a.theme-btn2:after {
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      left: 0;
      top: 0;
      -webkit-transition: 0.75s;
      -o-transition: 0.75s;
      transition: 0.75s;
      position: absolute;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
      background: #c1a184; }
    .theme-btn2:hover:after,
    a.theme-btn2:hover:after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
    .theme-btn2:hover i,
    a.theme-btn2:hover i {
      margin-left: 13px;
      margin-right: -3px; }
      .theme-btn.style-two{
        border-radius: 5px;
      }
    .theme-btn.style-two,
    a.theme-btn.style-two {
      background: #01c9f5; }
      .theme-btn.style-two:after,
      a.theme-btn.style-two:after {
        background: #0066ff; }
        .theme-btn.style-two2{
          border-radius: 5px;
          border: 1px solid rgba(0, 102, 255, 0.2);
        }
        .theme-btn.style-two2,
        a.theme-btn.style-two2 {
          background: transparent;
        color: #293043; }
          .theme-btn.style-two2:after,
          a.theme-btn.style-two2:after {
            color: #fff;
            background: #0066ff; }
            a.theme-btn.style-two2:hover{
              color: #fff;
            }
    .theme-btn.style-three,
    a.theme-btn.style-three {
      color: #293043;
      background: transparent;
      border: 1px solid rgba(0, 102, 255, 0.2); }
      @media only screen and (min-width: 576px) {
        .theme-btn.style-three,
        a.theme-btn.style-three {
          padding-top: 10px;
          padding-bottom: 10px; } }
      .theme-btn.style-three:after,
      a.theme-btn.style-three:after {
        background: #fc653c; }
      .theme-btn.style-three:hover,
      a.theme-btn.style-three:hover {
        color: white;
        border-color: #fc653c; }
      .theme-btn.style-three.white-btn,
      a.theme-btn.style-three.white-btn {
        color: white;
        border-color: white; }
    @media only screen and (max-width: 575px) {
      .theme-btn,
      a.theme-btn {
        padding: 9px 25px; } }
  
  /* Details Btn */
  .details-btn {
    font-size: 22px;
    width: 70px;
    height: 70px;
    background: white;
    line-height: 70px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    color: #fc653c; }
    @media only screen and (max-width: 479px) {
      .details-btn {
        width: 45px;
        height: 45px;
        font-size: 16px;
        line-height: 45px; } }

.details-btn:hover{
    color: #fff;
    background-color: #fc653c ;
}       
  
  /* Read More */
  .read-more {
    font-weight: 500;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-decoration: underline;
    text-transform: capitalize; }
    .read-more i {
      float: right;
      font-weight: 400;
      margin-left: 7px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
    .read-more:hover {
      color: #01c9f5; }
      .read-more:hover i {
        margin-left: 10px; }
  
  /* List style One */
  .list-style-one li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .list-style-one li:not(:last-child) {
      margin-bottom: 25px; }
    .list-style-one li:before {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      margin-top: 3px;
      font-size: 18px;
      font-weight: 600;
      content: "\f00c";
      margin-right: 25px;
      width: 45px;
      height: 45px;
      background: #fc653c;
      line-height: 45px;
      border-radius: 50%;
      text-align: center;
      font-family: 'Font Awesome 5 Pro'; }
      @media only screen and (max-width: 375px) {
        .list-style-one li:before {
          width: 40px;
          height: 40px;
          margin-top: 5px;
          font-size: 14px;
          line-height: 40px;
          margin-right: 15px; } }
    @media only screen and (min-width: 376px) {
      .list-style-one li .content h5 {
        font-size: 20px; } }
    .list-style-one li .content p {
      margin-bottom: 0; }
  
  /* List style Two */
  .list-style-two li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .list-style-two li:not(:last-child) {
      margin-bottom: 10px; }
    .list-style-two li:before {
      width: 5px;
      height: 5px;
      content: '';
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      margin-top: 13px;
      margin-right: 15px;
      border-radius: 50%;
      background: #fc653c; }
  
  /* List style Three */
  .list-style-three li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .list-style-three li:not(:last-child) {
      margin-bottom: 10px; }
    .list-style-three li:before {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      content: "\f00c";
      margin-right: 11px;
      color: #ff8a00;
      font-family: 'Font Awesome 5 Pro'; }
  
  /* List style Four */
  .list-style-four li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (min-width: 376px) {
      .list-style-four li {
        font-size: 18px; } }
    .list-style-four li:not(:last-child) {
      margin-bottom: 10px; }
    .list-style-four li:before {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: 18px;
      height: 18px;
      font-size: 10px;
      margin-top: 7px;
      font-weight: 600;
      content: "\f00c";
      line-height: 14px;
      border: 2px solid;
      text-align: center;
      margin-right: 15px;
      border-radius: 50%;
      color: #0066ff;
      font-family: 'Font Awesome 5 Pro'; }
  
  /** Social Link One **/
  .social-style-one {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -5px;
    margin-right: -5px; }
    .social-style-one a {
      width: 40px;
      height: 40px;
      background: white;
      line-height: 40px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid rgba(0, 102, 255, 0.1);
      margin-left: 5px;
      margin-right: 5px; }
      .social-style-one a:hover {
        color: white;
        background: #fc653c;
        border-color: #fc653c; }
  
  /** Social Link Two **/
  .social-style-two {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -5px;
    margin-right: -5px; }
    .social-style-two a {
      margin: 10px 5px 0;
      width: 45px;
      height: 45px;
      background: white;
      line-height: 45px;
      border-radius: 50%;
      text-align: center;
      color: #fc653c;
      -webkit-box-shadow: 10px 0 30px rgba(175, 175, 175, 0.3);
      box-shadow: 10px 0 30px rgba(175, 175, 175, 0.3); }
      .social-style-two a:hover {
        color: white;
        background: #fc653c; }
  
  /** Social Link Three **/
  .social-style-three {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -7.5px;
    margin-right: -7.5px; }
    .social-style-three a {
      margin-left: 7.5px;
      margin-right: 7.5px; }
  
  /*** Preloader style ** */
  .preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../public/images/preloader.gif"); }
  
  /* Pagination */
  .pagination {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: -5px;
    margin-right: -5px; }
    .pagination li {
      margin: 10px 5px 0; }
      .pagination li a,
      .pagination li .page-link {
        padding: 0;
        width: 45px;
        height: 45px;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-weight: 600;
        line-height: 43px;
        text-align: center;
        border-radius: 50%;
        color: #293043;
        border: 2px solid rgba(0, 102, 255, 0.1); }
      .pagination li.disabled .page-link, .pagination li:last-child .page-link {
        border-radius: 50%; }
      .pagination li.active .page-link, .pagination li:hover:not(.disabled) .page-link {
        color: white;
        background: #ff8a00;
        border-color: #ff8a00; }
      .pagination li.dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid rgba(0, 102, 255, 0.1); }
  
  /* Rating */
  .ratting {
    line-height: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    .ratting i {
      margin: 3px;
      color: #f1b000;
      font-size: 14px; }
  
  /*** Slick Dots ***/
  .slick-arrow {
    width: 55px;
    height: 55px;
    font-size: 18px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    border-radius: 50%;
    color: #fc653c;
    -webkit-box-shadow: 10px 0 60px rgba(109, 109, 109, 0.2);
    box-shadow: 10px 0 60px rgba(109, 109, 109, 0.2); }
    .slick-arrow:focus, .slick-arrow:hover {
      color: white;
      background: #fc653c;
      -webkit-box-shadow: 10px 0 60px rgba(118, 167, 19, 0.3);
      box-shadow: 10px 0 60px rgba(118, 167, 19, 0.3); }
  
  /*** Slick Dots ***/
  .slick-dots {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .slick-dots li {
      position: relative;
      cursor: pointer;
      width: 10px !important;
      height: 10px !important;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin: 10px 5px 0;
      border-radius: 50%;
      background: transparent;
      border: 2px solid #fc653c; }
      .slick-dots li button {
        opacity: 0; }
      .slick-dots li.slick-active {
        background: #fc653c; }
  
  /*** Scroll Top style ***/
  .scroll-top {
    position: fixed;
    bottom: 25px;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 99;
    color: white;
    display: none;
    font-size: 14px;
    cursor: pointer;
    line-height: 40px;
    border-radius: 5px;
    background: #0066ff;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite; }
  
  /* Text White */
  .text-white , .text-white a,
  .text-white .count-text,
  .text-white .footer-newsletter-content .sub-title {
    color: white; }
  
  .text-white li li .dropdown-btn span {
    color: #293043; }
  
  .text-white .copyright-area p {
    opacity: 0.5; }
  
  .text-white .copyright-area a {
    opacity: 0.5; }
    .text-white .copyright-area a:hover {
      opacity: 1; }
  
  /* Video Play */
  .video-play {
    color: white;
    font-size: 18px;
    display: inline-block;
    width: 90px;
    height: 90px;
    background: #fc653c;
    line-height: 90px;
    border-radius: 50%;
    text-align: center; }
    @media only screen and (max-width: 575px) {
      .video-play {
        width: 70px;
        height: 70px;
        font-size: 16px;
        line-height: 70px; } }
  
  /* Video Play With Text */
  .video-play-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .video-play-text i {
      color: white;
      font-size: 14px;
      margin-right: 15px;
      width: 55px;
      height: 55px;
      background: #01c9f5;
      line-height: 55px;
      border-radius: 50%;
      text-align: center; }
    .video-play-text span {
      font-weight: 500;
      text-decoration: underline; }
  
  /* Wave Shapes */
  .wave-shapes {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -100px;
    width: calc(100% + 100px);
    height: 100%; }
    .wave-shapes .shape {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      -webkit-animation: leftRightOne 6s infinite;
      animation: leftRightOne 6s infinite; }
      .wave-shapes .shape.two {
        -webkit-animation-delay: 3s;
        animation-delay: 3s; }
  
  /*Project Filter*/
  .filter-btns-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px; }
    .filter-btns-one li {
      cursor: pointer;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-weight: 500;
      padding: 4px 20px;
      border-radius: 5px;
      margin: 0 5px 10px;
      color: #293043; }
      @media only screen and (max-width: 375px) {
        .filter-btns-one li {
          padding-left: 15px;
          padding-right: 15px; } }
      .filter-btns-one li.current {
        background: white;
        color: #01c9f5;
        text-decoration: underline;
        -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5);
        box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5); }
  
  .filter-btns-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-weight: 500;
    margin-left: -18px;
    margin-right: -18px; }
    @media only screen and (min-width: 768px) {
      .filter-btns-two {
        font-size: 18px; } }
    @media only screen and (max-width: 575px) {
      .filter-btns-two {
        margin-left: -8px;
        margin-right: -8px; } }
    .filter-btns-two li {
      cursor: pointer;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin: 0 18px 10px;
      border-bottom: 3px solid transparent; }
      @media only screen and (max-width: 575px) {
        .filter-btns-two li {
          margin-left: 8px;
          margin-right: 8px; } }
      .filter-btns-two li.current {
        color: #0066ff;
        border-color: #0066ff; }
  
  /* Position */
  .rel {
    position: relative; }
  
  .z-0 {
    z-index: 0; }
  
  .z-1 {
    z-index: 1; }
  
  .z-2 {
    z-index: 2; }
  
  .z-3 {
    z-index: 3; }
  
  .z-4 {
    z-index: 4; }
  
  .z-5 {
    z-index: 5; }
  
  .overlay {
    z-index: 1;
    position: relative; }
    .overlay::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      opacity: 0.75;
      background-color: #293043; }
  
  /* Backgruond Size */
  .bgs-cover {
    background-size: cover;
    background-position: center; }
  
  /* Color + Background */
  .bgc-black {
    background-color: #070c13; }
  
  .bgc-primary {
    background-color: #0066ff; }
  
  .bgc-secondary {
    background-color: #01c9f5; }
  
  .bgc-dark-blue {
    background-color: #0e1b38; }
  
  .bgc-lighter {
    background-color: #78b6ab; }
  
  .color-secondary {
    color: #01c9f5; }
  
  .bgc-black-with-lighting {
    background: #1e1e22; }
    .bgc-black-with-lighting:after, .bgc-black-with-lighting:before {
      position: absolute;
      width: 450px;
      height: 450px;
      z-index: -1;
      content: '';
      border-radius: 50%;
      background: #fff;
      -webkit-animation: zoomInOut 5s infinite;
      animation: zoomInOut 5s infinite; }
    .bgc-black-with-lighting:after {
      left: -8%;
      top: -15%;
      -webkit-filter: blur(110px);
      filter: blur(110px); }
    .bgc-black-with-lighting:before {
      right: -10%;
      top: 20%;
      -webkit-filter: blur(350px);
      filter: blur(350px);
      -webkit-animation-delay: 2.5s;
      animation-delay: 2.5s; }
  
  /* Border Radius */
  .br-5 {
    border-radius: 5px; }
  
  .br-10 {
    border-radius: 10px; }
  
  .br-15 {
    border-radius: 15px; }
  
  .br-20 {
    border-radius: 20px; }
  
  .br-25 {
    border-radius: 25px; }
  
  .br-30 {
    border-radius: 30px; }
  
  /* Home One */
  .home-one {
    font-family: 'Catamaran', sans-serif; }
    .home-one .count-text,
    .home-one .price-and-btn .price,
    .home-one .blog-item .content h4,
    .home-one h1, .home-one h2, .home-one h3, .home-one h4, .home-one h5, .home-one h6,
    .home-one .blog-item .content .date,
    .home-one .h1, .home-one .h2, .home-one .h3, .home-one .h4, .home-one .h5, .home-one .h6,
    .home-one .blog-item .content .post-by a,
    .home-one .work-process-item .number {
      font-family: 'Kumbh Sans', sans-serif; }
    .home-one a:hover,
    .home-one .details-btn,
    .home-one .slick-arrow,
    .home-one .counter-item > i,
    .home-one .section-title h2 span,
    .home-one .blog-item .content .date,
    .home-one .style-two .counter-title,
    .home-one .section-title .sub-title,
    .home-one .list-style-two li a:hover,
    .home-one .social-style-two a:not(:hover) {
      color: #fc653c; }
    .home-one .theme-btn,
    .home-one .scroll-top,
    .home-one .details-btn:hover,
    .home-one .slick-arrow:hover,
    .home-one .slick-arrow:focus,
    .home-one .list-style-two li:before,
    .home-one .list-style-one li:before,
    .home-one .social-style-two a:hover,
    .home-one .work-process-item .icon i,
    .home-one .slick-dots li.slick-active,
    .home-one .theme-btn.style-three:after,
    .home-one .list-style-two li:hover:before,
    .home-one .work-process-item:hover .icon i,
    .home-one .hidden-bar .social-style-one a:hover,
    .home-one .widget_nav_menu .list-style-two li:hover:before {
      background: #fc653c;
       }
    .home-one .slick-dots li,
    .home-one .theme-btn.style-three:hover,
    .home-one .work-process-item .icon:before,
    .home-one .hidden-bar .social-style-one a:hover {
      border-color: #fc653c; }
    .home-one .slick-arrow:focus,
    .home-one .slick-arrow:hover,
    .home-one .details-btn:hover,
    .home-one .theme-btn.style-three,
    .home-one .social-style-two a:hover,
    .home-one .hidden-bar .social-style-one a:hover {
      color: white; }
    .home-one .theme-btn {
      border-radius: 27px; }
      .home-one .theme-btn:after {
        background: black; }
    .home-one .slick-arrow {
      border-radius: 50%; }
    .home-one .preloader {
      background-image: url("../public/images/preloader2.gif"); }
    .home-one .text-center .sub-title:after, .home-one .text-center .sub-title:before {
      display: block; }
  
  /* Home Two */
  @media only screen and (min-width: 768px) {
    .home-two h2 {
      font-size: 42px; } }


.flaticons{
    font-family: flaticon !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 60px;
    padding-bottom: 16px;
    color: #fc653c;
    -webkit-font-smoothing: antialiased;
} 
.feature-item-five:hover .flaticons{
    color: #fff;
    transition: 0.5s;
}
  
  /*******************************************************/
  /******************* 03. Repeat Style ******************/
  /*******************************************************/
  .heading,
  h1,
  .h1,
  h2,
  .h2,
  .count-text,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  .product-item .content .price-details-btn .price {
    font-weight: 700;
    color: #293043;
    font-family: "Catamaran", serif; }
  
  h1, .h1 {
    line-height: 1.16;
    font-size: 70px; }
  
  h2, .h2, .count-text {
    line-height: 1.22;
    font-size: 45px; }
  
  h3, .h3 {
    line-height: 1.3;
    font-size: 30px; }
  
  h4, .h4 {
    line-height: 1.65;
    font-size: 24px; }
  
  h5, .h5 {
    line-height: 1.5;
    font-size: 18px; }
  
  h6, .h6 {
    font-size: 16px; }
  
  .feature-item:hover .image, .service-item, .team-member:hover:after {
    -webkit-box-shadow: 10px 0 60px rgba(69, 69, 69, 0.1);
    box-shadow: 10px 0 60px rgba(69, 69, 69, 0.1); }
  
  /*******************************************************/
  /************** 04. Padding Margin Spacing *************/
  /*******************************************************/
  /* Padding Around */
  .p-5 {
    padding: 5px !important; }
  
  .p-10 {
    padding: 10px; }
  
  .p-15 {
    padding: 15px; }
  
  .p-20 {
    padding: 20px; }
  
  .p-25 {
    padding: 25px; }
  
  .p-30 {
    padding: 30px; }
  
  .p-35 {
    padding: 35px; }
  
  .p-40 {
    padding: 40px; }
  
  .p-45 {
    padding: 45px; }
  
  .p-50 {
    padding: 50px; }
  
  .p-55 {
    padding: 55px; }
  
  .p-60 {
    padding: 60px; }
  
  .p-65 {
    padding: 65px; }
  
  .p-70 {
    padding: 70px; }
  
  .p-75 {
    padding: 75px; }
  
  .p-80 {
    padding: 80px; }
  
  .p-85 {
    padding: 85px; }
  
  .p-90 {
    padding: 90px; }
  
  .p-95 {
    padding: 95px; }
  
  .p-100 {
    padding: 100px; }
  
  .p-105 {
    padding: 105px; }
  
  .p-110 {
    padding: 110px; }
  
  .p-115 {
    padding: 115px; }
  
  .p-120 {
    padding: 120px; }
  
  .p-125 {
    padding: 125px; }
  
  .p-130 {
    padding: 130px; }
  
  .p-135 {
    padding: 135px; }
  
  .p-140 {
    padding: 140px; }
  
  .p-145 {
    padding: 145px; }
  
  .p-150 {
    padding: 150px; }
  
  .p-155 {
    padding: 155px; }
  
  .p-160 {
    padding: 160px; }
  
  .p-165 {
    padding: 165px; }
  
  .p-170 {
    padding: 170px; }
  
  .p-175 {
    padding: 175px; }
  
  .p-180 {
    padding: 180px; }
  
  .p-185 {
    padding: 185px; }
  
  .p-190 {
    padding: 190px; }
  
  .p-195 {
    padding: 195px; }
  
  .p-200 {
    padding: 200px; }
  
  .p-205 {
    padding: 205px; }
  
  .p-210 {
    padding: 210px; }
  
  .p-215 {
    padding: 215px; }
  
  .p-220 {
    padding: 220px; }
  
  .p-225 {
    padding: 225px; }
  
  .p-230 {
    padding: 230px; }
  
  .p-235 {
    padding: 235px; }
  
  .p-240 {
    padding: 240px; }
  
  .p-245 {
    padding: 245px; }
  
  .p-250 {
    padding: 250px; }
  
  /* Padding Top */
  .pt-5,
  .py-5 {
    padding-top: 5px !important; }
  
  .pt-10,
  .py-10 {
    padding-top: 10px; }
  
  .pt-15,
  .py-15 {
    padding-top: 15px; }
  
  .pt-20,
  .py-20 {
    padding-top: 20px; }
  
  .pt-25,
  .py-25 {
    padding-top: 25px; }
  
  .pt-30,
  .py-30 {
    padding-top: 30px; }
  
  .pt-35,
  .py-35 {
    padding-top: 35px; }
  
  .pt-40,
  .py-40 {
    padding-top: 40px; }
  
  .pt-45,
  .py-45 {
    padding-top: 45px; }
  
  .pt-50,
  .py-50 {
    padding-top: 50px; }
  
  .pt-55,
  .py-55 {
    padding-top: 55px; }
  
  .pt-60,
  .py-60 {
    padding-top: 60px; }
  
  .pt-65,
  .py-65 {
    padding-top: 65px; }
  
  .pt-70,
  .py-70 {
    padding-top: 70px; }
  
  .pt-75,
  .py-75 {
    padding-top: 75px; }
  
  .pt-80,
  .py-80 {
    padding-top: 80px; }
  
  .pt-85,
  .py-85 {
    padding-top: 85px; }
  
  .pt-90,
  .py-90 {
    padding-top: 90px; }
  
  .pt-95,
  .py-95 {
    padding-top: 95px; }
  
  .pt-100,
  .py-100 {
    padding-top: 100px; }
  
  .pt-105,
  .py-105 {
    padding-top: 105px; }
  
  .pt-110,
  .py-110 {
    padding-top: 110px; }
  
  .pt-115,
  .py-115 {
    padding-top: 115px; }
  
  .pt-120,
  .py-120 {
    padding-top: 120px; }
  
  .pt-125,
  .py-125 {
    padding-top: 125px; }
  
  .pt-130,
  .py-130 {
    padding-top: 130px; }
  
  .pt-135,
  .py-135 {
    padding-top: 135px; }
  
  .pt-140,
  .py-140 {
    padding-top: 140px; }
  
  .pt-145,
  .py-145 {
    padding-top: 145px; }
  
  .pt-150,
  .py-150 {
    padding-top: 150px; }
  
  .pt-155,
  .py-155 {
    padding-top: 155px; }
  
  .pt-160,
  .py-160 {
    padding-top: 160px; }
  
  .pt-165,
  .py-165 {
    padding-top: 165px; }
  
  .pt-170,
  .py-170 {
    padding-top: 170px; }
  
  .pt-175,
  .py-175 {
    padding-top: 175px; }
  
  .pt-180,
  .py-180 {
    padding-top: 180px; }
  
  .pt-185,
  .py-185 {
    padding-top: 185px; }
  
  .pt-190,
  .py-190 {
    padding-top: 190px; }
  
  .pt-195,
  .py-195 {
    padding-top: 195px; }
  
  .pt-200,
  .py-200 {
    padding-top: 200px; }
  
  .pt-205,
  .py-205 {
    padding-top: 205px; }
  
  .pt-210,
  .py-210 {
    padding-top: 210px; }
  
  .pt-215,
  .py-215 {
    padding-top: 215px; }
  
  .pt-220,
  .py-220 {
    padding-top: 220px; }
  
  .pt-225,
  .py-225 {
    padding-top: 225px; }
  
  .pt-230,
  .py-230 {
    padding-top: 230px; }
  
  .pt-235,
  .py-235 {
    padding-top: 235px; }
  
  .pt-240,
  .py-240 {
    padding-top: 240px; }
  
  .pt-245,
  .py-245 {
    padding-top: 245px; }
  
  .pt-250,
  .py-250 {
    padding-top: 250px; }
  
  /* Padding Bottom */
  .pb-5,
  .py-5 {
    padding-bottom: 5px !important; }
  
  .pb-10,
  .py-10 {
    padding-bottom: 10px; }
  
  .pb-15,
  .py-15 {
    padding-bottom: 15px; }
  
  .pb-20,
  .py-20 {
    padding-bottom: 20px; }
  
  .pb-25,
  .py-25 {
    padding-bottom: 25px; }
  
  .pb-30,
  .py-30 {
    padding-bottom: 30px; }
  
  .pb-35,
  .py-35 {
    padding-bottom: 35px; }
  
  .pb-40,
  .py-40 {
    padding-bottom: 40px; }
  
  .pb-45,
  .py-45 {
    padding-bottom: 45px; }
  
  .pb-50,
  .py-50 {
    padding-bottom: 50px; }
  
  .pb-55,
  .py-55 {
    padding-bottom: 55px; }
  
  .pb-60,
  .py-60 {
    padding-bottom: 60px; }
  
  .pb-65,
  .py-65 {
    padding-bottom: 65px; }
  
  .pb-70,
  .py-70 {
    padding-bottom: 70px; }
  
  .pb-75,
  .py-75 {
    padding-bottom: 75px; }
  
  .pb-80,
  .py-80 {
    padding-bottom: 80px; }
  
  .pb-85,
  .py-85 {
    padding-bottom: 85px; }
  
  .pb-90,
  .py-90 {
    padding-bottom: 90px; }
  
  .pb-95,
  .py-95 {
    padding-bottom: 95px; }
  
  .pb-100,
  .py-100 {
    padding-bottom: 100px; }
  
  .pb-105,
  .py-105 {
    padding-bottom: 105px; }
  
  .pb-110,
  .py-110 {
    padding-bottom: 110px; }
  
  .pb-115,
  .py-115 {
    padding-bottom: 115px; }
  
  .pb-120,
  .py-120 {
    padding-bottom: 120px; }
  
  .pb-125,
  .py-125 {
    padding-bottom: 125px; }
  
  .pb-130,
  .py-130 {
    padding-bottom: 130px; }
  
  .pb-135,
  .py-135 {
    padding-bottom: 135px; }
  
  .pb-140,
  .py-140 {
    padding-bottom: 140px; }
  
  .pb-145,
  .py-145 {
    padding-bottom: 145px; }
  
  .pb-150,
  .py-150 {
    padding-bottom: 150px; }
  
  .pb-155,
  .py-155 {
    padding-bottom: 155px; }
  
  .pb-160,
  .py-160 {
    padding-bottom: 160px; }
  
  .pb-165,
  .py-165 {
    padding-bottom: 165px; }
  
  .pb-170,
  .py-170 {
    padding-bottom: 170px; }
  
  .pb-175,
  .py-175 {
    padding-bottom: 175px; }
  
  .pb-180,
  .py-180 {
    padding-bottom: 180px; }
  
  .pb-185,
  .py-185 {
    padding-bottom: 185px; }
  
  .pb-190,
  .py-190 {
    padding-bottom: 190px; }
  
  .pb-195,
  .py-195 {
    padding-bottom: 195px; }
  
  .pb-200,
  .py-200 {
    padding-bottom: 200px; }
  
  .pb-205,
  .py-205 {
    padding-bottom: 205px; }
  
  .pb-210,
  .py-210 {
    padding-bottom: 210px; }
  
  .pb-215,
  .py-215 {
    padding-bottom: 215px; }
  
  .pb-220,
  .py-220 {
    padding-bottom: 220px; }
  
  .pb-225,
  .py-225 {
    padding-bottom: 225px; }
  
  .pb-230,
  .py-230 {
    padding-bottom: 230px; }
  
  .pb-235,
  .py-235 {
    padding-bottom: 235px; }
  
  .pb-240,
  .py-240 {
    padding-bottom: 240px; }
  
  .pb-245,
  .py-245 {
    padding-bottom: 245px; }
  
  .pb-250,
  .py-250 {
    padding-bottom: 250px; }
  
  /* Margin Around */
  .m-5 {
    margin: 5px !important; }
  
  .m-10 {
    margin: 10px; }
  
  .m-15 {
    margin: 15px; }
  
  .m-20 {
    margin: 20px; }
  
  .m-25 {
    margin: 25px; }
  
  .m-30 {
    margin: 30px; }
  
  .m-35 {
    margin: 35px; }
  
  .m-40 {
    margin: 40px; }
  
  .m-45 {
    margin: 45px; }
  
  .m-50 {
    margin: 50px; }
  
  .m-55 {
    margin: 55px; }
  
  .m-60 {
    margin: 60px; }
  
  .m-65 {
    margin: 65px; }
  
  .m-70 {
    margin: 70px; }
  
  .m-75 {
    margin: 75px; }
  
  .m-80 {
    margin: 80px; }
  
  .m-85 {
    margin: 85px; }
  
  .m-90 {
    margin: 90px; }
  
  .m-95 {
    margin: 95px; }
  
  .m-100 {
    margin: 100px; }
  
  .m-105 {
    margin: 105px; }
  
  .m-110 {
    margin: 110px; }
  
  .m-115 {
    margin: 115px; }
  
  .m-120 {
    margin: 120px; }
  
  .m-125 {
    margin: 125px; }
  
  .m-130 {
    margin: 130px; }
  
  .m-135 {
    margin: 135px; }
  
  .m-140 {
    margin: 140px; }
  
  .m-145 {
    margin: 145px; }
  
  .m-150 {
    margin: 150px; }
  
  .m-155 {
    margin: 155px; }
  
  .m-160 {
    margin: 160px; }
  
  .m-165 {
    margin: 165px; }
  
  .m-170 {
    margin: 170px; }
  
  .m-175 {
    margin: 175px; }
  
  .m-180 {
    margin: 180px; }
  
  .m-185 {
    margin: 185px; }
  
  .m-190 {
    margin: 190px; }
  
  .m-195 {
    margin: 195px; }
  
  .m-200 {
    margin: 200px; }
  
  .m-205 {
    margin: 205px; }
  
  .m-210 {
    margin: 210px; }
  
  .m-215 {
    margin: 215px; }
  
  .m-220 {
    margin: 220px; }
  
  .m-225 {
    margin: 225px; }
  
  .m-230 {
    margin: 230px; }
  
  .m-235 {
    margin: 235px; }
  
  .m-240 {
    margin: 240px; }
  
  .m-245 {
    margin: 245px; }
  
  .m-250 {
    margin: 250px; }
  
  /* Margin Top */
  .mt-5,
  .my-5 {
    margin-top: 5px !important; }
  
  .mt-10,
  .my-10 {
    margin-top: 10px; }
  
  .mt-15,
  .my-15 {
    margin-top: 15px; }
  
  .mt-20,
  .my-20 {
    margin-top: 20px; }
  
  .mt-25,
  .my-25 {
    margin-top: 25px; }
  
  .mt-30,
  .my-30 {
    margin-top: 30px; }
  
  .mt-35,
  .my-35 {
    margin-top: 35px; }
  
  .mt-40,
  .my-40 {
    margin-top: 40px; }
  
  .mt-45,
  .my-45 {
    margin-top: 45px; }
  
  .mt-50,
  .my-50 {
    margin-top: 50px; }
  
  .mt-55,
  .my-55 {
    margin-top: 55px; }
  
  .mt-60,
  .my-60 {
    margin-top: 60px; }
  
  .mt-65,
  .my-65 {
    margin-top: 65px; }
  
  .mt-70,
  .my-70 {
    margin-top: 70px; }
  
  .mt-75,
  .my-75 {
    margin-top: 75px; }
  
  .mt-80,
  .my-80 {
    margin-top: 80px; }
  
  .mt-85,
  .my-85 {
    margin-top: 85px; }
  
  .mt-90,
  .my-90 {
    margin-top: 90px; }
  
  .mt-95,
  .my-95 {
    margin-top: 95px; }
  
  .mt-100,
  .my-100 {
    margin-top: 100px; }
  
  .mt-105,
  .my-105 {
    margin-top: 105px; }
  
  .mt-110,
  .my-110 {
    margin-top: 110px; }
  
  .mt-115,
  .my-115 {
    margin-top: 115px; }
  
  .mt-120,
  .my-120 {
    margin-top: 120px; }
  
  .mt-125,
  .my-125 {
    margin-top: 125px; }
  
  .mt-130,
  .my-130 {
    margin-top: 130px; }
  
  .mt-135,
  .my-135 {
    margin-top: 135px; }
  
  .mt-140,
  .my-140 {
    margin-top: 140px; }
  
  .mt-145,
  .my-145 {
    margin-top: 145px; }
  
  .mt-150,
  .my-150 {
    margin-top: 150px; }
  
  .mt-155,
  .my-155 {
    margin-top: 155px; }
  
  .mt-160,
  .my-160 {
    margin-top: 160px; }
  
  .mt-165,
  .my-165 {
    margin-top: 165px; }
  
  .mt-170,
  .my-170 {
    margin-top: 170px; }
  
  .mt-175,
  .my-175 {
    margin-top: 175px; }
  
  .mt-180,
  .my-180 {
    margin-top: 180px; }
  
  .mt-185,
  .my-185 {
    margin-top: 185px; }
  
  .mt-190,
  .my-190 {
    margin-top: 190px; }
  
  .mt-195,
  .my-195 {
    margin-top: 195px; }
  
  .mt-200,
  .my-200 {
    margin-top: 200px; }
  
  .mt-205,
  .my-205 {
    margin-top: 205px; }
  
  .mt-210,
  .my-210 {
    margin-top: 210px; }
  
  .mt-215,
  .my-215 {
    margin-top: 215px; }
  
  .mt-220,
  .my-220 {
    margin-top: 220px; }
  
  .mt-225,
  .my-225 {
    margin-top: 225px; }
  
  .mt-230,
  .my-230 {
    margin-top: 230px; }
  
  .mt-235,
  .my-235 {
    margin-top: 235px; }
  
  .mt-240,
  .my-240 {
    margin-top: 240px; }
  
  .mt-245,
  .my-245 {
    margin-top: 245px; }
  
  .mt-250,
  .my-250 {
    margin-top: 250px; }
  
  /* Margin Bottom */
  .mb-5,
  .my-5 {
    margin-bottom: 5px !important; }
  
  .mb-10,
  .my-10 {
    margin-bottom: 10px; }
  
  .mb-15,
  .my-15 {
    margin-bottom: 15px; }
  
  .mb-20,
  .my-20 {
    margin-bottom: 20px; }
  
  .mb-25,
  .my-25 {
    margin-bottom: 25px; }
  
  .mb-30,
  .my-30 {
    margin-bottom: 30px; }
  
  .mb-35,
  .my-35 {
    margin-bottom: 35px; }
  
  .mb-40,
  .my-40 {
    margin-bottom: 40px; }
  
  .mb-45,
  .my-45 {
    margin-bottom: 45px; }
  
  .mb-50,
  .my-50 {
    margin-bottom: 50px; }
  
  .mb-55,
  .my-55 {
    margin-bottom: 55px; }
  
  .mb-60,
  .my-60 {
    margin-bottom: 60px; }
  
  .mb-65,
  .my-65 {
    margin-bottom: 65px; }
  
  .mb-70,
  .my-70 {
    margin-bottom: 70px; }
  
  .mb-75,
  .my-75 {
    margin-bottom: 75px; }
  
  .mb-80,
  .my-80 {
    margin-bottom: 80px; }
  
  .mb-85,
  .my-85 {
    margin-bottom: 85px; }
  
  .mb-90,
  .my-90 {
    margin-bottom: 90px; }
  
  .mb-95,
  .my-95 {
    margin-bottom: 95px; }
  
  .mb-100,
  .my-100 {
    margin-bottom: 100px; }
  
  .mb-105,
  .my-105 {
    margin-bottom: 105px; }
  
  .mb-110,
  .my-110 {
    margin-bottom: 110px; }
  
  .mb-115,
  .my-115 {
    margin-bottom: 115px; }
  
  .mb-120,
  .my-120 {
    margin-bottom: 120px; }
  
  .mb-125,
  .my-125 {
    margin-bottom: 125px; }
  
  .mb-130,
  .my-130 {
    margin-bottom: 130px; }
  
  .mb-135,
  .my-135 {
    margin-bottom: 135px; }
  
  .mb-140,
  .my-140 {
    margin-bottom: 140px; }
  
  .mb-145,
  .my-145 {
    margin-bottom: 145px; }
  
  .mb-150,
  .my-150 {
    margin-bottom: 150px; }
  
  .mb-155,
  .my-155 {
    margin-bottom: 155px; }
  
  .mb-160,
  .my-160 {
    margin-bottom: 160px; }
  
  .mb-165,
  .my-165 {
    margin-bottom: 165px; }
  
  .mb-170,
  .my-170 {
    margin-bottom: 170px; }
  
  .mb-175,
  .my-175 {
    margin-bottom: 175px; }
  
  .mb-180,
  .my-180 {
    margin-bottom: 180px; }
  
  .mb-185,
  .my-185 {
    margin-bottom: 185px; }
  
  .mb-190,
  .my-190 {
    margin-bottom: 190px; }
  
  .mb-195,
  .my-195 {
    margin-bottom: 195px; }
  
  .mb-200,
  .my-200 {
    margin-bottom: 200px; }
  
  .mb-205,
  .my-205 {
    margin-bottom: 205px; }
  
  .mb-210,
  .my-210 {
    margin-bottom: 210px; }
  
  .mb-215,
  .my-215 {
    margin-bottom: 215px; }
  
  .mb-220,
  .my-220 {
    margin-bottom: 220px; }
  
  .mb-225,
  .my-225 {
    margin-bottom: 225px; }
  
  .mb-230,
  .my-230 {
    margin-bottom: 230px; }
  
  .mb-235,
  .my-235 {
    margin-bottom: 235px; }
  
  .mb-240,
  .my-240 {
    margin-bottom: 240px; }
  
  .mb-245,
  .my-245 {
    margin-bottom: 245px; }
  
  .mb-250,
  .my-250 {
    margin-bottom: 250px; }
  
  /* Responsive Padding Margin */
  @media only screen and (max-width: 991px) {
    /* Padding Around */
    .rp-0 {
      padding: 0px !important; }
    .rp-5 {
      padding: 5px !important; }
    .rp-10 {
      padding: 10px; }
    .rp-15 {
      padding: 15px; }
    .rp-20 {
      padding: 20px; }
    .rp-25 {
      padding: 25px; }
    .rp-30 {
      padding: 30px; }
    .rp-35 {
      padding: 35px; }
    .rp-40 {
      padding: 40px; }
    .rp-45 {
      padding: 45px; }
    .rp-50 {
      padding: 50px; }
    .rp-55 {
      padding: 55px; }
    .rp-60 {
      padding: 60px; }
    .rp-65 {
      padding: 65px; }
    .rp-70 {
      padding: 70px; }
    .rp-75 {
      padding: 75px; }
    .rp-80 {
      padding: 80px; }
    .rp-85 {
      padding: 85px; }
    .rp-90 {
      padding: 90px; }
    .rp-95 {
      padding: 95px; }
    .rp-100 {
      padding: 100px; }
    .rp-105 {
      padding: 105px; }
    .rp-110 {
      padding: 110px; }
    .rp-115 {
      padding: 115px; }
    .rp-120 {
      padding: 120px; }
    .rp-125 {
      padding: 125px; }
    .rp-130 {
      padding: 130px; }
    .rp-135 {
      padding: 135px; }
    .rp-140 {
      padding: 140px; }
    .rp-145 {
      padding: 145px; }
    .rp-150 {
      padding: 150px; }
    /* Padding Top */
    .rpt-0,
    .rpy-0 {
      padding-top: 0px !important; }
    .rpt-5,
    .rpy-5 {
      padding-top: 5px !important; }
    .rpt-10,
    .rpy-10 {
      padding-top: 10px; }
    .rpt-15,
    .rpy-15 {
      padding-top: 15px; }
    .rpt-20,
    .rpy-20 {
      padding-top: 20px; }
    .rpt-25,
    .rpy-25 {
      padding-top: 25px; }
    .rpt-30,
    .rpy-30 {
      padding-top: 30px; }
    .rpt-35,
    .rpy-35 {
      padding-top: 35px; }
    .rpt-40,
    .rpy-40 {
      padding-top: 40px; }
    .rpt-45,
    .rpy-45 {
      padding-top: 45px; }
    .rpt-50,
    .rpy-50 {
      padding-top: 50px; }
    .rpt-55,
    .rpy-55 {
      padding-top: 55px; }
    .rpt-60,
    .rpy-60 {
      padding-top: 60px; }
    .rpt-65,
    .rpy-65 {
      padding-top: 65px; }
    .rpt-70,
    .rpy-70 {
      padding-top: 70px; }
    .rpt-75,
    .rpy-75 {
      padding-top: 75px; }
    .rpt-80,
    .rpy-80 {
      padding-top: 80px; }
    .rpt-85,
    .rpy-85 {
      padding-top: 85px; }
    .rpt-90,
    .rpy-90 {
      padding-top: 90px; }
    .rpt-95,
    .rpy-95 {
      padding-top: 95px; }
    .rpt-100,
    .rpy-100 {
      padding-top: 100px; }
    .rpt-105,
    .rpy-105 {
      padding-top: 105px; }
    .rpt-110,
    .rpy-110 {
      padding-top: 110px; }
    .rpt-115,
    .rpy-115 {
      padding-top: 115px; }
    .rpt-120,
    .rpy-120 {
      padding-top: 120px; }
    .rpt-125,
    .rpy-125 {
      padding-top: 125px; }
    .rpt-130,
    .rpy-130 {
      padding-top: 130px; }
    .rpt-135,
    .rpy-135 {
      padding-top: 135px; }
    .rpt-140,
    .rpy-140 {
      padding-top: 140px; }
    .rpt-145,
    .rpy-145 {
      padding-top: 145px; }
    .rpt-150,
    .rpy-150 {
      padding-top: 150px; }
    /* Padding Bottom */
    .rpb-0,
    .rpy-0 {
      padding-bottom: 0px !important; }
    .rpb-5,
    .rpy-5 {
      padding-bottom: 5px !important; }
    .rpb-10,
    .rpy-10 {
      padding-bottom: 10px; }
    .rpb-15,
    .rpy-15 {
      padding-bottom: 15px; }
    .rpb-20,
    .rpy-20 {
      padding-bottom: 20px; }
    .rpb-25,
    .rpy-25 {
      padding-bottom: 25px; }
    .rpb-30,
    .rpy-30 {
      padding-bottom: 30px; }
    .rpb-35,
    .rpy-35 {
      padding-bottom: 35px; }
    .rpb-40,
    .rpy-40 {
      padding-bottom: 40px; }
    .rpb-45,
    .rpy-45 {
      padding-bottom: 45px; }
    .rpb-50,
    .rpy-50 {
      padding-bottom: 50px; }
    .rpb-55,
    .rpy-55 {
      padding-bottom: 55px; }
    .rpb-60,
    .rpy-60 {
      padding-bottom: 60px; }
    .rpb-65,
    .rpy-65 {
      padding-bottom: 65px; }
    .rpb-70,
    .rpy-70 {
      padding-bottom: 70px; }
    .rpb-75,
    .rpy-75 {
      padding-bottom: 75px; }
    .rpb-80,
    .rpy-80 {
      padding-bottom: 80px; }
    .rpb-85,
    .rpy-85 {
      padding-bottom: 85px; }
    .rpb-90,
    .rpy-90 {
      padding-bottom: 90px; }
    .rpb-95,
    .rpy-95 {
      padding-bottom: 95px; }
    .rpb-100,
    .rpy-100 {
      padding-bottom: 100px; }
    .rpb-105,
    .rpy-105 {
      padding-bottom: 105px; }
    .rpb-110,
    .rpy-110 {
      padding-bottom: 110px; }
    .rpb-115,
    .rpy-115 {
      padding-bottom: 115px; }
    .rpb-120,
    .rpy-120 {
      padding-bottom: 120px; }
    .rpb-125,
    .rpy-125 {
      padding-bottom: 125px; }
    .rpb-130,
    .rpy-130 {
      padding-bottom: 130px; }
    .rpb-135,
    .rpy-135 {
      padding-bottom: 135px; }
    .rpb-140,
    .rpy-140 {
      padding-bottom: 140px; }
    .rpb-145,
    .rpy-145 {
      padding-bottom: 145px; }
    .rpb-150,
    .rpy-150 {
      padding-bottom: 150px; }
    /* Margin Around */
    .rm-0 {
      margin: 0px !important; }
    .rm-5 {
      margin: 5px !important; }
    .rm-10 {
      margin: 10px; }
    .rm-15 {
      margin: 15px; }
    .rm-20 {
      margin: 20px; }
    .rm-25 {
      margin: 25px; }
    .rm-30 {
      margin: 30px; }
    .rm-35 {
      margin: 35px; }
    .rm-40 {
      margin: 40px; }
    .rm-45 {
      margin: 45px; }
    .rm-50 {
      margin: 50px; }
    .rm-55 {
      margin: 55px; }
    .rm-60 {
      margin: 60px; }
    .rm-65 {
      margin: 65px; }
    .rm-70 {
      margin: 70px; }
    .rm-75 {
      margin: 75px; }
    .rm-80 {
      margin: 80px; }
    .rm-85 {
      margin: 85px; }
    .rm-90 {
      margin: 90px; }
    .rm-95 {
      margin: 95px; }
    .rm-100 {
      margin: 100px; }
    .rm-105 {
      margin: 105px; }
    .rm-110 {
      margin: 110px; }
    .rm-115 {
      margin: 115px; }
    .rm-120 {
      margin: 120px; }
    .rm-125 {
      margin: 125px; }
    .rm-130 {
      margin: 130px; }
    .rm-135 {
      margin: 135px; }
    .rm-140 {
      margin: 140px; }
    .rm-145 {
      margin: 145px; }
    .rm-150 {
      margin: 150px; }
    /* Margin Top */
    .rmt-0,
    .rmy-0 {
      margin-top: 0px !important; }
    .rmt-5,
    .rmy-5 {
      margin-top: 5px !important; }
    .rmt-10,
    .rmy-10 {
      margin-top: 10px; }
    .rmt-15,
    .rmy-15 {
      margin-top: 15px; }
    .rmt-20,
    .rmy-20 {
      margin-top: 20px; }
    .rmt-25,
    .rmy-25 {
      margin-top: 25px; }
    .rmt-30,
    .rmy-30 {
      margin-top: 30px; }
    .rmt-35,
    .rmy-35 {
      margin-top: 35px; }
    .rmt-40,
    .rmy-40 {
      margin-top: 40px; }
    .rmt-45,
    .rmy-45 {
      margin-top: 45px; }
    .rmt-50,
    .rmy-50 {
      margin-top: 50px; }
    .rmt-55,
    .rmy-55 {
      margin-top: 55px; }
    .rmt-60,
    .rmy-60 {
      margin-top: 60px; }
    .rmt-65,
    .rmy-65 {
      margin-top: 65px; }
    .rmt-70,
    .rmy-70 {
      margin-top: 70px; }
    .rmt-75,
    .rmy-75 {
      margin-top: 75px; }
    .rmt-80,
    .rmy-80 {
      margin-top: 80px; }
    .rmt-85,
    .rmy-85 {
      margin-top: 85px; }
    .rmt-90,
    .rmy-90 {
      margin-top: 90px; }
    .rmt-95,
    .rmy-95 {
      margin-top: 95px; }
    .rmt-100,
    .rmy-100 {
      margin-top: 100px; }
    .rmt-105,
    .rmy-105 {
      margin-top: 105px; }
    .rmt-110,
    .rmy-110 {
      margin-top: 110px; }
    .rmt-115,
    .rmy-115 {
      margin-top: 115px; }
    .rmt-120,
    .rmy-120 {
      margin-top: 120px; }
    .rmt-125,
    .rmy-125 {
      margin-top: 125px; }
    .rmt-130,
    .rmy-130 {
      margin-top: 130px; }
    .rmt-135,
    .rmy-135 {
      margin-top: 135px; }
    .rmt-140,
    .rmy-140 {
      margin-top: 140px; }
    .rmt-145,
    .rmy-145 {
      margin-top: 145px; }
    .rmt-150,
    .rmy-150 {
      margin-top: 150px; }
    /* Margin Bottom */
    .rmb-0,
    .rmy-0 {
      margin-bottom: 0px !important; }
    .rmb-5,
    .rmy-5 {
      margin-bottom: 5px !important; }
    .rmb-10,
    .rmy-10 {
      margin-bottom: 10px; }
    .rmb-15,
    .rmy-15 {
      margin-bottom: 15px; }
    .rmb-20,
    .rmy-20 {
      margin-bottom: 20px; }
    .rmb-25,
    .rmy-25 {
      margin-bottom: 25px; }
    .rmb-30,
    .rmy-30 {
      margin-bottom: 30px; }
    .rmb-35,
    .rmy-35 {
      margin-bottom: 35px; }
    .rmb-40,
    .rmy-40 {
      margin-bottom: 40px; }
    .rmb-45,
    .rmy-45 {
      margin-bottom: 45px; }
    .rmb-50,
    .rmy-50 {
      margin-bottom: 50px; }
    .rmb-55,
    .rmy-55 {
      margin-bottom: 55px; }
    .rmb-60,
    .rmy-60 {
      margin-bottom: 60px; }
    .rmb-65,
    .rmy-65 {
      margin-bottom: 65px; }
    .rmb-70,
    .rmy-70 {
      margin-bottom: 70px; }
    .rmb-75,
    .rmy-75 {
      margin-bottom: 75px; }
    .rmb-80,
    .rmy-80 {
      margin-bottom: 80px; }
    .rmb-85,
    .rmy-85 {
      margin-bottom: 85px; }
    .rmb-90,
    .rmy-90 {
      margin-bottom: 90px; }
    .rmb-95,
    .rmy-95 {
      margin-bottom: 95px; }
    .rmb-100,
    .rmy-100 {
      margin-bottom: 100px; }
    .rmb-105,
    .rmy-105 {
      margin-bottom: 105px; }
    .rmb-110,
    .rmy-110 {
      margin-bottom: 110px; }
    .rmb-115,
    .rmy-115 {
      margin-bottom: 115px; }
    .rmb-120,
    .rmy-120 {
      margin-bottom: 120px; }
    .rmb-125,
    .rmy-125 {
      margin-bottom: 125px; }
    .rmb-130,
    .rmy-130 {
      margin-bottom: 130px; }
    .rmb-135,
    .rmy-135 {
      margin-bottom: 135px; }
    .rmb-140,
    .rmy-140 {
      margin-bottom: 140px; }
    .rmb-145,
    .rmy-145 {
      margin-bottom: 145px; }
    .rmb-150,
    .rmy-150 {
      margin-bottom: 150px; } }
  
  /*******************************************************/
  /***************** 05. Custom Animation ****************/
  /*******************************************************/
  /* Animation Delay */
  .delay-1-0s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
  
  .delay-2-0s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s; }
  
  .delay-0-1s {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
  
  .delay-0-2s {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
  
  .delay-0-3s {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
  
  .delay-0-4s {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  
  .delay-0-5s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s; }
  
  .delay-0-6s {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }
  
  .delay-0-7s {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s; }
  
  .delay-0-8s {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s; }
  
  .delay-0-9s {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s; }
  
  .delay-1-1s {
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s; }
  
  .delay-1-2s {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s; }
  
  .delay-1-3s {
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s; }
  
  .delay-1-4s {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s; }
  
  .delay-1-5s {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s; }
  
  .delay-1-6s {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s; }
  
  .delay-1-7s {
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s; }
  
  .delay-1-8s {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s; }
  
  .delay-1-9s {
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s; }
  
  /* Menu Sticky */
  @-webkit-keyframes sticky {
    0% {
      top: -100px; }
    100% {
      top: 0; } }
  
  @keyframes sticky {
    0% {
      top: -100px; }
    100% {
      top: 0; } }
  
  /* Hero Circle */
  @-webkit-keyframes rotated_circle {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }
  @keyframes rotated_circle {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } }
  
  /* Feedback Rotated */
  @-webkit-keyframes semi_rotated {
    0%,
    100% {
      -webkit-transform: rotate(20deg);
      transform: rotate(20deg); }
    50% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg); } }
  @keyframes semi_rotated {
    0%,
    100% {
      -webkit-transform: rotate(20deg);
      transform: rotate(20deg); }
    50% {
      -webkit-transform: rotate(-20deg);
      transform: rotate(-20deg); } }
  
  /* Image BG Rotated */
  @-webkit-keyframes semi_rotated_two {
    0%,
    100% {
      -webkit-transform: rotate(-11deg);
      transform: rotate(-11deg); }
    50% {
      -webkit-transform: rotate(11deg);
      transform: rotate(11deg); } }
  @keyframes semi_rotated_two {
    0%,
    100% {
      -webkit-transform: rotate(-11deg);
      transform: rotate(-11deg); }
    50% {
      -webkit-transform: rotate(11deg);
      transform: rotate(11deg); } }
  
  @-webkit-keyframes move_arround {
    0% {
      top: 20px;
      left: 20px; }
    25% {
      top: 20px;
      left: -20px; }
    50% {
      top: -20px;
      left: -20px; }
    75% {
      top: -20px;
      left: 20px; }
    100% {
      top: 20px;
      left: 20px; } }
  
  @keyframes move_arround {
    0% {
      top: 20px;
      left: 20px; }
    25% {
      top: 20px;
      left: -20px; }
    50% {
      top: -20px;
      left: -20px; }
    75% {
      top: -20px;
      left: 20px; }
    100% {
      top: 20px;
      left: 20px; } }
  
  /* Hero Circle */
  @-webkit-keyframes upDownLeft {
    0%,
    100% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px); }
    25%,
    75% {
      -webkit-transform: translate(0px, 50px);
      transform: translate(0px, 50px); }
    50% {
      -webkit-transform: translate(-50px, 50px);
      transform: translate(-50px, 50px); } }
  @keyframes upDownLeft {
    0%,
    100% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0px, 0px); }
    25%,
    75% {
      -webkit-transform: translate(0px, 50px);
      transform: translate(0px, 50px); }
    50% {
      -webkit-transform: translate(-50px, 50px);
      transform: translate(-50px, 50px); } }
  
  @-webkit-keyframes shapeAnimationOne {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(0px, 150px) rotate(90deg);
      transform: translate(0px, 150px) rotate(90deg); }
    50% {
      -webkit-transform: translate(150px, 150px) rotate(180deg);
      transform: translate(150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(150px, 0px) rotate(270deg);
      transform: translate(150px, 0px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @keyframes shapeAnimationOne {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(0px, 150px) rotate(90deg);
      transform: translate(0px, 150px) rotate(90deg); }
    50% {
      -webkit-transform: translate(150px, 150px) rotate(180deg);
      transform: translate(150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(150px, 0px) rotate(270deg);
      transform: translate(150px, 0px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @-webkit-keyframes shapeAnimationTwo {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-150px, 0px) rotate(270deg);
      transform: translate(-150px, 0px) rotate(270deg); }
    50% {
      -webkit-transform: translate(-150px, 150px) rotate(180deg);
      transform: translate(-150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(0px, 150px) rotate(90deg);
      transform: translate(0px, 150px) rotate(90deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @keyframes shapeAnimationTwo {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-150px, 0px) rotate(270deg);
      transform: translate(-150px, 0px) rotate(270deg); }
    50% {
      -webkit-transform: translate(-150px, 150px) rotate(180deg);
      transform: translate(-150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(0px, 150px) rotate(90deg);
      transform: translate(0px, 150px) rotate(90deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @-webkit-keyframes shapeAnimationThree {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(50px, 150px) rotate(90deg);
      transform: translate(50px, 150px) rotate(90deg); }
    50% {
      -webkit-transform: translate(150px, 150px) rotate(180deg);
      transform: translate(150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(150px, 50px) rotate(270deg);
      transform: translate(150px, 50px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @keyframes shapeAnimationThree {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(50px, 150px) rotate(90deg);
      transform: translate(50px, 150px) rotate(90deg); }
    50% {
      -webkit-transform: translate(150px, 150px) rotate(180deg);
      transform: translate(150px, 150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(150px, 50px) rotate(270deg);
      transform: translate(150px, 50px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @-webkit-keyframes shapeAnimationFour {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-150px -50px) rotate(90deg);
      transform: translate(-150px -50px) rotate(90deg); }
    50% {
      -webkit-transform: translate(-150px, -150px) rotate(180deg);
      transform: translate(-150px, -150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(-50px, -150px) rotate(270deg);
      transform: translate(-50px, -150px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @keyframes shapeAnimationFour {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-150px -50px) rotate(90deg);
      transform: translate(-150px -50px) rotate(90deg); }
    50% {
      -webkit-transform: translate(-150px, -150px) rotate(180deg);
      transform: translate(-150px, -150px) rotate(180deg); }
    75% {
      -webkit-transform: translate(-50px, -150px) rotate(270deg);
      transform: translate(-50px, -150px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @-webkit-keyframes shapeAnimationFive {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-100px -100px) rotate(90deg);
      transform: translate(-100px -100px) rotate(90deg); }
    50% {
      -webkit-transform: translate(100px, 50px) rotate(180deg);
      transform: translate(100px, 50px) rotate(180deg); }
    75% {
      -webkit-transform: translate(-100px, 150px) rotate(270deg);
      transform: translate(-100px, 150px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @keyframes shapeAnimationFive {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
      transform: translate(0px, 0px) rotate(0deg); }
    25% {
      -webkit-transform: translate(-100px -100px) rotate(90deg);
      transform: translate(-100px -100px) rotate(90deg); }
    50% {
      -webkit-transform: translate(100px, 50px) rotate(180deg);
      transform: translate(100px, 50px) rotate(180deg); }
    75% {
      -webkit-transform: translate(-100px, 150px) rotate(270deg);
      transform: translate(-100px, 150px) rotate(270deg); }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(360deg);
      transform: translate(0px, 0px) rotate(360deg); } }
  
  @-webkit-keyframes down-up-one {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px); }
    50% {
      -webkit-transform: rotateX(0deg) translateY(25px);
      transform: rotateX(0deg) translateY(25px); }
    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px); } }
  
  @keyframes down-up-one {
    0% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px); }
    50% {
      -webkit-transform: rotateX(0deg) translateY(25px);
      transform: rotateX(0deg) translateY(25px); }
    100% {
      -webkit-transform: rotateX(0deg) translateY(0px);
      transform: rotateX(0deg) translateY(0px); } }
  
  @-webkit-keyframes down-up-two {
    0% {
      -webkit-transform: rotateX(0deg) translate(0px);
      transform: rotateX(0deg) translate(0px); }
    50% {
      -webkit-transform: rotateX(0deg) translate(25px, -25px);
      transform: rotateX(0deg) translate(25px, -25px); }
    100% {
      -webkit-transform: rotateX(0deg) translate(0px);
      transform: rotateX(0deg) translate(0px); } }
  
  @keyframes down-up-two {
    0% {
      -webkit-transform: rotateX(0deg) translate(0px);
      transform: rotateX(0deg) translate(0px); }
    50% {
      -webkit-transform: rotateX(0deg) translate(25px, -25px);
      transform: rotateX(0deg) translate(25px, -25px); }
    100% {
      -webkit-transform: rotateX(0deg) translate(0px);
      transform: rotateX(0deg) translate(0px); } }
  
  @-webkit-keyframes leftRightOne {
    0%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0); }
    50% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px); } }
  
  @keyframes leftRightOne {
    0%,
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0); }
    50% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px); } }
  
  @-webkit-keyframes zoomInOut {
    0%,
    100% {
      -webkit-transform: scale(1);
      transform: scale(1); }
    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5); } }
  
  @keyframes zoomInOut {
    0%,
    100% {
      -webkit-transform: scale(1);
      transform: scale(1); }
    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5); } }
  
  /*******************************************************/
  /******************* 06. Header style ******************/
  /*******************************************************/
  .main-header {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
    @media only screen and (min-width: 1200px) {
      .main-header .container {
        max-width: 1555px; } }
    .main-header .container-fluid {
      padding-left: 25px;
      padding-right: 25px; }
      @media only screen and (min-width: 1200px) {
        .main-header .container-fluid {
          padding-left: 55px;
          padding-right: 55px; } }
    .main-header .header-upper {
      z-index: 5;
      width: 100%;
      position: relative;
      -webkit-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
      .main-header .header-upper1 {
        z-index: 5;
        width: 100%;
        position: relative;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease; }
    .main-header .logo-outer {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none; }
      @media only screen and (max-width: 991px) {
        .main-header .logo-outer {
          display: none; } }
    .main-header .logo {
      z-index: 9;
      padding: 2px 0;
      position: relative;
      margin-right: 25px; }
    .main-header.menu-absolute .header-upper {
      position: absolute; }
      .main-header.menu-absolute .header-upper1 {
        position: absolute; }
    .main-header.fixed-header .header-upper {
      top: 0;
      left: 0;
      position: fixed;
      background: white;
      -webkit-animation: sticky  1s;
      animation: sticky  1s;
      -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
      box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1); }
      .main-header.fixed-header .header-upper1 {
        top: 0;
        left: 0;
        position: fixed;
        background: white;
        -webkit-animation: sticky  1s;
        animation: sticky  1s;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
        box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1); }
      
    @media only screen and (min-width: 992px) {
      .main-header.fixed-header .main-menu .navbar-collapse > ul > li {
        padding-top: 20px;
        padding-bottom: 20px; } }
  
  @media only screen and (max-width: 767px) {
    .header-top-wrap {
      display: none; } }
  
  .header-top {
    font-size: 18px;
    font-weight: 500; }
    .header-top .top-left > ul {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
      .header-top .top-left > ul li:not(:last-child) {
        margin-right: 33px; }
      .header-top .top-left > ul li a:hover {
        text-decoration: underline; }
    .header-top .top-right > ul {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
      .header-top .top-right > ul > li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .header-top .top-right > ul > li > i {
          margin-right: 5px; }
        .header-top .top-right > ul > li a {
          text-decoration: underline; }
        .header-top .top-right > ul > li:not(:last-child):after {
          content: '';
          margin-left: 25px;
          margin-right: 25px;
          width: 5px;
          height: 5px;
          background: white;
          line-height: 5px;
          border-radius: 50%;
          text-align: center; }
    .header-top .top-right .nice-select {
      border: none;
      padding: 0 15px 0 0;
      background-color: transparent; }
      .header-top .top-right .nice-select:after {
        right: 0;
        border-color: white; }
      .header-top .top-right .nice-select .list {
        width: auto; }
        .header-top .top-right .nice-select .list li {
          color: #696e7b; }
      .header-top .top-right .nice-select .current {
        color: white;
        font-weight: 500;
        text-decoration: underline; }
  
  .nav-outer {
    margin-left: auto; }
    @media only screen and (max-width: 991px) {
      .nav-outer {
        width: 100%; } }
  
  /** Header Main Menu **/
  @media only screen and (max-width: 991px) {
    .main-menu {
      width: 100%; } }
  
  .main-menu .mobile-logo {
    margin-right: auto; }
    @media only screen and (max-width: 575px) {
      .main-menu .mobile-logo {
        max-width: 150px; } }
  
  @media only screen and (max-width: 991px) {
    .main-menu .collapse {
      overflow: auto; } }
  
  .main-menu .navbar-collapse {
    padding: 0px; }
    .main-menu .navbar-collapse > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse > ul {
          display: block;
          padding: 25px 0;
          background: #FFFFFF; }
          .main-menu .navbar-collapse > ul > li:last-child {
            border-bottom: 1px solid #f3f3f3; } }
    @media only screen and (max-width: 991px) {
      .main-menu .navbar-collapse {
        left: 0;
        width: 100%;
        position: absolute; } }
    .main-menu .navbar-collapse li {
      padding: 20px 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      @media only screen and (max-width: 1399px) {
        .main-menu .navbar-collapse li {
          padding-left: 15px;
          padding-right: 15px; } }
      @media only screen and (max-width: 991px) {
        .main-menu .navbar-collapse li {
          display: block;
          padding: 0 15px;
          border-top: 1px solid #f3f3f3; } }
      .main-menu .navbar-collapse li.dropdown .dropdown-btn {
        cursor: pointer;
        font-size: 12px;
        margin-left: 5px;
        color: #293043; }
        @media only screen and (max-width: 991px) {
          .main-menu .navbar-collapse li.dropdown .dropdown-btn {
            position: absolute;
            right: 10px;
            top: 0;
            width: 50px;
            height: 43px;
            border-left: 1px solid #f2f2f2;
            text-align: center;
            line-height: 43px; } }
      .main-menu .navbar-collapse li a {
        display: block;
        font-size: 20px;
        opacity: 1;
        font-weight: 500;
        position: relative;
        color: #293043;
        text-transform: capitalize;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease; }
        @media only screen and (max-width: 991px) {
          .main-menu .navbar-collapse li a {
            padding: 10px 10px;
            line-height: 22px; } }
        .main-menu .navbar-collapse li a:hover {
          color: #0066ff;
          text-decoration: underline; }
      .main-menu .navbar-collapse li.current > a, .main-menu .navbar-collapse li.current-menu-item > a {
        font-weight: 500; }
      .main-menu .navbar-collapse li li {
        border-top: 1px solid #f3f3f3; }
        .main-menu .navbar-collapse li li a {
          text-transform: capitalize; }
          .main-menu .navbar-collapse li li a:before {
            display: none; }
      .main-menu .navbar-collapse li .megamenu {
        position: absolute;
        left: 0px;
        top: 100%;
        width: 100%;
        z-index: 100;
        display: none;
        padding: 20px 0;
        background: #ffffff;
        -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05); }
        .main-menu .navbar-collapse li .megamenu:after {
          display: block;
          clear: both;
          content: ""; }
        @media only screen and (max-width: 991px) {
          .main-menu .navbar-collapse li .megamenu {
            position: relative;
            -webkit-box-shadow: none;
            box-shadow: none;
            width: 100%; }
            .main-menu .navbar-collapse li .megamenu .container {
              max-width: 100%; }
            .main-menu .navbar-collapse li .megamenu .row {
              margin: 0px; } }
        .main-menu .navbar-collapse li .megamenu ul {
          display: block;
          position: relative;
          top: 0;
          width: 100%;
          -webkit-box-shadow: none;
          box-shadow: none; }
      .main-menu .navbar-collapse li ul {
        position: absolute;
        left: inherit;
        top: 100%;
        min-width: 265px;
        z-index: 100;
        display: none;
        background: #ffffff;
        -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05); }
        @media only screen and (max-width: 991px) {
          .main-menu .navbar-collapse li ul {
            position: relative;
            display: none;
            width: 100%;
            -webkit-box-shadow: none;
            box-shadow: none; }
            .main-menu .navbar-collapse li ul:after {
              display: block;
              clear: both;
              content: ""; } }
    @media only screen and (max-width: 991px) and (max-width: 375px) {
      .main-menu .navbar-collapse li ul {
        min-width: auto; } }
        .main-menu .navbar-collapse li ul li {
          width: 100%;
          padding: 7px 20px; }
          @media only screen and (max-width: 991px) {
            .main-menu .navbar-collapse li ul li {
              padding: 0 15px; } }
          .main-menu .navbar-collapse li ul li ul {
            left: 100%;
            top: 0%; }
            @media only screen and (max-width: 991px) {
              .main-menu .navbar-collapse li ul li ul {
                left: auto; } }
  
  .main-menu .navbar-header {
    display: none;
    margin-right: 30px; }
    @media only screen and (max-width: 991px) {
      .main-menu .navbar-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start; } }
    @media only screen and (max-width: 375px) {
      .main-menu .navbar-header {
        margin-right: 20px; } }
    .main-menu .navbar-header .navbar-toggle {
      float: right;
      padding: 4px 0;
      cursor: pointer;
      background: transparent; }
      .main-menu .navbar-header .navbar-toggle .icon-bar {
        background: #696e7b;
        height: 2px;
        width: 30px;
        display: block;
        margin: 7px 0; }
  
  /* Menu Btns */
  .menu-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .menu-btns .theme-btn {
      margin-left: 25px;
      margin-right: 25px; }
      .menu-btns .theme-btn {
        margin-left: 25px;
        margin-right: 25px; }
      @media only screen and (max-width: 1199px) {
        .menu-btns .theme-btn {
          display: none; } }
  
  /* Header Search */
  .nav-search {
    position: relative;
    margin-right: auto;
    margin-left: 15px; }
    @media only screen and (max-width: 575px) {
      .nav-search {
        display: none; } }
    .nav-search button {
      background: transparent; }
    .nav-search form {
      position: absolute;
      width: 320px;
      top: 100%;
      right: 0;
      z-index: 777;
      padding: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #fff;
      -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05); }
      .nav-search form.hide {
        display: none; }
      .nav-search form input {
        border: none;
        padding: 15px 5px 15px 25px; }
      .nav-search form button {
        padding: 15px 20px 15px 0; }
  
  /* Menu Sidebar */
  .menu-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (min-width: 576px) and (max-width: 1199px) {
      .menu-sidebar {
        margin-left: 30px; } }
    .menu-sidebar button {
      width: 35px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      cursor: pointer;
      -webkit-box-align: end;
      -ms-flex-align: end;
      /* align-items: flex-end; */
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      background: transparent; }
      .menu-sidebar button .icon-bar {
        background: #293043;
        height: 2px;
        width: 100%;
        margin: 4px 0;
        display: block;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s; }
        .menu-sidebar button .icon-bar:first-child {
          width: calc(100% - 5px); }
        .menu-sidebar button .icon-bar:last-child {
          width: calc(100% - 8px); }
      .menu-sidebar button:hover .icon-bar {
        width: 100%; }
  
  /** hidden-sidebar * */
  .hidden-bar {
    position: fixed;
    right: -350px;
    top: 0px;
    opacity: 0;
    width: 350px;
    height: 100%;
    z-index: 99999;
    overflow-y: auto;
    visibility: hidden;
    background-color: #293043;
    border-left: 1px solid #231b26;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    @media only screen and (max-width: 375px) {
      .hidden-bar {
        width: 300px; } }
    .hidden-bar .social-style-one a {
      width: 40px;
      height: 40px;
      line-height: 40px; }
  
  .side-content-visible .hidden-bar {
    right: 0px;
    opacity: 1;
    visibility: visible; }
  
  .hidden-bar .inner-box {
    position: relative;
    padding: 100px 40px 50px; }
    @media only screen and (max-width: 375px) {
      .hidden-bar .inner-box {
        padding-left: 25px;
        padding-right: 25px; } }
    .hidden-bar .inner-box .cross-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
      color: #ffffff;
      font-size: 20px;
      -webkit-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
    .hidden-bar .inner-box h4 {
      position: relative;
      color: #ffffff;
      margin-bottom: 35px; }
  
  /*Appointment Form */
  .hidden-bar .appointment-form {
    position: relative; }
    .hidden-bar .appointment-form .form-group {
      position: relative;
      margin-bottom: 20px; }
    .hidden-bar .appointment-form input[type="text"], .hidden-bar .appointment-form input[type="email"] {
      position: relative;
      display: block;
      width: 100%;
      line-height: 23px;
      padding: 10px 25px;
      color: #ffffff;
      font-size: 16px;
      background: none;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
      border: 1px solid rgba(255, 255, 255, 0.1); }
    .hidden-bar .appointment-form textarea {
      position: relative;
      display: block;
      width: 100%;
      line-height: 23px;
      padding: 10px 25px;
      color: #ffffff;
      font-size: 16px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: none;
      -webkit-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;
      resize: none; }
    .hidden-bar .appointment-form input::-webkit-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form input:-ms-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form input::-ms-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form input::placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form textarea::-webkit-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form textarea:-ms-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form textarea::-ms-input-placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form textarea::placeholder {
      font-size: 14px;
      color: #bdbdbd; }
    .hidden-bar .appointment-form .form-group button {
      width: 100%;
      font-size: 16px;
      padding: 10px 15px;
      margin-bottom: 20px;
      background-color: #fc653c;
      border-color: white; }
  
  .form-back-drop {
    position: fixed;
    left: 100%;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    z-index: 9990;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  
  .side-content-visible .form-back-drop {
    opacity: 1;
    left: 0;
    visibility: visible; }
  
  /* Header Three */
  .main-header.header-three .nav-outer {
    margin-right: auto; }
    @media only screen and (min-width: 1400px) {
      .main-header.header-three .nav-outer {
        margin-left: 100px; } }
  
  @media only screen and (max-width: 991px) {
    .main-header.header-three .menu-btns {
      display: none; } }
  
  .main-header.header-three .menu-btns .login {
    font-size: 18px;
    font-weight: 500;
    color: #293043; }
    .main-header.header-three .menu-btns .login:hover {
      color: #01c9f5; }
  
  .main-header.header-three .menu-btns .theme-btn {
    margin-right: 0;
    margin-left: 30px; }
  
  .main-header.header-three .navbar-header {
    margin-right: 0; }
  
  /* Menu White */
  .main-header.header-two {
    position: absolute; }
    @media only screen and (min-width: 992px) {
      .main-header.header-two .nav-outer {
        margin-left: 75px; } }
    .main-header.header-two .nav-search > button {
      color: white; }
    @media only screen and (min-width: 992px) {
      .main-header.header-two .main-menu .navigation > li > a,
      .main-header.header-two .main-menu .navigation > .dropdown > .dropdown-btn {
        color: rgb(0, 0, 0); } }
    .main-header.header-two .main-menu .navigation li li a:hover {
      color: #fc653c; }
    .main-header.header-two .menu-sidebar button .icon-bar,
    .main-header.header-two .main-menu .navbar-toggle .icon-bar {
      background: rgb(255, 252, 252); }
      .main-header.header-two .menu-sidebar button .icon-bar1,
      .main-header.header-two .main-menu .navbar-toggle .icon-bar1 {
        background: rgb(221, 150, 19); }
    .main-header.header-two .menu-btns .theme-btn.style-three:not(:hover) {
      border-color: white; }
    .main-header.header-two.fixed-header .header-upper {
      background: #ffffff; }
  
  /*******************************************************/
  /******************** 07. Hero Area ********************/
  /*******************************************************/
  .hero-content {
    z-index: 2;
    max-width: 640px;
    position: relative; }
    .hero-content .sub-title {
      font-weight: 500;
      line-height: 1.5;
      padding: 8px 20px;
      display: inline-block;
      border-radius: 0 5px 5px 0;
      background: #0066ff;
      border-left: 3px solid white; }
      @media only screen and (min-width: 768px) {
        .hero-content .sub-title {
          font-size: 18px; } }
    @media only screen and (max-width: 1399px) {
      .hero-content h1 {
        font-size: 60px; } }
    @media only screen and (max-width: 1199px) {
      .hero-content h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 767px) {
      .hero-content h1 {
        font-size: 45px; } }
    @media only screen and (max-width: 575px) {
      .hero-content h1 {
        font-size: 40px; } }
    @media only screen and (max-width: 375px) {
      .hero-content h1 {
        font-size: 33px;
        line-height: 1.3; } }
    .hero-content p {
      margin-top: 25px;
      padding-left: 30px;
      border-left: 3px solid #01c9f5; }
      @media only screen and (max-width: 1199px) {
        .hero-content p {
          padding-left: 15px; } }
      @media only screen and (max-width: 375px) {
        .hero-content p {
          line-height: 1.5; } }
    .hero-content:before {
      position: absolute;
      content: '';
      top: -65px;
      width: 220px;
      height: 220px;
      left: -110px;
      z-index: -1;
      opacity: 0.1;
      border-radius: 50%;
      border: 2px dashed white;
      -webkit-animation: rotated_circle 30s infinite;
      animation: rotated_circle 30s infinite; }
  
  .hero-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .hero-btns .theme-btn:not(:last-child) {
      margin-right: 15px; }
  
  .hero-images {
    z-index: 1;
    position: relative;
    margin-left: -38%; }
    @media only screen and (min-width: 1400px) {
      .hero-images {
        margin-right: -28%; } }
    @media only screen and (max-width: 991px) {
      .hero-images {
        margin-left: -15%; } }
    .hero-images:after {
      position: absolute;
      width: 210px;
      height: 210px;
      z-index: -1;
      content: '';
      right: -8%;
      top: 15%;
      border-radius: 50%;
      -webkit-filter: blur(110px);
      filter: blur(110px);
      background: #01c9f5;
      -webkit-animation: zoomInOut 5s infinite;
      animation: zoomInOut 5s infinite; }
      @media only screen and (max-width: 1199px) {
        .hero-images:after {
          top: -15%; } }
  
  /* Hero Three */
  .hero-three-shapes .angle {
    position: absolute;
    width: 100%;
    height: 70%;
    content: '';
    left: 0;
    top: 0;
    z-index: -2;
    background: #f7f9fd;
    -webkit-clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0);
    clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0); }
  
  .hero-three-shapes .circle {
    width: 770px;
    height: 770px;
    z-index: -1;
    top: -200px;
    left: -100px;
    background: white;
    border-radius: 50%;
    position: absolute; }
    @media only screen and (max-width: 991px) {
      .hero-three-shapes .circle {
        width: 400px;
        height: 400px; } }
  
  .hero-three-shapes .shape-one,
  .hero-three-shapes .shape-two {
    z-index: -1;
    max-width: 10%;
    position: absolute; }
  
  .hero-three-shapes .shape-one {
    left: 6%;
    top: 15%;
    -webkit-animation: down-up-two 15s infinite;
    animation: down-up-two 15s infinite; }
  
  .hero-three-shapes .shape-two {
    right: 6%;
    top: 25%;
    -webkit-animation: upDownLeft 20s infinite;
    animation: upDownLeft 20s infinite; }
  
  .hero-three-shapes:after, .hero-three-shapes:before {
    position: absolute;
    border: 2px solid;
    content: '';
    width: 15px;
    height: 15px;
    z-index: -1;
    border-radius: 50%; }
  
  .hero-three-shapes:before {
    left: 7%;
    top: 40%;
    border-color: #01c9f5;
    -webkit-animation: shapeAnimationOne 30s infinite;
    animation: shapeAnimationOne 30s infinite; }
  
  .hero-three-shapes:after {
    right: 15%;
    top: 12%;
    border-color: #ff8a00;
    -webkit-animation: shapeAnimationTwo 30s infinite;
    animation: shapeAnimationTwo 30s infinite; }
  
  .hero-content-three h1 {
    font-size: 75px;
    margin-bottom: 25px; }
    @media only screen and (max-width: 1399px) {
      .hero-content-three h1 {
        font-size: 60px; } }
    @media only screen and (max-width: 1199px) {
      .hero-content-three h1 {
        font-size: 53px; } }
    @media only screen and (max-width: 767px) {
      .hero-content-three h1 {
        font-size: 45px; } }
    @media only screen and (max-width: 575px) {
      .hero-content-three h1 {
        font-size: 40px; } }
    @media only screen and (max-width: 375px) {
      .hero-content-three h1 {
        font-size: 32px;
        line-height: 1.3; } }
    .hero-content-three h1 span {
      position: relative;
      color: #01c9f5; }
      .hero-content-three h1 span:after {
        position: absolute;
        content: '';
        left: 4%;
        z-index: -1;
        bottom: -13%;
        width: 96%;
        height: 28%;
        background: url("../public/images/shapes/hero-title-bg-line.png") no-repeat center/cover; }
  
  @media only screen and (min-width: 768px) {
    .hero-content-three p {
      font-size: 20px;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto; } }
  
  .hero-content-three .hero-btns {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media only screen and (max-width: 375px) {
      .hero-content-three .hero-btns .theme-btn:not(:last-child) {
        margin-right: 0; } }
  
  .hero-content-three .video-play-text i {
    background: #ff8a00; }
  
  .hero-content-three .video-play-text span:not(:hover) {
    color: #293043; }
  
  /*******************************************************/
  /******************* 08. Page Banner *******************/
  /*******************************************************/
  .banner-inner h1 {
    font-size: 75px;
    font-weight: 400;
    text-transform: capitalize; }
    @media only screen and (min-width: 376px) {
      .banner-inner h1 {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) {
      .banner-inner h1 {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 991px) {
      .banner-inner h1 {
        font-size: 60px; } }
    @media only screen and (max-width: 767px) {
      .banner-inner h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 575px) {
      .banner-inner h1 {
        font-size: 45px; } }
    @media only screen and (max-width: 375px) {
      .banner-inner h1 {
        font-size: 35px;
        line-height: 1.25; } }
    .banner-inner h1 span {
      z-index: 1;
      position: relative; }
      .banner-inner h1 span:after {
        position: absolute;
        content: '';
        z-index: -1;
        left: 0;
        top: 80%;
        height: 20px;
        width: 100%;
        background: url("../public/images/shapes/page-title-bg-line.png") no-repeat center/contain; }
  
  .breadcrumb {
    padding: 0;
    margin: 0;
    font-size: 24px;
    background: transparent;
    text-transform: capitalize; }
    @media only screen and (max-width: 575px) {
      .breadcrumb {
        font-size: 20px; } }
    @media only screen and (max-width: 375px) {
      .breadcrumb {
        font-size: 18px; } }
    .breadcrumb .breadcrumb-item {
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .breadcrumb .breadcrumb-item a:not(:hover) {
        color: #293043; }
      .breadcrumb .breadcrumb-item.active {
        color: #0066ff;
        text-decoration: underline; }
      .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
        margin-left: 15px;
        margin-right: 15px;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        content: "\f101";
        color: #293043;
        font-family: "Font Awesome 5 Pro"; }
  
  /* Banner Shapes */
  .banner-shapes .circle {
    width: 770px;
    height: 770px;
    z-index: -2;
    top: -300px;
    left: -200px;
    background: #78b6ab;
    border-radius: 50%;
    position: absolute; }
    @media only screen and (max-width: 991px) {
      .banner-shapes .circle {
        width: 400px;
        height: 400px; } }
  
  .banner-shapes .shape-one,
  .banner-shapes .shape-two {
    z-index: -1;
    max-width: 10%;
    position: absolute; }
  
  .banner-shapes .shape-one {
    left: 6%;
    top: 35%;
    -webkit-animation: down-up-two 15s infinite;
    animation: down-up-two 15s infinite; }
  
  .banner-shapes .shape-two {
    right: 6%;
    top: 65%;
    -webkit-animation: upDownLeft 20s infinite;
    animation: upDownLeft 20s infinite; }
  
  .banner-shapes:after, .banner-shapes:before {
    position: absolute;
    border: 2px solid;
    content: '';
    width: 15px;
    height: 15px;
    z-index: -1;
    border-radius: 50%; }
  
  .banner-shapes:before {
    left: 10%;
    top: 65%;
    border-color: #01c9f5;
    -webkit-animation: shapeAnimationOne 30s infinite;
    animation: shapeAnimationOne 30s infinite; }
  
  .banner-shapes:after {
    right: 10%;
    top: 20%;
    border-color: #ff8a00;
    -webkit-animation: shapeAnimationTwo 30s infinite;
    animation: shapeAnimationTwo 30s infinite; }
  
  /*******************************************************/
  /******************* 09. Main Slider *******************/
  /*******************************************************/
  .main-slider-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-height: 870px;
    padding-top: 110px;
    padding-bottom: 110px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  
  .main-slider-active {
    width: 100%; }
    .main-slider-active .slick-list {
      overflow: visible; }
  
  .main-slider-dots {
    margin-top: -135px;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content; }
    @media only screen and (max-width: 991px) {
      .main-slider-dots {
        margin-top: -635px; } }
    @media only screen and (max-width: 575px) {
      .main-slider-dots {
        margin-top: -485px; } }
    .main-slider-dots .slick-dots li {
      border-color: white; }
      .main-slider-dots .slick-dots li.slick-active {
        background: white; }
  
  .slider-content {
    z-index: 2;
    max-width: 700px;
    margin-top: -7px;
    position: relative;
    padding-top: 165px;
    padding-bottom: 165px; }
    @media only screen and (max-width: 1199px) {
      .slider-content {
        max-width: 580px; } }
    @media only screen and (max-width: 1199px) {
      .slider-content {
        padding-top: 80px; } }
    @media only screen and (max-width: 575px) {
      .slider-content {
        padding-top: 55px; } }
    .slider-content .sub-title {
      color: white;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      display: inline-block;
      font-family: 'Catamaran', sans-serif; }
    .slider-content h1 {
      color: white;
      font-size: 85px;
      line-height: 1.1;
      margin-bottom: 30px;
      letter-spacing: -3px; }
      @media only screen and (max-width: 1199px) {
        .slider-content h1 {
          font-size: 70px; } }
      @media only screen and (max-width: 767px) {
        .slider-content h1 {
          font-size: 60px; } }
      @media only screen and (max-width: 575px) {
        .slider-content h1 {
          font-size: 46px; } }
      @media only screen and (max-width: 375px) {
        .slider-content h1 {
          font-size: 38px; } }
    .slider-content .slider-btns {
      margin-left: -5px;
      margin-right: -5px; }
      .slider-content .slider-btns .theme-btn {
        margin: 10px 5px 0; }
  
  .slider-video {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    right: 70px;
    top: 0;
    width: 52%;
    height: 100%;
    background-size: cover; }
    @media only screen and (max-width: 991px) {
      .slider-video {
        right: 0;
        width: 100%;
        height: 500px;
        position: relative; } }
    @media only screen and (max-width: 575px) {
      .slider-video {
        height: 350px; } }
    .slider-video .video-play {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .slider-video .video-play:hover {
        color: white; }
    .slider-video .video-title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      color: white;
      line-height: 1;
      font-size: 80px;
      text-align: center;
      text-transform: uppercase;
      -webkit-transform: translateY(60px);
      -ms-transform: translateY(60px);
      transform: translateY(60px);
      font-family: "Zurdo W01 Regular"; }
      @media only screen and (max-width: 1199px) {
        .slider-video .video-title {
          font-size: 80px; } }
      @media only screen and (max-width: 767px) {
        .slider-video .video-title {
          font-size: 70px; } }
      @media only screen and (max-width: 575px) {
        .slider-video .video-title {
          font-size: 60px; } }
      @media only screen and (max-width: 375px) {
        .slider-video .video-title {
          font-size: 50px; } }
      .slider-video .video-title .cd-words-wrapper {
        padding: 0; }
        .slider-video .video-title .cd-words-wrapper span {
          padding: 0; }
        .slider-video .video-title .cd-words-wrapper:after {
          background: transparent; }
  
  .slider-video,
  .slider-content > * {
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  
  .slick-active .slider-video {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight; }
  
  .slick-active .slider-content * {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp; }
  
  .slick-active .sub-title {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  
  .slick-active h1 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s; }
  
  .slick-active .slider-btns {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s; }
  
  .slider-shapes .shape {
    z-index: -1;
    position: absolute; }
  
  .slider-shapes .circle {
    top: 20%;
    left: 5%;
    -webkit-animation: shapeAnimationThree 40s infinite;
    animation: shapeAnimationThree 40s infinite; }
  
  .slider-shapes .dots {
    max-width: 10%;
    -webkit-animation: rotated_circle 30s infinite;
    animation: rotated_circle 30s infinite; }
    .slider-shapes .dots.one {
      top: -10px;
      right: -30px;
      -webkit-animation-delay: 5s;
      animation-delay: 5s; }
    .slider-shapes .dots.two {
      left: 42%;
      bottom: 40px; }
  
  .slider-shapes .wave-line {
    max-width: 30%;
    left: 0;
    bottom: 0;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  
  /*******************************************************/
  /****************** 10. About Section ******************/
  /*******************************************************/
  .about-image {
    max-width: 100%;
    padding-top: 18%;
    padding-right: 11%;
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .about-image .years-fo-experience {
      position: absolute;
      right: 0;
      top: 0;
      width: 90%;
      max-width: 400px;
      padding: 40px 60px; }
      @media only screen and (max-width: 479px) {
        .about-image .years-fo-experience {
          max-width: 290px;
          padding: 20px 30px; } }
      .about-image .years-fo-experience .count-text {
        color: white;
        margin-bottom: 20px;
        display: inline-block; }
        @media only screen and (min-width: 480px) {
          .about-image .years-fo-experience .count-text {
            font-size: 65px; } }
      .about-image .years-fo-experience h4 {
        color: white; }
        @media only screen and (max-width: 479px) {
          .about-image .years-fo-experience h4 {
            font-size: 20px; } }
        @media only screen and (max-width: 375px) {
          .about-image .years-fo-experience h4 {
            font-size: 16px; } }
  
  .client-satisfactions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 444px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media only screen and (max-width: 479px) {
      .client-satisfactions {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .client-satisfactions img,
    .client-satisfactions .heading,
    .client-satisfactions .count-text {
      margin-bottom: 15px; }
    .client-satisfactions img {
      margin-right: 25px; }
    .client-satisfactions .count-text {
      margin-right: 20px; }
      @media only screen and (min-width: 376px) {
        .client-satisfactions .count-text {
          font-size: 55px; } }
    .client-satisfactions .heading {
      font-size: 22px; }
  
  /* About Home One */
  .about-image-one .image-left {
    position: absolute;
    right: 100%;
    top: 5%;
    width: 35%;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  
  .about-content-one .list-style-one li {
    position: relative; }
    .about-content-one .list-style-one li:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(0, 102, 255, 0.1); }
    @media only screen and (min-width: 376px) {
      .about-content-one .list-style-one li p {
        font-size: 18px; } }
    .about-content-one .list-style-one li:before {
      content: '';
      width: 40px;
      height: 40px;
      margin-right: 40px; }
      @media only screen and (max-width: 375px) {
        .about-content-one .list-style-one li:before {
          margin-right: 25px; } }
    .about-content-one .list-style-one li:after {
      content: '';
      width: 40px;
      height: 40px;
      left: 10px;
      top: 15px;
      position: absolute;
      border-radius: 50%;
      border: 1px solid #293043; }
  
  .bg-squire-shape {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: 50px;
    margin-right: 50px;
    z-index: 1;
    position: relative;
    max-width: calc(100% - 100px); }
    .bg-squire-shape::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      opacity: 1;
      background-color: #fc653c; }
    .bg-squire-shape:before {
      -webkit-transform: rotate(-11deg);
      -ms-transform: rotate(-11deg);
      transform: rotate(-11deg);
      -webkit-animation: semi_rotated_two 20s infinite;
      animation: semi_rotated_two 20s infinite; }
  
  /* Why Choose Us */
  .circle-shapes {
    z-index: -1;
    top: 0;
    width: 55%;
    right: 33px;
    padding-top: 55%;
    position: absolute;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%); }
    .circle-shapes .shape {
      opacity: 0.04;
      position: absolute;
      border-radius: 50%;
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      background: #0e1b38; }
      .circle-shapes .shape.one {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; }
      .circle-shapes .shape.two {
        width: 82%;
        height: 82%;
        left: 9%;
        top: 9%; }
      .circle-shapes .shape.three {
        width: 64%;
        height: 64%;
        left: 18%;
        top: 18%; }
      .circle-shapes .shape.four {
        width: 46%;
        height: 46%;
        left: 27%;
        top: 27%; }
  
  /* Why Choose Us Two */
  .support-marketing-list .list-style-one li,
  .why-choose-two-content .list-style-one li {
    padding: 25px 30px;
    -webkit-box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5);
    box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5); }
    @media only screen and (max-width: 375px) {
      .support-marketing-list .list-style-one li,
      .why-choose-two-content .list-style-one li {
        padding-left: 25px;
        padding-right: 25px; } }
    .support-marketing-list .list-style-one li:not(:last-child),
    .why-choose-two-content .list-style-one li:not(:last-child) {
      margin-bottom: 15px; }
    @media only screen and (min-width: 1200px) {
      .support-marketing-list .list-style-one li:nth-child(odd),
      .why-choose-two-content .list-style-one li:nth-child(odd) {
        margin-right: 50px; } }
    @media only screen and (min-width: 480px) and (max-width: 991px) {
      .support-marketing-list .list-style-one li:nth-child(odd),
      .why-choose-two-content .list-style-one li:nth-child(odd) {
        margin-right: 50px; } }
    @media only screen and (min-width: 1200px) {
      .support-marketing-list .list-style-one li:nth-child(even),
      .why-choose-two-content .list-style-one li:nth-child(even) {
        margin-left: 50px; } }
    @media only screen and (min-width: 480px) and (max-width: 991px) {
      .support-marketing-list .list-style-one li:nth-child(even),
      .why-choose-two-content .list-style-one li:nth-child(even) {
        margin-left: 50px; } }
    .support-marketing-list .list-style-one li:before,
    .why-choose-two-content .list-style-one li:before {
      width: 35px;
      height: 35px;
      font-size: 16px;
      line-height: 35px; }
  
  /* What We Do */
  .ww-do-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .ww-do-images .image-one {
      margin-right: -5%;
      -webkit-box-shadow: 10px 0 60px rgba(156, 156, 156, 0.4);
      box-shadow: 10px 0 60px rgba(156, 156, 156, 0.4); }
    .ww-do-images .image-two {
      z-index: 2;
      max-width: 90%;
      position: relative;
      margin: -10% auto 0 auto; }
    .ww-do-images .image-three {
      bottom: 10%;
      right: -11%;
      max-width: 40%;
      position: absolute; }
      @media only screen and (max-width: 1399px) {
        .ww-do-images .image-three {
          right: -15px; } }
  
  /* What We Do Two */
  .ww-do-two-content .feature-item-two {
    padding: 0;
    border-radius: 0; }
    .ww-do-two-content .feature-item-two .icon {
      width: 30px;
      height: 30px;
      font-size: 12px;
      line-height: 30px; }
  
  .ww-do-two-content .feature-item-two,
  .ww-do-two-content .feature-item-two.color-two {
    background: transparent; }
  
  .ww-do-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .ww-do-btns .theme-btn:not(:last-child) {
      margin-right: 15px; }
    .ww-do-btns .video-play-text i {
      background: #ff8a00; }
    .ww-do-btns .video-play-text span {
      color: #293043; }
  
  @media only screen and (min-width: 576px) {
    .border-right {
      border-right: 1px solid rgba(0, 102, 255, 0.1); } }
  
  .ww-do-two-images .image-one {
    margin-top: 60px; }
    @media only screen and (max-width: 575px) {
      .ww-do-two-images .image-one {
        margin-top: 0; } }
  
  @media only screen and (max-width: 575px) {
    .ww-do-two-images .image-two {
      margin-right: 30px;
      width: calc(40% - 18px); } }
  
  .ww-do-two-images .image-three {
    margin: 40px -55px 50px 0;
    max-width: calc(100% + 55px); }
    @media only screen and (max-width: 575px) {
      .ww-do-two-images .image-three {
        margin-right: 0;
        margin-bottom: 0;
        width: calc(60% - 18px); } }
  
  .year-of-experience {
    position: absolute;
    color: white;
    left: 0;
    top: 50%;
    width: 200px;
    height: 200px;
    padding: 30px;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    border-radius: 50%;
    background: #0066ff;
    -webkit-transform: translate(-20%, -20%);
    -ms-transform: translate(-20%, -20%);
    transform: translate(-20%, -20%); }
    @media only screen and (max-width: 575px) {
      .year-of-experience {
        -webkit-transform: translate(0, -40%);
        -ms-transform: translate(0, -40%);
        transform: translate(0, -40%); } }
    @media only screen and (max-width: 375px) {
      .year-of-experience {
        width: 165px;
        height: 165px;
        font-size: 16px;
        -webkit-transform: translate(0, -30%);
        -ms-transform: translate(0, -30%);
        transform: translate(0, -30%); } }
    .year-of-experience .number {
      display: block;
      line-height: 1;
      font-size: 65px;
      margin-bottom: 15px;
      font-family: "Merriweather", serif; }
      @media only screen and (max-width: 375px) {
        .year-of-experience .number {
          font-size: 45px; } }
  
  /* What We Offer */
  @media only screen and (min-width: 992px) {
    .what-we-offer:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      left: 50%;
      top: 0;
      background: rgba(0, 102, 255, 0.1); } }
  
  /* Responsive Design */
  @media only screen and (min-width: 992px) {
    .responsive-design-image {
      margin-right: -55px; } }
  
  /* Support & Marketing */
  .support-marketing-progress {
    max-width: 555px; }
  
  .circle-counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  
  .circle-progress-item {
    text-align: center;
    margin: 30px 8px 0; }
    .circle-progress-item h5 {
      margin: 5px 0 0; }
  
  .progress-content {
    position: relative; }
    .progress-content h3 {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      margin-bottom: 0;
      /* padding-bottom: 10px;  */
    }
  
  .support-marketing-list {
    z-index: 1;
    position: relative;
    padding: 30px 30px 30px 0; }
    @media only screen and (max-width: 575px) {
      .support-marketing-list {
        padding-right: 15px; } }
    .support-marketing-list:before {
      position: absolute;
      width: 80%;
      height: 100%;
      content: '';
      top: 0;
      right: 0;
      z-index: -1;
      background: #f7f9fd; }
    .support-marketing-list .list-style-one li {
      background: white; }
      .support-marketing-list .list-style-one li:before {
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 30px; }
      .support-marketing-list .list-style-one li:not(:last-child) {
        margin-bottom: 30px; }
      .support-marketing-list .list-style-one li:nth-child(1) {
        margin-right: 0;
        margin-left: 60px; }
        @media only screen and (max-width: 479px) {
          .support-marketing-list .list-style-one li:nth-child(1) {
            margin-left: 0px; } }
      .support-marketing-list .list-style-one li:nth-child(2) {
        margin-left: 30px;
        margin-right: 30px; }
        @media only screen and (max-width: 479px) {
          .support-marketing-list .list-style-one li:nth-child(2) {
            margin-left: 0px;
            margin-right: 0px; } }
        .support-marketing-list .list-style-one li:nth-child(2):before {
          background: #ff8a00; }
      .support-marketing-list .list-style-one li:nth-child(3) {
        margin-right: 60px; }
        @media only screen and (max-width: 479px) {
          .support-marketing-list .list-style-one li:nth-child(3) {
            margin-right: 0px; } }
        .support-marketing-list .list-style-one li:nth-child(3):before {
          background: #01c9f5; }
  
  /*******************************************************/
  /****************** 11. Counter Area *******************/
  /*******************************************************/
  .count-text:after {
    content: '+'; }
  
  .counter-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .counter-item > i {
      font-size: 60px;
      margin-bottom: 25px;
      color: #fc653c; }
    .counter-item .counter-title {
      margin-top: 10px; }
    .counter-item.style-two {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      padding: 35px 30px 15px;
      border: 1px solid rgba(0, 102, 255, 0.1); }
      .counter-item.style-two .count-text {
        font-weight: 500;
        font-size: 40px; }
      .counter-item.style-two .counter-title {
        color: #fc653c;
        font-weight: 500;
        margin-bottom: 8px; }
        @media only screen and (min-width: 376px) {
          .counter-item.style-two .counter-title {
            font-size: 18px; } }
      .counter-item.style-two:hover {
        border-color: white;
        -webkit-box-shadow: 10px 0 60px rgba(178, 178, 178, 0.3);
        box-shadow: 10px 0 60px rgba(178, 178, 178, 0.3); }
  
  .counter-cta {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 7px;
    text-align: center;
    padding: 20px 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    background: #0066ff; }
    @media only screen and (max-width: 375px) {
      .counter-cta {
        padding-left: 10px;
        padding-right: 10px; } }
    .counter-cta h4,
    .counter-cta .theme-btn {
      margin: 10px 15px; }
  
  /* Statistics Two */
  .statistics-two-counters .counter-item {
    padding: 40px;
    background: white; }
    .statistics-two-counters .counter-item > i {
      color: #0066ff; }
    .statistics-two-counters .counter-item .count-text {
      font-size: 40px; }
    .statistics-two-counters .counter-item .counter-title {
      font-weight: 500; }
    @media only screen and (min-width: 576px) {
      .statistics-two-counters .counter-item.for-margin {
        margin-top: -30px; } }
  
  /* Statistics Three */
  .statistics-three-image:before {
    background: #f1f1f5; }
  
  .statistics-three-image .image-right {
    position: absolute;
    left: 110%;
    top: -5%;
    width: 26%;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  
  /* Statistics Four */
  .statistics-area-four {
    margin-top: -200px;
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px); }
  
  .statistics-four-counters {
    padding: 60px 70px 30px; }
    @media only screen and (max-width: 767px) {
      .statistics-four-counters {
        padding-left: 25px;
        padding-right: 25px; } }
    .statistics-four-counters .counter-item {
      padding: 40px;
      background: white;
      border-radius: 7px;
      -webkit-box-shadow: 10px 0 60px rgba(64, 64, 64, 0.1);
      box-shadow: 10px 0 60px rgba(64, 64, 64, 0.1); }
      @media only screen and (max-width: 575px) {
        .statistics-four-counters .counter-item {
          margin-top: 0; } }
      @media only screen and (max-width: 375px) {
        .statistics-four-counters .counter-item {
          padding-left: 25px;
          padding-right: 25px; } }
  
  /* Statistics Five */
  .statistics-five-content {
    max-width: 535px; }
  
  /*******************************************************/
  /****************** 12. Features Area ******************/
  /*******************************************************/
  .feature-item {
    text-align: center;
    margin-bottom: 30px; }
    .feature-item .image {
      height: 150px;
      padding: 25px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 7px 7px 0 0;
      border: 1px solid rgba(0, 102, 255, 0.1); }
    .feature-item h5 {
      color: white;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 0;
      padding: 9px 15px;
      border-radius: 0 0 7px 7px;
      background: #78b6ab; }
    .feature-item:hover .image {
      border-color: white; }
    .feature-item:hover h5 {
      background: #76ada0; }
  
  @media only screen and (min-width: 376px) {
    .support-features-content p {
      font-size: 18px; } }
  
  /* Feature Two */
  .feature-item-two {
    border-radius: 7px;
    margin-bottom: 30px;
    background: #f4f9ff;
    padding: 40px 30px 20px 40px; }
    @media only screen and (max-width: 767px) {
      .feature-item-two {
        margin-top: 0; } }
    .feature-item-two .icon {
      color: white;
      margin-bottom: 25px;
      width: 40px;
      height: 40px;
      background: #0066ff;
      line-height: 40px;
      border-radius: 50%;
      text-align: center; }
    .feature-item-two.color-two {
      background: #effafe; }
      .feature-item-two.color-two .icon {
        background: #01c9f5; }
    .feature-item-two.color-three {
      background: #fef9ed; }
      .feature-item-two.color-three .icon {
        background: #ff8a00; }
    .feature-item-two.color-four {
      background: #f9f2ff; }
      .feature-item-two.color-four .icon {
        background: #8400ff; }
  
  /* Feature Three */
  .feature-item-three {
    background: white;
    border-radius: 7px;
    padding: 25px 35px 10px;
    -webkit-box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5);
    box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5); }
    @media only screen and (min-width: 376px) {
      .feature-item-three {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    @media only screen and (max-width: 479px) {
      .feature-item-three {
        padding-left: 25px;
        padding-right: 25px; } }
    .feature-item-three > i {
      font-size: 55px;
      margin-top: 10px;
      margin-right: 25px;
      color: #01c9f5; }
      @media only screen and (max-width: 375px) {
        .feature-item-three > i {
          margin: 0 0 15px 0;
          display: inline-block; } }
    .feature-item-three .content h4 {
      font-size: 22px; }
    .feature-item-three.color-two > i {
      color: #0066ff; }
    .feature-item-three.color-three > i {
      color: #ff8a00; }
    .feature-item-three.color-four > i {
      color: #8400ff; }
  
  /* Feature With Image */
  .feature-with-image {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .feature-with-image:not(:last-child) {
      margin-bottom: 80px; }
    @media only screen and (min-width: 1200px) {
      .feature-with-image:nth-child(odd) .feature-image {
        margin-left: 100px; } }
    @media only screen and (min-width: 1200px) {
      .feature-with-image:nth-child(even) .feature-content {
        margin-left: 100px; } }
    .feature-with-image .feature-image,
    .feature-with-image .feature-content {
      width: 40%; }
      @media only screen and (max-width: 991px) {
        .feature-with-image .feature-image,
        .feature-with-image .feature-content {
          width: 48%; } }
      @media only screen and (max-width: 767px) {
        .feature-with-image .feature-image,
        .feature-with-image .feature-content {
          width: 100%; } }
    .feature-with-image .feature-image {
      max-width: 495px; }
      @media only screen and (max-width: 767px) {
        .feature-with-image .feature-image {
          margin-bottom: 35px; } }
    .feature-with-image .feature-content {
      max-width: 410px; }
      @media only screen and (max-width: 767px) {
        .feature-with-image .feature-content {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
          max-width: none; } }
      .feature-with-image .feature-content h3 {
        margin-bottom: 20px; }
        @media only screen and (min-width: 376px) {
          .feature-with-image .feature-content h3 {
            font-size: 35px; } }
      .feature-with-image .feature-content .read-more {
        font-size: 18px;
        margin-top: 15px;
        color: #01c9f5; }
        .feature-with-image .feature-content .read-more i {
          color: #01c9f5; }
        .feature-with-image .feature-content .read-more:hover {
          color: #ff8a00; }
          .feature-with-image .feature-content .read-more:hover i {
            color: #ff8a00; }
    .feature-with-image .feature-shape {
      max-width: 15%;
      text-align: center; }
      @media only screen and (max-width: 991px) {
        .feature-with-image .feature-shape {
          display: none; } }
  
  /* Feature Four */
  .feature-item-four {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 30px;
    padding: 30px 40px 20px;
    border: 1px solid rgba(0, 102, 255, 0.1); }
    @media only screen and (max-width: 375px) {
      .feature-item-four {
        padding-left: 25px;
        padding-right: 25px; } }
    .feature-item-four h4 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 22px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 15px; }
      @media only screen and (max-width: 991px) {
        .feature-item-four h4 {
          font-size: 20px; } }
      .feature-item-four h4 i {
        line-height: 0;
        font-size: 55px;
        margin-top: 3px;
        margin-right: 20px;
        color: #0066ff; }
        @media only screen and (max-width: 375px) {
          .feature-item-four h4 i {
            font-size: 45px;
            margin-right: 15px; } }
    .feature-item-four:hover {
      border-color: white;
      -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3);
      box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3); }
  
  .text-white .feature-with-image p {
    opacity: 0.6; }
  
  /* Feature Five */
  .feature-item-five {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    text-align: center;
    padding: 35px 20px;
    border-radius: 7px;
    margin-bottom: 30px;
    background-color: white; }
    .feature-item-five:before {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 5px; }
    .feature-item-five i {
      line-height: 1;
      font-size: 60px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 10px;
      color: #fc653c;
      display: inline-block; }
    .feature-item-five h5 {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 0; }
    .feature-item-five:hover {
      background-size: cover;
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
      background-color: #fc653c;
      background-image: url("../public/images/shapes/feature-bg.png"); }
      .feature-item-five:hover i, .feature-item-five:hover h5, .feature-item-five:hover h5 a {
        color: white; }
  
  /* Core Design Featured */
  .feature-middle .image {
    max-width: 100%;
    padding-top: 45px;
    position: relative;
    margin-bottom: -100px;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto; }
    .feature-middle .image .circle-text {
      top: -12px;
      left: 49%;
      max-width: 100%;
      position: absolute;
      -webkit-transform: translate(-51%);
      -ms-transform: translate(-51%);
      transform: translate(-51%); }
    .feature-middle .image .round {
      border-radius: 200px; }
  
  @media only screen and (max-width: 991px) {
    .feature-middle .service-item {
      margin-top: 0; } }
  
  @media only screen and (min-width: 992px) {
    .feature-left > .row > div:first-child .service-item {
      margin-left: 30px; }
    .feature-left > .row > div:last-child .service-item {
      margin-right: 30px; } }
  
  @media only screen and (min-width: 992px) {
    .feature-right > .row > div:first-child .service-item {
      margin-right: 30px; }
    .feature-right > .row > div:last-child .service-item {
      margin-left: 30px; } }
  
  @media only screen and (max-width: 991px) {
    .feature-right .service-item {
      margin-top: 0; } }
  
  .design-feature-shapes .shape {
    z-index: -1;
    position: absolute; }
    .design-feature-shapes .shape.dots {
      top: 15%;
      right: 8%;
      max-width: 10%;
      -webkit-animation: rotated_circle 30s infinite;
      animation: rotated_circle 30s infinite; }
    .design-feature-shapes .shape.wave-line {
      max-width: 35%;
      left: 0;
      bottom: 0;
      -webkit-animation: down-up-one 10s infinite;
      animation: down-up-one 10s infinite; }
  
  /* Feature Six */
  .freature-area-six {
    margin-top: -250px;
    -webkit-transform: translateY(250px);
    -ms-transform: translateY(250px);
    transform: translateY(250px); }
  
  .feature-six-inner {
    padding: 80px 80px 50px; }
    @media only screen and (max-width: 767px) {
      .feature-six-inner {
        padding-left: 25px;
        padding-right: 25px; } }
    .feature-six-inner .feature-item-three {
      margin-bottom: 30px; }
      @media only screen and (max-width: 479px) {
        .feature-six-inner .feature-item-three {
          display: block; } }
  
  /* Feature Seven */
  .feature-item-seven {
    text-align: center;
    border-radius: 7px;
    margin-bottom: 30px;
    padding: 35px 25px 28px;
    background: rgba(49, 128, 252, 0.05); }
    @media only screen and (max-width: 575px) {
      .feature-item-seven {
        margin-top: 0; } }
    .feature-item-seven .icon {
      width: 70px;
      height: 70px;
      color: white;
      font-size: 40px;
      line-height: 70px;
      border-radius: 7px;
      margin: 0 auto 22px;
      background: #3180fc;
      -webkit-box-shadow: 10px 0 60px rgba(49, 128, 252, 0.55);
      box-shadow: 10px 0 60px rgba(49, 128, 252, 0.55); }
      .feature-item-seven .icon i:before {
        line-height: inherit; }
    .feature-item-seven p {
      margin-bottom: 0; }
    .feature-item-seven.color-two {
      background: rgba(22, 180, 242, 0.05); }
      .feature-item-seven.color-two .icon {
        background: #16b4f2;
        -webkit-box-shadow: 10px 0 60px rgba(22, 180, 242, 0.55);
        box-shadow: 10px 0 60px rgba(22, 180, 242, 0.55); }
    .feature-item-seven.color-three {
      background: rgba(241, 176, 0, 0.05); }
      .feature-item-seven.color-three .icon {
        background: #f1b000;
        -webkit-box-shadow: 10px 0 60px rgba(241, 176, 0, 0.55);
        box-shadow: 10px 0 60px rgba(241, 176, 0, 0.55); }
    .feature-item-seven.color-four {
      background: rgba(132, 0, 255, 0.05); }
      .feature-item-seven.color-four .icon {
        background: #8400ff;
        -webkit-box-shadow: 10px 0 60px rgba(132, 0, 255, 0.55);
        box-shadow: 10px 0 60px rgba(132, 0, 255, 0.55); }
    .feature-item-seven.color-five {
      background: rgba(255, 117, 6, 0.05); }
      .feature-item-seven.color-five .icon {
        background: #ff7506;
        -webkit-box-shadow: 10px 0 60px rgba(255, 117, 6, 0.55);
        box-shadow: 10px 0 60px rgba(255, 117, 6, 0.55); }
  
  /*******************************************************/
  /****************** 13. Services Area ******************/
  /*******************************************************/
  .service-item {
    z-index: 1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    padding: 40px 30px;
    text-align: center;
    background: white;
    border-radius: 7px;
    margin-bottom: 30px; }
    .service-item .icon {
      font-size: 65px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 25px;
      color: #0066ff; }
    .service-item h5 {
      margin-bottom: 15px; }
      .service-item h5 a {
        text-decoration: underline; }
    .service-item p {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      margin-bottom: 10px; }
    .service-item:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: url("../public/images/services/service-bg.png") no-repeat center/cover; }
    .service-item:hover {
      background: #0066ff;
      -webkit-box-shadow: 10px 0 60px rgba(0, 102, 254, 0.25);
      box-shadow: 10px 0 60px rgba(0, 102, 254, 0.25); }
      .service-item:hover:after {
        opacity: 1; }
      .service-item:hover .icon, .service-item:hover a, .service-item:hover p {
        color: white; }
    .service-item.style-two {
      padding: 35px 25px; }
      .service-item.style-two h5 {
        margin-bottom: 0; }
        .service-item.style-two h5 a {
          text-decoration: none; }
      .service-item.style-two:after {
        background-image: url("../public/images/services/service-bg-two.png"); }
      .service-item.style-two:hover {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px); }
    .service-item.style-three {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #16161c; }
      .service-item.style-three .icon {
        font-size: 70px;
        margin-bottom: 22px; }
        .service-item.style-three .icon i {
          color: #fc653c; }
      .service-item.style-three h4 {
        font-size: 22px; }
      .service-item.style-three .more-btn {
        font-size: 50px; }
      .service-item.style-three:after {
        display: none; }
  
  /* Service Four */
  .service-item-four {
    padding: 40px;
    background: white;
    text-align: center;
    border-radius: 7px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 375px) {
      .service-item-four {
        padding-left: 25px;
        padding-right: 25px; } }
    .service-item-four .image {
      width: 100%;
      padding-top: 70%;
      position: relative;
      margin-bottom: 25px; }
      .service-item-four .image img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 75%; }
    .service-item-four h5 {
      line-height: 1.6;
      margin-bottom: 22px; }
      @media only screen and (min-width: 376px) {
        .service-item-four h5 {
          font-size: 20px; } }
    .service-item-four .theme-btn {
      width: 100%; }
  
  /* Service Five */
  .service-item-five {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding: 40px 50px;
    border-radius: 7px;
    margin-bottom: 30px;
    border: 1px solid rgba(0, 102, 255, 0.1); }
    @media only screen and (max-width: 375px) {
      .service-item-five {
        padding-left: 25px;
        padding-right: 25px; } }
    .service-item-five .icon {
      margin-bottom: 30px; }
    .service-item-five h4 {
      margin-bottom: 15px; }
    .service-item-five .theme-btn.style-three {
      border: none;
      margin-top: 5px;
      background: #f0f3f9; }
    .service-item-five:hover {
      border-color: white;
      -webkit-box-shadow: 10px 0 60px rgba(153, 153, 153, 0.2);
      box-shadow: 10px 0 60px rgba(153, 153, 153, 0.2); }
  
  /*******************************************************/
  /****************** 14. Projects Area ******************/
  /*******************************************************/
  .project-area:after {
    content: '';
    left: 0;
    bottom: 0;
    z-index: -1;
    position: absolute;
    background: #ebeae982;
    width: 100%;
    height: calc(100% - 165px); }
  
  .project-item {
    text-align: center;
    margin-bottom: 30px; }
    .project-item .project-iamge {
      overflow: hidden;
      position: relative; }
      .project-item .project-iamge img {
        width: 100%; }
    .project-item .project-over {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 100%;
      -webkit-transition: 0.75s;
      -o-transition: 0.75s;
      transition: 0.75s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .project-item .project-over:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        mix-blend-mode: multiply;
        background: #0066ff; }
      .project-item .project-over h3,
      .project-item .project-over .details-btn {
        z-index: 1;
        opacity: 0;
        -webkit-transition: 0.35s;
        -o-transition: 0.35s;
        transition: 0.35s;
        margin-left: -25px;
        position: relative; }
      .project-item .project-over .details-btn {
        margin-bottom: 20px; }
      .project-item .project-over h3 {
        color: white;
        margin-bottom: 0; }
        @media only screen and (max-width: 479px) {
          .project-item .project-over h3 {
            font-size: 25px; } }
    .project-item .project-content {
      padding: 22px;
      -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3);
      box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3); }
      .project-item .project-content h5 {
        margin-bottom: 0; }
        @media only screen and (min-width: 376px) {
          .project-item .project-content h5 {
            font-size: 20px; } }
    .project-item:hover .project-over {
      bottom: 0;
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
      .project-item:hover .project-over h3,
      .project-item:hover .project-over .details-btn {
        opacity: 1;
        margin-left: 0; }
      .project-item:hover .project-over .details-btn {
        -webkit-transition-delay: 0.7s;
        -o-transition-delay: 0.7s;
        transition-delay: 0.7s; }
      .project-item:hover .project-over h3 {
        -webkit-transition-delay: 0.9s;
        -o-transition-delay: 0.9s;
        transition-delay: 0.9s; }
    .project-item.style-two .project-over .details-btn {
      margin-bottom: 0; }
    .project-item.style-two .project-over:after {
      opacity: 0.5;
      background: #1e1e22; }
    .project-item.style-two .project-content h4 {
      margin-bottom: 0; }
      @media only screen and (max-width: 375px) {
        .project-item.style-two .project-content h4 {
          font-size: 20px;
          line-height: 1.5; } }
    .project-item.style-two .project-content .category {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: #fc653c; }
      @media only screen and (min-width: 376px) {
        .project-item.style-two .project-content .category {
          font-size: 18px; } }
      .project-item.style-two .project-content .category:after, .project-item.style-two .project-content .category:before {
        content: '';
        width: 30px;
        height: 2px;
        background: #fc653c; }
      .project-item.style-two .project-content .category:after {
        margin-left: 20px; }
      .project-item.style-two .project-content .category:before {
        margin-right: 20px; }
    .project-item.style-three {
      position: relative;
      margin-bottom: 60px; }
      .project-item.style-three .project-over:after {
        opacity: 0.5;
        background: #070c13; }
      .project-item.style-three .project-content {
        position: absolute;
        left: 50%;
        opacity: 0;
        bottom: 30px;
        min-width: 70%;
        max-width: 95%;
        -webkit-transition: 0.75s;
        -o-transition: 0.75s;
        transition: 0.75s;
        background: white;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        border-radius: 7px;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        -webkit-box-shadow: 10px 0 30px rgba(49, 128, 252, 0.15);
        box-shadow: 10px 0 30px rgba(49, 128, 252, 0.15); }
      .project-item.style-three:hover .project-content {
        opacity: 1;
        bottom: -30px;
        -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
  
  /*Project Three*/
  @media only screen and (min-width: 1400px) {
    .project-three-active {
      padding-left: 55px;
      padding-right: 55px; } }
  
  .project-three-active .project-item {
    padding-left: 15px;
    padding-right: 15px; }
    .project-three-active .project-item .project-content {
      -webkit-box-shadow: 5px 0 15px rgba(195, 195, 195, 0.35);
      box-shadow: 5px 0 15px rgba(195, 195, 195, 0.35); }
  
  /* Project Page */
  .project-page-area .section-title .sub-title:before {
    display: none; }
  
  .project-creative-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .project-creative-item:not(:last-child) {
      margin-bottom: 100px; }
    .project-creative-item .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: calc(50% - 15px); }
      @media only screen and (max-width: 991px) {
        .project-creative-item .image {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
          order: 2;
          width: 100%; } }
      .project-creative-item .image:not(:last-child) {
        margin-right: 50px; }
        @media only screen and (max-width: 991px) {
          .project-creative-item .image:not(:last-child) {
            margin-right: 0; } }
    .project-creative-item .content {
      width: 40%;
      padding-left: 60px; }
      @media only screen and (max-width: 1199px) {
        .project-creative-item .content {
          width: calc(50% - 35px); } }
      @media only screen and (max-width: 991px) {
        .project-creative-item .content {
          width: 100%;
          margin-bottom: 55px; } }
      @media only screen and (max-width: 479px) {
        .project-creative-item .content {
          padding-left: 25px; } }
      .project-creative-item .content:not(:last-child) {
        margin-left: auto;
        margin-right: 50px; }
        @media only screen and (max-width: 991px) {
          .project-creative-item .content:not(:last-child) {
            margin-right: 0; } }
      .project-creative-item .content .section-title {
        margin-bottom: 40px; }
        .project-creative-item .content .section-title .sub-title {
          font-family: "Merriweather", serif; }
          @media only screen and (min-width: 480px) {
            .project-creative-item .content .section-title .sub-title {
              font-size: 30px;
              margin-left: -30px; } }
      .project-creative-item .content hr {
        margin: 0 0 30px -55px; }
        @media only screen and (max-width: 479px) {
          .project-creative-item .content hr {
            margin-left: -20px; } }
      .project-creative-item .content p {
        max-width: 340px; }
      .project-creative-item .content .theme-btn {
        margin-top: 18px; }
  
  /*Project Tab*/
  .project-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px; }
    .project-tab li button {
      cursor: pointer;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-weight: 500;
      padding: 4px 20px;
      border-radius: 5px;
      margin: 0 5px 10px;
      color: #293043;
      background: transparent; }
      @media only screen and (max-width: 375px) {
        .project-tab li button {
          padding-left: 15px;
          padding-right: 15px; } }
      .project-tab li button.active {
        background: white;
        color: #01c9f5;
        text-decoration: underline;
        -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5);
        box-shadow: 10px 0 60px rgba(195, 195, 195, 0.5); }
  
  /* Project Details */
  .project-details-area .section-title h2 {
    margin-bottom: 10px; }
  
  @media only screen and (min-width: 376px) {
    .project-details-area h5 {
      font-size: 22px; } }
  
  .sub-title-two {
    display: inline-block; }
    @media only screen and (min-width: 376px) {
      .sub-title-two {
        font-size: 18px; } }
  
  /* Next Prev Project */
  .next-prev-project {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media only screen and (max-width: 991px) {
      .next-prev-project {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; } }
    .next-prev-project .prev-project,
    .next-prev-project .next-project {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px; }
      @media only screen and (max-width: 479px) {
        .next-prev-project .prev-project,
        .next-prev-project .next-project {
          margin-left: -10px;
          margin-right: -10px; } }
      .next-prev-project .prev-project .image,
      .next-prev-project .prev-project .content,
      .next-prev-project .next-project .image,
      .next-prev-project .next-project .content {
        padding-left: 15px;
        padding-right: 15px; }
        @media only screen and (max-width: 479px) {
          .next-prev-project .prev-project .image,
          .next-prev-project .prev-project .content,
          .next-prev-project .next-project .image,
          .next-prev-project .next-project .content {
            padding-left: 10px;
            padding-right: 10px; } }
      .next-prev-project .prev-project h5,
      .next-prev-project .next-project h5 {
        margin-bottom: 0; }
      @media only screen and (max-width: 375px) {
        .next-prev-project .prev-project .image,
        .next-prev-project .next-project .image {
          max-width: 45%; } }
      .next-prev-project .prev-project .category,
      .next-prev-project .next-project .category {
        display: block;
        margin-bottom: 8px; }
    .next-prev-project .show-all {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 30px;
      margin-top: 35px;
      margin-bottom: 35px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .next-prev-project .show-all i {
        margin: -10px 0; }
    .next-prev-project .read-more {
      color: #0066ff; }
  
  /*******************************************************/
  /******************** 15. Team Area ********************/
  /*******************************************************/
  .team-member {
    padding: 35px;
    position: relative;
    text-align: center;
    margin-bottom: 30px; }
    .team-member img {
      border-radius: 50%;
      margin-bottom: 25px; }
    .team-member h5 {
      margin-bottom: 3px; }
      @media only screen and (min-width: 376px) {
        .team-member h5 {
          font-size: 20px; } }
    .team-member .designation {
      display: block;
      font-weight: 500;
      margin-bottom: 10px;
      color: #0066ff; }
    .team-member .social-style-one {
      opacity: 0;
      -webkit-transition: 0.25s;
      -o-transition: 0.25s;
      transition: 0.25s;
      visibility: hidden; }
    .team-member:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 7px;
      width: 100%;
      height: calc(100% - 65px);
      border: 1px solid rgba(0, 102, 255, 0.1); }
    .team-member:hover .social-style-one {
      opacity: 1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      visibility: visible;
      -webkit-transition-delay: 0.5s;
      -o-transition-delay: 0.5s;
      transition-delay: 0.5s; }
    .team-member:hover:after {
      height: 100%;
      border-color: white; }
  
  /* Team Slider */
  .team-slider-active {
    margin-left: -15px;
    margin-right: -15px; }
    .team-slider-active .slick-slide {
      padding-left: 15px;
      padding-right: 15px; }
  
  /* Team Filter */
  .team-active .team-member {
    margin-bottom: 0; }
  
  /* Team Profile */
  .team-profile-content .section-title h2 {
    margin-bottom: 5px; }
  
  .team-profile-content .section-title .designation {
    font-size: 20px;
    font-weight: 500;
    color: #0066ff; }
  
  .experience-item {
    padding-bottom: 10px; }
    @media only screen and (min-width: 376px) {
      .experience-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .experience-item .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 88px;
      margin: 5px 25px 15px 0; }
    .experience-item .content h5 {
      margin-bottom: 0; }
    .experience-item .content .duration {
      display: block;
      margin-bottom: 10px; }
  
  /*******************************************************/
  /****************** 16. Pricing Area *******************/
  /*******************************************************/
  .pricing-area .wave-shapes .shape.one {
    bottom: 20%; }
  
  .pricing-area .wave-shapes .shape.two {
    bottom: 10%; }
  
  .pricing-plan-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    background: white;
    position: relative;
    -webkit-box-shadow: 10px 0 60px rgba(170, 170, 170, 0.2);
    box-shadow: 10px 0 60px rgba(170, 170, 170, 0.2); }
    .pricing-plan-item:not(:last-child) {
      margin-bottom: 30px; }
    .pricing-plan-item .price-features {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      padding: 40px 50px 35px; }
      @media only screen and (min-width: 992px) {
        .pricing-plan-item .price-features {
          width: 65%; } }
      @media only screen and (max-width: 991px) {
        .pricing-plan-item .price-features {
          position: relative;
          border-bottom: 1px solid rgba(0, 102, 255, 0.1); } }
      @media only screen and (max-width: 375px) {
        .pricing-plan-item .price-features {
          padding-left: 25px;
          padding-right: 25px; } }
      .pricing-plan-item .price-features h4 {
        margin-bottom: 20px; }
      .pricing-plan-item .price-features .list-style-two {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        .pricing-plan-item .price-features .list-style-two li {
          width: 50%; }
          @media only screen and (min-width: 1200px) {
            .pricing-plan-item .price-features .list-style-two li {
              font-size: 18px; } }
          @media only screen and (max-width: 767px) {
            .pricing-plan-item .price-features .list-style-two li {
              width: 100%; } }
      .pricing-plan-item .price-features .badge {
        position: absolute;
        top: 0;
        left: 65%;
        color: white;
        font-size: 18px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        font-weight: 700;
        border-radius: 0;
        padding: 40px 16px 30px;
        text-transform: uppercase;
        background: #ff8a00;
        font-family: "Merriweather", serif;
        -webkit-writing-mode: vertical-rl;
        -webkit-transform: translate(-50%, -100%) rotate(180deg);
        -ms-transform: translate(-50%, -100%) rotate(180deg);
        transform: translate(-50%, -100%) rotate(180deg);
        -webkit-clip-path: polygon(50% 15px, 100% 0, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(50% 15px, 100% 0, 100% 100%, 0 100%, 0 0); }
        @media only screen and (max-width: 991px) {
          .pricing-plan-item .price-features .badge {
            left: 100%;
            -webkit-transform: translate(-100%, -100%) rotate(180deg);
            -ms-transform: translate(-100%, -100%) rotate(180deg);
            transform: translate(-100%, -100%) rotate(180deg); } }
        @media only screen and (max-width: 375px) {
          .pricing-plan-item .price-features .badge {
            font-size: 14px;
            padding: 30px 8px 20px; } }
  
  .price-and-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 45px 50px 50px; }
    @media only screen and (min-width: 992px) {
      .price-and-btn {
        width: 35%;
        border-left: 1px solid rgba(0, 102, 255, 0.1); } }
    @media only screen and (max-width: 375px) {
      .price-and-btn {
        padding-left: 25px;
        padding-right: 25px; } }
    .price-and-btn .price {
      font-size: 40px;
      font-weight: 700;
      color: #01c9f5;
      font-family: "Merriweather", serif; }
      .price-and-btn .price:before {
        content: '$'; }
      .price-and-btn .price:after {
        color: white;
        font-size: 18px;
        content: '/monthly'; }
    .price-and-btn .save {
      display: block;
      font-size: 18px;
      margin-bottom: 20px; }
      .price-and-btn .save span {
        color: #0066ff; }
    .price-and-btn .theme-btn.style-three {
      border-color: #0066ff; }
      .price-and-btn .theme-btn.style-three:after {
        background: #0066ff; }
  
  .pricing-plan-item:hover .badge {
    -webkit-transform: translate(-50%, 0) rotate(180deg);
    -ms-transform: translate(-50%, 0) rotate(180deg);
    transform: translate(-50%, 0) rotate(180deg); }
    @media only screen and (max-width: 991px) {
      .pricing-plan-item:hover .badge {
        -webkit-transform: translate(-100%, 0) rotate(180deg);
        -ms-transform: translate(-100%, 0) rotate(180deg);
        transform: translate(-100%, 0) rotate(180deg); } }
  
  .pricing-plan-item:hover .theme-btn {
    color: white; }
    .pricing-plan-item:hover .theme-btn:after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
  
  .text-white .pricing-plan-item {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #0b1630; }
    .text-white .pricing-plan-item .list-style-two li {
      opacity: 0.7; }
  
  .text-white .price-and-btn .theme-btn.style-three {
    color: white; }
  
  .text-white .price-and-btn .save span {
    color: #ff8a00; }
  
  /* Pricing Plan Two */
  .pricing-item-two {
    overflow: hidden;
    background: white;
    position: relative;
    border-radius: 7px;
    margin-bottom: 30px;
    padding: 30px 40px 40px;
    -webkit-box-shadow: 10px 0 60px rgba(162, 162, 162, 0.3);
    box-shadow: 10px 0 60px rgba(162, 162, 162, 0.3); }
    @media only screen and (max-width: 375px) {
      .pricing-item-two {
        padding-left: 25px;
        padding-right: 25px; } }
    .pricing-item-two .title {
      font-size: 22px;
      text-align: center;
      margin-bottom: 25px;
      padding-bottom: 20px;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(0, 102, 255, 0.1);
      letter-spacing: 6px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .pricing-item-two .title {
          text-align: left; } }
      @media only screen and (max-width: 767px) {
        .pricing-item-two .title {
          text-align: left; } }
      @media only screen and (max-width: 479px) {
        .pricing-item-two .title {
          font-size: 20px;
          letter-spacing: 2px; } }
      @media only screen and (max-width: 375px) {
        .pricing-item-two .title {
          font-size: 16px; } }
    .pricing-item-two .badge {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      width: 250px;
      font-size: 16px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 0;
      text-align: center;
      text-transform: uppercase;
      background: #fc653c;
      padding: 15px 20px 12px 40px;
      -webkit-transform: rotate(30deg) translate(45px, -100px);
      -ms-transform: rotate(30deg) translate(45px, -100px);
      transform: rotate(30deg) translate(45px, -100px); }
      @media only screen and (max-width: 375px) {
        .pricing-item-two .badge {
          font-size: 12px; } }
    .pricing-item-two .pricing-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .pricing-item-two .pricing-inner {
          display: block; } }
      @media only screen and (max-width: 767px) {
        .pricing-item-two .pricing-inner {
          display: block; } }
      @media only screen and (min-width: 376px) {
        .pricing-item-two .pricing-inner .list-style-two {
          font-size: 20px; } }
      .pricing-item-two .pricing-inner .price-and-btn {
        padding: 0;
        width: auto;
        border-left: none; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .pricing-item-two .pricing-inner .price-and-btn {
            margin-top: 25px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start; } }
        @media only screen and (max-width: 767px) {
          .pricing-item-two .pricing-inner .price-and-btn {
            margin-top: 25px;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start; } }
        .pricing-item-two .pricing-inner .price-and-btn .price {
          font-weight: 600;
          color: #fc653c; }
          .pricing-item-two .pricing-inner .price-and-btn .price:after {
            font-weight: 400;
            color: #696e7b; }
        .pricing-item-two .pricing-inner .price-and-btn .save {
          font-weight: 600;
          color: #293043; }
          .pricing-item-two .pricing-inner .price-and-btn .save span {
            color: #293043; }
        .pricing-item-two .pricing-inner .price-and-btn .theme-btn.style-three {
          border-color: #fc653c; }
          .pricing-item-two .pricing-inner .price-and-btn .theme-btn.style-three:not(:hover) {
            color: #fc653c; }
          .pricing-item-two .pricing-inner .price-and-btn .theme-btn.style-three:after {
            background: #fc653c; }
    .pricing-item-two:hover .badge {
      opacity: 1;
      -webkit-transform: rotate(30deg) translate(45px, 0);
      -ms-transform: rotate(30deg) translate(45px, 0);
      transform: rotate(30deg) translate(45px, 0); }
      @media only screen and (max-width: 375px) {
        .pricing-item-two:hover .badge {
          -webkit-transform: rotate(50deg) translate(66px, -44px);
          -ms-transform: rotate(50deg) translate(66px, -44px);
          transform: rotate(50deg) translate(66px, -44px); } }
  
  .pricing-shapes .shape {
    z-index: -1;
    position: absolute; }
    .pricing-shapes .shape.dots {
      top: 130px;
      left: 50%;
      max-width: 100%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%); }
    .pricing-shapes .shape.wave {
      left: 0;
      bottom: 0;
      max-width: 50%; }
  
  /* Pricing plan three */
  @media only screen and (min-width: 1200px) {
    .pricing-area-three .pricing-plan-three {
      margin-left: 15px; } }
  
  .pricing-plan-three {
    position: relative;
    margin-bottom: 30px;
    padding: 40px 45px;
    background: white;
    overflow: hidden;
    -webkit-box-shadow: 10px 0 60px rgba(170, 170, 170, 0.2);
    box-shadow: 10px 0 60px rgba(170, 170, 170, 0.2); }
    @media only screen and (max-width: 479px) {
      .pricing-plan-three {
        padding-left: 25px;
        padding-right: 25px; } }
    .pricing-plan-three .badge {
      position: absolute;
      top: 0;
      right: 0;
      color: white;
      font-size: 18px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-weight: 700;
      border-radius: 0;
      overflow: hidden;
      text-transform: uppercase;
      background: #ff8a00;
      font-family: "Merriweather", serif;
      -webkit-transform: translate(100%);
      -ms-transform: translate(100%);
      transform: translate(100%);
      padding: 17px 30px 15px 40px;
      -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 15px 50%, 0 0);
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 15px 50%, 0 0); }
      @media only screen and (max-width: 375px) {
        .pricing-plan-three .badge {
          font-size: 14px;
          padding: 12px 25px 10px 35px; } }
    .pricing-plan-three h4 {
      font-size: 20px;
      margin-bottom: 25px;
      display: inline-block;
      padding: 15px 20px 5px;
      background: url("../public/images/shapes/pricing-title-bg.png") no-repeat 100%/100%; }
    .pricing-plan-three .list-style-two {
      margin-bottom: 35px; }
      @media only screen and (min-width: 376px) {
        .pricing-plan-three .list-style-two {
          font-size: 18px; } }
    .pricing-plan-three .price-and-btn {
      padding: 0;
      width: auto;
      border-left: none;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .pricing-plan-three .price-and-btn .price {
        color: #0066ff; }
        @media only screen and (max-width: 375px) {
          .pricing-plan-three .price-and-btn .price {
            font-size: 33px; } }
        .pricing-plan-three .price-and-btn .price:after {
          color: #696e7b; }
      .pricing-plan-three .price-and-btn .save {
        font-weight: 500; }
    .pricing-plan-three:hover .badge {
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
      transform: translate(0); }
    .pricing-plan-three.style-two {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s; }
      .pricing-plan-three.style-two h4,
      .pricing-plan-three.style-two .price,
      .pricing-plan-three.style-two .save span,
      .pricing-plan-three.style-two .price:after,
      .pricing-plan-three.style-two .list-style-two li:before {
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s; }
      .pricing-plan-three.style-two .price-and-btn .theme-btn.style-three:after {
        background: #52cecc; }
      .pricing-plan-three.style-two:hover {
        color: white;
        background: #0066ff;
        background-image: url("../public/images/shapes/price-plan-bg.png"); }
        .pricing-plan-three.style-two:hover h4 {
          background-image: url("../public/images/shapes/pricing-title-bg-white.png"); }
        .pricing-plan-three.style-two:hover h4,
        .pricing-plan-three.style-two:hover .price,
        .pricing-plan-three.style-two:hover .save span,
        .pricing-plan-three.style-two:hover .price:after,
        .pricing-plan-three.style-two:hover .theme-btn.style-three {
          color: white; }
        .pricing-plan-three.style-two:hover .list-style-two li:before {
          background: white; }
        .pricing-plan-three.style-two:hover .price-and-btn .theme-btn.style-three {
          border-color: #52cecc; }
          .pricing-plan-three.style-two:hover .price-and-btn .theme-btn.style-three:after {
            -webkit-transform: scaleX(1);
            -ms-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            -o-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }
  
  .yearly .price-and-btn .price:after {
    content: '/yearly'; }
  
  /* Pricing Area Five */
  .pricing-area-five .pricing-plan-three.style-two .badge {
    padding: 12px 25px 10px 35px; }
    @media only screen and (min-width: 376px) {
      .pricing-area-five .pricing-plan-three.style-two .badge {
        font-size: 16px; } }
  
  /* Pricing Tab */
  .pricing-tab {
    padding: 11px 35px;
    border-radius: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #0066ff; }
    .pricing-tab li:first-child button:after,
    .pricing-tab li:last-child button:before {
      content: '';
      width: 20px;
      height: 20px;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: white;
      border-radius: 50%; }
    .pricing-tab li:first-child button:after {
      margin-left: 10px; }
    .pricing-tab li:last-child button:before {
      margin-right: 10px; }
    .pricing-tab li:first-child button:before {
      position: absolute;
      width: 40px;
      height: 20px;
      content: '';
      top: 50%;
      right: 0;
      z-index: -1;
      background: white;
      border-radius: 10px;
      -webkit-transform: translate(50%, -50%);
      -ms-transform: translate(50%, -50%);
      transform: translate(50%, -50%); }
    .pricing-tab li button {
      padding: 0;
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #fefeff;
      font-size: 16px;
      font-weight: 700;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      background: transparent; }
      .pricing-tab li button:focus, .pricing-tab li button:hover {
        color: #fefeff; }
    .pricing-tab li:first-child button.active:after,
    .pricing-tab li:last-child button.active:before {
      background: #01c9f5; }
  
  /*******************************************************/
  /**************** 17. Testimonials Area ****************/
  /*******************************************************/
  .feedback-active {
    margin-left: -15px;
    margin-right: -15px; }
    .feedback-active .feedback-item {
      margin-top: 15px;
      -webkit-box-shadow: 5px 5px 15px rgba(218, 218, 218, 0.75);
      box-shadow: 5px 5px 15px rgba(218, 218, 218, 0.75); }
  
  .feedback-item {
    background: white;
    border-radius: 7px;
    padding: 40px 50px;
    margin-bottom: 30px;
    -webkit-box-shadow: 10px 0 60px rgba(218, 218, 218, 0.75);
    box-shadow: 10px 0 60px rgba(218, 218, 218, 0.75); }
    @media only screen and (max-width: 479px) {
      .feedback-item {
        padding-left: 25px;
        padding-right: 25px; } }
    .feedback-item .author-logo {
      margin-bottom: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .feedback-item .author-logo > i {
        color: white;
        font-size: 20px;
        margin-right: 15px;
        width: 55px;
        height: 55px;
        background: #ff8a00;
        line-height: 55px;
        border-radius: 50%;
        text-align: center; }
        @media only screen and (max-width: 375px) {
          .feedback-item .author-logo > i {
            width: 45px;
            height: 45px;
            line-height: 45px; } }
    .feedback-item h4 {
      font-size: 22px;
      margin-bottom: 16px; }
      @media only screen and (max-width: 375px) {
        .feedback-item h4 {
          font-size: 20px; } }
    .feedback-item .feedback-author {
      margin-top: 25px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      @media only screen and (min-width: 376px) {
        .feedback-item .feedback-author {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; } }
      .feedback-item .feedback-author img {
        max-width: 65px;
        margin-right: 20px;
        border-radius: 50%; }
        @media only screen and (max-width: 375px) {
          .feedback-item .feedback-author img {
            margin-bottom: 10px; } }
      .feedback-item .feedback-author .content h5 {
        margin-bottom: 0;
        color: #0066ff; }
        @media only screen and (min-width: 376px) {
          .feedback-item .feedback-author .content h5 {
            font-size: 20px; } }
  
  .feedback-shape {
    z-index: -1;
    max-width: 20%;
    position: absolute;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    -webkit-animation: semi_rotated 10s infinite;
    animation: semi_rotated 10s infinite; }
    .feedback-shape:after {
      content: '';
      width: 100%;
      height: 100%;
      z-index: -2;
      top: 20px;
      left: 20px;
      position: absolute;
      border: 1px solid rgba(0, 102, 255, 0.1);
      -webkit-animation: move_arround 10s infinite;
      animation: move_arround 10s infinite; }
    .feedback-shape.one {
      left: 60px;
      bottom: 10%; }
    .feedback-shape.two {
      right: 5%;
      top: 22%;
      -webkit-animation-delay: 5s;
      animation-delay: 5s; }
      .feedback-shape.two:after {
        -webkit-animation-delay: 5s;
        animation-delay: 5s; }
  
  /* Testimonial Two */
  .slider-arrow-btns button:first-child {
    margin-right: 5px; }
  
  /* .testimonial-two-active {
    margin-left: -110px;
    margin-right: -110px; } */
    @media only screen and (max-width: 1199px) {
      .testimonial-two-active {
        margin-left: -30px;
        margin-right: -30px; } }
    .testimonial-two-active .testimonial-item-two {
      padding-left: 30px;
      padding-right: 30px; }
      @media only screen and (min-width: 480px) {
        .testimonial-two-active .testimonial-item-two {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex !important; } }
      @media only screen and (max-width: 1199px) {
        .testimonial-two-active .testimonial-item-two {
          padding-left: 30px;
          padding-right: 30px; } }
  
  .testimonial-item-two {
    padding-top: 10px; }
    @media only screen and (min-width: 480px) {
      .testimonial-item-two {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .testimonial-item-two .testimonial-author {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 120px;
      position: relative;
      margin-right: 30px;
      margin-bottom: 20px; }
      .testimonial-item-two .testimonial-author img {
        border-radius: 50%; }
      .testimonial-item-two .testimonial-author:before {
        top: -10px;
        right: 0;
        color: white;
        content: "\201D";
        position: absolute;
        width: 45px;
        height: 45px;
        background: #fc653c;
        line-height: 65px;
        font-size: 3rem;
        border-radius: 50%;
        text-align: center;
        font-family: "Font Awesome 5 Pro"; }
    .testimonial-item-two .testimonial-content p {
      font-size: 20px; }
    .testimonial-item-two .author-description {
      padding-left: 70px;
      position: relative; }
      .testimonial-item-two .author-description .h5 {
        display: block;
        font-size: 20px;
        margin-bottom: 0;
        color: #fc653c; }
      .testimonial-item-two .author-description .designation {
        color: #293043; }
      .testimonial-item-two .author-description:before {
        position: absolute;
        content: '';
        left: 0;
        top: 20px;
        height: 3px;
        width: 40px;
        background: #fc653c; }
  
  .for-middle-border {
    position: relative; }
    .for-middle-border:after, .for-middle-border:before {
      position: absolute;
      content: '';
      z-index: -1;
      background: rgba(0, 102, 255, 0.1); }
      @media only screen and (max-width: 991px) {
        .for-middle-border:after, .for-middle-border:before {
          display: none; } }
    .for-middle-border:before {
      height: 1px;
      width: 100%;
      left: 0;
      top: 145px; }
    .for-middle-border:after {
      width: 1px;
      height: 100%;
      left: 50%;
      top: 0; }
  
  /*******************************************************/
  /**************** 18. Work Process Area ****************/
  /*******************************************************/
  .work-process-item {
    text-align: center;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px; }
    @media only screen and (max-width: 575px) {
      .work-process-item {
        margin-top: 0; } }
    .work-process-item .icon {
      z-index: 1;
      padding: 15px;
      position: relative;
      margin-bottom: 20px;
      display: inline-block; }
      .work-process-item .icon .number {
        z-index: -1;
        opacity: 0.15;
        line-height: 1;
        font-size: 85px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        font-weight: 700;
        position: absolute;
        color: transparent;
        -webkit-text-stroke: 1px;
        -webkit-text-stroke-color: #293043;
        -webkit-transform: translate(-70%, -60%) rotate(-50deg);
        -ms-transform: translate(-70%, -60%) rotate(-50deg);
        transform: translate(-70%, -60%) rotate(-50deg); }
        @media only screen and (max-width: 575px) {
          .work-process-item .icon .number {
            font-size: 65px; } }
      .work-process-item .icon i {
        color: white;
        font-size: 65px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        display: inline-block;
        width: 120px;
        height: 120px;
        background: #fc653c;
        line-height: 120px;
        border-radius: 50%;
        text-align: center; }
        @media only screen and (max-width: 575px) {
          .work-process-item .icon i {
            width: 80px;
            height: 80px;
            font-size: 35px;
            line-height: 80px; } }
        .work-process-item .icon i:before {
          line-height: inherit; }
      .work-process-item .icon:before {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: '';
        left: 0;
        top: 0;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        border-radius: 50%;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        border: 2px dashed #fc653c; }
    .work-process-item p {
      font-size: 18px;
      line-height: 22px;
    }
    .work-process-item:hover .icon i {
      background: #fc653c; }
    .work-process-item:hover .icon:before {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-animation: rotated_circle 5s linear infinite;
      animation: rotated_circle 5s linear infinite;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s; }
  
  .work-process-shape {
    position: absolute;
    width: 110%;
    top: -10%;
    left: -5%;
    z-index: -1; }
    @media only screen and (max-width: 991px) {
      .work-process-shape {
        top: 0; } }
    @media only screen and (max-width: 575px) {
      .work-process-shape {
        display: none; } }


        .work-process-item1 {
          text-align: center;
          margin-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px; }
          @media only screen and (max-width: 575px) {
            .work-process-item1 {
              margin-top: 0; } }
          .work-process-item1 .icon {
            z-index: 1;
            padding: 15px;
            position: relative;
            margin-bottom: 20px;
            display: inline-block; }
            .work-process-item1 .icon .number {
              z-index: -1;
              opacity: 0.15;
              line-height: 1;
              font-size: 85px;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              transition: 0.5s;
              font-weight: 700;
              position: absolute;
              color: transparent;
              -webkit-text-stroke: 1px;
              -webkit-text-stroke-color: #293043;
              -webkit-transform: translate(-70%, -60%) rotate(-50deg);
              -ms-transform: translate(-70%, -60%) rotate(-50deg);
              transform: translate(-70%, -60%) rotate(-50deg); }
              @media only screen and (max-width: 575px) {
                .work-process-item1 .icon .number {
                  font-size: 65px; } }
            .work-process-item1 .icon i {
              color: white;
              font-size: 65px;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              transition: 0.5s;
              display: inline-block;
              width: 120px;
              height: 120px;
              background: #0066ff;
              line-height: 120px;
              border-radius: 50%;
              text-align: center; }
              @media only screen and (max-width: 575px) {
                .work-process-item1 .icon i {
                  width: 80px;
                  height: 80px;
                  font-size: 35px;
                  line-height: 80px; } }
              .work-process-item1 .icon i:before {
                line-height: inherit; }
            .work-process-item1 .icon:before {
              position: absolute;
              z-index: -1;
              width: 100%;
              height: 100%;
              content: '';
              left: 0;
              top: 0;
              -webkit-transition: 0.5s;
              -o-transition: 0.5s;
              transition: 0.5s;
              border-radius: 50%;
              -webkit-transform: scale(0);
              -ms-transform: scale(0);
              transform: scale(0);
              border: 2px dashed #01c9f5 }
          .work-process-item1 p {
            font-size: 18px; }
          .work-process-item1:hover .icon i {
            background: #01c9f5; }
          .work-process-item1:hover .icon:before {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -webkit-animation: rotated_circle 5s linear infinite;
            animation: rotated_circle 5s linear infinite;
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; }
  
  /*******************************************************/
  /******************** 19. CTA Area *********************/
  /*******************************************************/
  .cta-inner {
    margin-top: -110px;
    z-index: 1;
    position: relative;
    -webkit-transform: translateY(110px);
    -ms-transform: translateY(110px);
    transform: translateY(110px); }
    .cta-inner::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      opacity: 0.9;
      background-color: #1e1e22; }
  
  .cta-item {
    padding: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .cta-item {
        padding-left: 40px;
        padding-right: 40px; } }
    @media only screen and (max-width: 575px) {
      .cta-item {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; } }
    .cta-item .icon {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      font-size: 55px;
      width: 100px;
      height: 100px;
      background: #fc653c;
      line-height: 100px;
      border-radius: 50%;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .cta-item .icon {
          width: 80px;
          height: 80px;
          font-size: 35px;
          line-height: 80px; } }
      .cta-item .icon i:before {
        line-height: inherit; }
    .cta-item h4 {
      color: white;
      line-height: 1.44;
      margin: 10px 30px; }
      @media only screen and (min-width: 1400px) {
        .cta-item h4 {
          font-size: 28px; } }
    .cta-item .details-btn {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      margin-left: auto;
      border: 1px solid white; }
      .cta-item .details-btn:hover {
        border-color: #fc653c; }
      @media only screen and (max-width: 575px) {
        .cta-item .details-btn {
          margin-left: 0;
          margin-top: 10px; } }
      .cta-item .details-btn:not(:hover) {
        color: white;
        background: transparent; }
  
  /*******************************************************/
  /****************** 20. Contact Area *******************/
  /*******************************************************/
  .contact-info-wrap {
    padding: 40px;
    border: 1px solid rgba(255, 255, 255, 0.1); }
    @media only screen and (max-width: 479px) {
      .contact-info-wrap {
        padding-left: 25px;
        padding-right: 25px; } }
  
  .contact-info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .contact-info-item:not(:last-child) {
      margin-bottom: 30px; }
    .contact-info-item .icon {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      font-size: 20px;
      margin-top: 5px;
      margin-right: 20px;
      width: 55px;
      height: 55px;
      background: #fc653c;
      line-height: 55px;
      border-radius: 50%;
      text-align: center; }
      @media only screen and (max-width: 375px) {
        .contact-info-item .icon {
          width: 45px;
          height: 45px;
          font-size: 16px;
          line-height: 45px;
          margin-right: 15px; } }
    .contact-info-item .title {
      font-size: 18px; }
    .contact-info-item .text {
      display: block;
      font-weight: 400; }
      @media only screen and (min-width: 376px) {
        .contact-info-item .text {
          font-size: 22px; } }
    .contact-info-item.style-two {
      padding: 35px 40px;
      background: #f0f6ff; }
      @media only screen and (max-width: 479px) {
        .contact-info-item.style-two {
          padding-left: 25px;
          padding-right: 25px; } }
      @media only screen and (max-width: 375px) {
        .contact-info-item.style-two {
          display: block; } }
      .contact-info-item.style-two .icon {
        margin-bottom: 10px;
        background: #0066ff; }
        @media only screen and (min-width: 480px) {
          .contact-info-item.style-two .icon {
            width: 60px;
            height: 60px;
            font-size: 24px;
            line-height: 60px;
            margin-right: 25px; } }
      .contact-info-item.style-two .content {
        color: #293043; }
        .contact-info-item.style-two .content .title {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 3px;
          text-transform: uppercase; }
        @media only screen and (min-width: 376px) {
          .contact-info-item.style-two .content .text {
            font-size: 20px;
            line-height: 1.4; } }
        .contact-info-item.style-two .content .text a:not(:hover) {
          color: #293043; }
      .contact-info-item.style-two:nth-child(2) {
        background: #fef9ed; }
        .contact-info-item.style-two:nth-child(2) .icon {
          background: #ff8a00; }
      .contact-info-item.style-two:nth-child(3) {
        background: #effafe; }
        .contact-info-item.style-two:nth-child(3) .icon {
          background: #01c9f5; }
  
  .contact-area-form {
    background: white;
    border-radius: 12px;
    padding: 40px 60px 50px; }
    @media only screen and (max-width: 479px) {
      .contact-area-form {
        padding-left: 25px;
        padding-right: 25px; } }
    .contact-area-form h4 {
      margin-bottom: 25px; }
    .contact-area-form .form-control {
      font-size: 18px;
      margin-bottom: 15px; }
      .contact-area-form .form-control::-webkit-input-placeholder {
        font-weight: 600;
        color: #293043; }
      .contact-area-form .form-control:-ms-input-placeholder {
        font-weight: 600;
        color: #293043; }
      .contact-area-form .form-control::-ms-input-placeholder {
        font-weight: 600;
        color: #293043; }
      .contact-area-form .form-control::placeholder {
        font-weight: 600;
        color: #293043; }
    .contact-area-form .theme-btn {
      min-width: 70%; }
  
  .contact-shapes .shape {
    z-index: -1;
    position: absolute; }
  
  .contact-shapes .circle {
    top: 10%;
    right: 10%;
    max-width: 10%;
    -webkit-animation: rotated_circle 40s infinite;
    animation: rotated_circle 40s infinite; }
  
  .contact-shapes .dots {
    left: 15%;
    bottom: 5%;
    max-width: 10%;
    -webkit-animation: down-up-two 30s infinite;
    animation: down-up-two 30s infinite; }
  
  .contact-shapes .wave-line {
    max-width: 30%;
    right: 0;
    bottom: 0;
    -webkit-animation: down-up-one 10s infinite;
    animation: down-up-one 10s infinite; }
  
  /* Contact Page */
  .contact-content .form-group {
    margin-bottom: 22px; }
    .contact-content .form-group label {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
      color: #293043;
      font-family: "Merriweather", serif; }
    .contact-content .form-group .form-control {
      padding-top: 17px;
      padding-bottom: 17px; }
    .contact-content .form-group .nice-select {
      font-size: 16px;
      line-height: 1.75; }
      .contact-content .form-group .nice-select .current {
        font-weight: 400;
        color: #696e7b; }
  
  .has-error .help-block.with-errors {
    margin-top: 5px;
    color: #fc653c; }
  
  #msgSubmit.h4 {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 10px; }
  
  .follow-us {
    padding-top: 22px;
    text-align: center;
    padding-bottom: 35px;
    border: 1px solid rgba(0, 102, 255, 0.1); }
    .follow-us h4 {
      font-size: 22px;
      margin-bottom: 22px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 102, 255, 0.1); }
  
  /* Our Location */
  .our-location-address-area {
    margin-top: -250px;
    -webkit-transform: translateY(250px);
    -ms-transform: translateY(250px);
    transform: translateY(250px); }
    @media only screen and (min-width: 1200px) {
      .our-location-address-area .container {
        max-width: 1140px; } }
  
  .location-address-item {
    background: white;
    border-radius: 7px;
    margin-bottom: 30px;
    -webkit-box-shadow: 10px 0 60px rgba(64, 64, 64, 0.1);
    box-shadow: 10px 0 60px rgba(64, 64, 64, 0.1); }
    .location-address-item .top-part {
      padding-top: 40px;
      text-align: center;
      margin-left: 25px;
      margin-right: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 102, 255, 0.1); }
      .location-address-item .top-part h5 {
        font-size: 20px;
        margin-top: 35px; }
    .location-address-item .bottom-part {
      padding: 25px 45px 35px; }
      @media only screen and (max-width: 375px) {
        .location-address-item .bottom-part {
          padding-left: 35px;
          padding-right: 35px; } }
      .location-address-item .bottom-part li:not(:last-child) {
        margin-bottom: 10px; }
      .location-address-item .bottom-part li i {
        font-size: 18px;
        margin-right: 10px;
        color: #0066ff; }
  
  /* Google Map */
  .our-location {
    margin-bottom: -15px; }
    .our-location iframe {
      height: 750px; }
  
  /*******************************************************/
  /******************** 21. Blog Area ********************/
  /*******************************************************/
  .blog-item {
    margin-bottom: 55px; }
    @media only screen and (min-width: 576px) {
      .blog-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; } }
    .blog-item .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 35%;
      margin-right: 40px; }
      @media only screen and (max-width: 575px) {
        .blog-item .image {
          max-width: none;
          margin-right: 0;
          margin-bottom: 15px; }
          .blog-item .image img {
            width: 100%; } }
    .blog-item .content {
      font-size: 18px;
      line-height: 1.4; }
      .blog-item .content .date {
        display: flex;
        color: #fc653c;
        margin-bottom: 10px; }
        .blog-item .content .date i {
          margin-right: 8px; }
      .blog-item .content h4 {
        line-height: 1.4;
        margin-bottom: 18px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .blog-item .content h4 {
            font-size: 20px; } }
        @media only screen and (max-width: 375px) {
          .blog-item .content h4 {
            font-size: 20px; } }
      .blog-item .content .author {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .blog-item .content .author img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px; }
        .blog-item .content .author span {
          display: block; }
        .blog-item .content .author a {
          font-weight: 500; }
          .blog-item .content .author a:not(:hover) {
            color: #293043; }
  
  /* Blog Grid */
  .blog-grid-item {
    margin-bottom: 60px; }
    .blog-grid-item .image img {
      width: 100%; }
  
  .blog-content {
    background: white;
    padding: 30px 35px 35px;
    -webkit-box-shadow: 10px 0 60px rgba(223, 223, 223, 0.5);
    box-shadow: 10px 0 60px rgba(223, 223, 223, 0.5); }
    @media only screen and (max-width: 479px) {
      .blog-content {
        padding-left: 25px;
        padding-right: 25px; } }
    .blog-content h5 {
      line-height: 1.75;
      margin-bottom: 10px;
      letter-spacing: -1px; }
      @media only screen and (min-width: 376px) {
        .blog-content h5 {
          font-size: 20px; } }
    .blog-content .read-more {
      color: #0066ff;
      text-decoration: underline; }
      @media only screen and (min-width: 376px) {
        .blog-content .read-more {
          font-size: 18px; } }
  
  .blog-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .blog-meta li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      line-height: 1.3;
      margin-bottom: 8px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .blog-meta li img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        border-radius: 50%; }
      .blog-meta li i {
        margin-right: 6px; }
      .blog-meta li:not(:last-child) {
        margin-right: 22px; }
      .blog-meta li:not(:first-child) {
        padding-left: 22px;
        border-left: 2px solid #0066ff; }
  
  /* Blog List */
  .blog-list-item {
    margin-bottom: 30px; }
    @media only screen and (min-width: 1200px) {
      .blog-list-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .blog-list-item .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 44%;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
      @media only screen and (max-width: 1199px) {
        .blog-list-item .image {
          max-width: 100%; } }
      .blog-list-item .image img {
        width: 100%; }
    .blog-list-item .blog-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 40px 60px 30px; }
      @media only screen and (max-width: 767px) {
        .blog-list-item .blog-content {
          padding-left: 35px;
          padding-right: 35px; } }
      @media only screen and (max-width: 479px) {
        .blog-list-item .blog-content {
          padding-left: 25px;
          padding-right: 25px; } }
      .blog-list-item .blog-content h4 {
        line-height: 1.52;
        margin-bottom: 15px;
        letter-spacing: -1px; }
        @media only screen and (min-width: 576px) {
          .blog-list-item .blog-content h4 {
            font-size: 27px; } }
        @media only screen and (max-width: 479px) {
          .blog-list-item .blog-content h4 {
            font-size: 20px;
            line-height: 1.65; } }
        @media only screen and (max-width: 375px) {
          .blog-list-item .blog-content h4 {
            font-size: 16px; } }
  
  /* Blog Details */
  .blog-details-content {
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(41, 48, 67, 0.1); }
    .blog-details-content .blog-meta {
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(41, 48, 67, 0.1); }
  
  blockquote {
    position: relative;
    padding: 30px 70px 20px 160px; }
    @media only screen and (max-width: 767px) {
      blockquote {
        padding-right: 0;
        padding-left: 75px; } }
    @media only screen and (max-width: 375px) {
      blockquote {
        padding-left: 55px; } }
    blockquote:before {
      line-height: 1;
      content: "\f125";
      font-size: 50px;
      font-family: "Flaticon";
      color: #01c9f5;
      position: absolute;
      margin-left: -75px; }
      @media only screen and (max-width: 375px) {
        blockquote:before {
          font-size: 40px;
          margin-left: -55px; } }
    @media only screen and (max-width: 479px) {
      blockquote h4 {
        font-size: 20px; } }
    @media only screen and (max-width: 375px) {
      blockquote h4 {
        font-size: 18px; } }
    blockquote .blockquote-footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 5px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      @media only screen and (min-width: 376px) {
        blockquote .blockquote-footer {
          font-size: 18px; } }
      blockquote .blockquote-footer:before {
        content: '';
        width: 40px;
        height: 3px;
        margin-right: 20px;
        background: #ff8a00; }
  
  .tag-share {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .tag-share .item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 25px; }
      .tag-share .item h5 {
        margin: 0 20px 0 0; }
      .tag-share .item .tag-coulds a {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 5px;
        text-transform: capitalize; }
  
  /* Comments */
  h4.comment-title {
    margin-bottom: 0;
    border-bottom: none; }
  
  .comment-body {
    padding-top: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(41, 48, 67, 0.1); }
    .comment-body:first-child {
      border-top: 1px solid rgba(41, 48, 67, 0.1); }
    @media only screen and (min-width: 480px) {
      .comment-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    .comment-body .author-thumb {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 85px;
      margin-right: 30px;
      margin-bottom: 15px; }
      .comment-body .author-thumb img {
        border-radius: 50%; }
    .comment-body .content h5 {
      margin-bottom: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .comment-body .content h5 .theme-btn {
        font-size: 16px;
        padding: 6px 20px;
        font-family: "Merriweather", serif; }
    .comment-body .content .date {
      display: block;
      margin: -2px 0 8px; }
  
  .admin-comment .comment-body {
    border: none;
    background: white;
    padding: 35px 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 10px 0 60px rgba(219, 219, 219, 0.4);
    box-shadow: 10px 0 60px rgba(219, 219, 219, 0.4); }
    @media only screen and (max-width: 575px) {
      .admin-comment .comment-body {
        display: block;
        padding-left: 25px;
        padding-right: 25px; } }
    .admin-comment .comment-body .author-thumb {
      max-width: 140px; }
      @media only screen and (min-width: 576px) {
        .admin-comment .comment-body .author-thumb {
          margin-bottom: 0; } }
  
  /* Next Prev Post */
  .next-prev-post {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .next-prev-post .post-item {
      max-width: 250px;
      margin-bottom: 20px; }
      .next-prev-post .post-item h5 {
        margin-bottom: 5px; }
        .next-prev-post .post-item h5 a {
          color: #293043; }
          .next-prev-post .post-item h5 a:hover {
            text-decoration: underline; }
      .next-prev-post .post-item .date i {
        margin-right: 5px; }
  
  /* Comment Form */
  .comment-form {
    padding: 50px 50px 60px; }
    @media only screen and (max-width: 375px) {
      .comment-form {
        padding-left: 25px;
        padding-right: 25px; } }
    .comment-form h4 {
      margin-bottom: 0; }
    .comment-form .form-group label {
      position: absolute;
      top: 0;
      right: 0; }
    .comment-form .form-control {
      font-size: 18px;
      border-radius: 0;
      padding: 15px 0 12px;
      border-width: 0 0 1px;
      background-color: transparent;
      border-color: rgba(41, 48, 67, 0.1); }
      .comment-form .form-control::-webkit-input-placeholder {
        color: #293043; }
      .comment-form .form-control:-ms-input-placeholder {
        color: #293043; }
      .comment-form .form-control::-ms-input-placeholder {
        color: #293043; }
      .comment-form .form-control::placeholder {
        color: #293043; }
    .comment-form .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 30px; }
      .comment-form .form-check .form-check-input[type=radio] {
        width: 24px;
        height: 24px;
        padding: 0;
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        cursor: pointer;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: relative;
        margin-right: 20px;
        border-radius: 5px;
        background: transparent;
        border: 2px solid rgba(32, 78, 207, 0.2); }
        .comment-form .form-check .form-check-input[type=radio]:after {
          content: '';
          width: 7px;
          height: 7px;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: rgba(0, 102, 255, 0.1); }
        .comment-form .form-check .form-check-input[type=radio]:checked:after {
          background: #0066ff; }
      .comment-form .form-check label {
        font-weight: 400;
        position: relative; }
  
  /*******************************************************/
  /****************** 22. Video Section ******************/
  /*******************************************************/
  .video-part {
    z-index: 1;
    position: relative; }
    .video-part::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      opacity: 0.1;
      background-color: #070c13; }
    @media only screen and (min-width: 992px) {
      .video-part {
        margin-bottom: -80px; } }
    .video-part:before {
      z-index: 1; }
    .video-part .video-play {
      z-index: 2;
      width: 70px;
      height: 70px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      font-size: 15px;
      line-height: 70px;
      background: #01c9f5; }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .video-content h2 {
      font-size: 35px; } }
  
  .video-content .list-style-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .video-content .list-style-three li {
      width: 50%; }
      @media only screen and (max-width: 575px) {
        .video-content .list-style-three li {
          width: 100%; } }
  
  /*******************************************************/
  /******************* 23. FAQs Area *********************/
  /*******************************************************/
  .faq-images img:not(:last-child) {
    margin-bottom: 30px; }
  
  .faq-images .image-one {
    margin-left: 30px;
    width: calc(100% - 30px);
    max-width: calc(100% - 30px); }
  
  .faq-images .image-two {
    margin-left: -30px;
    width: calc(100% + 30px);
    max-width: calc(100% + 30px); }
  
  @media only screen and (max-width: 575px) {
    .faq-images .image-three {
      margin-top: 50px; } }
  
  .faq-images .year-of-experience {
    position: relative;
    width: auto;
    height: auto;
    top: 0;
    border-radius: 0;
    text-align: left;
    padding: 20px 25px;
    margin-right: 30px;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); }
    @media only screen and (max-width: 479px) {
      .faq-images .year-of-experience {
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px; } }
    .faq-images .year-of-experience .number {
      font-size: 45px; }
      .faq-images .year-of-experience .number:after {
        content: '+'; }
  
  .accordion-item {
    z-index: 1;
    border: none;
    padding: 16px 30px;
    position: relative;
    border-radius: 0 !important; }
    @media only screen and (max-width: 375px) {
      .accordion-item {
        padding-left: 20px;
        padding-right: 20px; } }
    .accordion-item:not(:last-child) {
      margin-bottom: 20px; }
    .accordion-item .accordion-button {
      padding: 0;
      position: inherit;
      background: transparent; }
      @media only screen and (min-width: 376px) {
        .accordion-item .accordion-button {
          font-size: 18px; } }
      .accordion-item .accordion-button:focus {
        -webkit-box-shadow: none;
        box-shadow: none; }
      .accordion-item .accordion-button:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        background: #f7f9fd; }
      .accordion-item .accordion-button:not(.collapsed) {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #293043; }
        .accordion-item .accordion-button:not(.collapsed):before {
          background: white;
          -webkit-box-shadow: 10px 0 40px rgba(204, 204, 204, 0.3);
          box-shadow: 10px 0 40px rgba(204, 204, 204, 0.3); }
    .accordion-item .accordion-body {
      padding: 0; }
      .accordion-item .accordion-body p {
        margin: 10px 0 0; }
  
  .style-two .accordion-button:before {
    background: white;
    -webkit-box-shadow: 10px 0 40px rgba(204, 204, 204, 0.3);
    box-shadow: 10px 0 40px rgba(204, 204, 204, 0.3); }
  
  /* FAQ Page */
  .faq-search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    background: white;
    border-radius: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 10px 0 30px rgba(153, 153, 153, 0.2);
    box-shadow: 10px 0 30px rgba(153, 153, 153, 0.2); }
    .faq-search-form label {
      margin: 0 20px; }
    .faq-search-form input {
      padding: 0;
      border: none; }
    .faq-search-form button {
      width: 45px;
      height: 45px;
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      color: white;
      border-radius: 5px;
      background: #0066ff; }
  
  /*******************************************************/
  /******************** 24. Shop Area ********************/
  /*******************************************************/
  .product-item {
    margin-bottom: 50px; }
    .product-item .image img {
      width: 100%; }
    .product-item .content {
      padding: 35px 40px 40px;
      -webkit-box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3);
      box-shadow: 10px 0 60px rgba(195, 195, 195, 0.3); }
      @media only screen and (max-width: 479px) {
        .product-item .content {
          padding-left: 25px;
          padding-right: 25px; } }
      .product-item .content h5 {
        margin-bottom: 4px; }
        @media only screen and (min-width: 376px) {
          .product-item .content h5 {
            font-size: 20px; } }
      .product-item .content .admin-ratting {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .product-item .content .admin-ratting a {
          color: #0066ff;
          text-decoration: underline; }
      .product-item .content .price-details-btn .price {
        font-size: 18px;
        margin-right: 20px; }
        .product-item .content .price-details-btn .price:before {
          content: '$'; }
      .product-item .content .price-details-btn .theme-btn {
        padding: 2px 15px; }
  
  /* Product Details */
  .preview-images {
    margin-bottom: 30px; }
    .preview-images img {
      width: 100%; }
  
  .thumb-images {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .thumb-images .thumb-item {
      width: calc(33.3333% - 20px); }
      .thumb-images .thumb-item img {
        width: 100%; }
  
  .product-details-content .section-title h2 {
    margin-bottom: 15px; }
  
  .product-details-content .ratting-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .product-details-content .ratting-price .price {
      font-size: 24px;
      font-weight: 500;
      color: #293043;
      margin-left: 10px; }
      .product-details-content .ratting-price .price:before {
        content: '$'; }
  
  .product-details-content .category-tags li:not(:last-child) {
    margin-bottom: 8px; }
  
  .product-details-content .category-tags li b {
    font-size: 18px;
    color: #293043; }
  
  .product-details-content .category-tags li span {
    margin-left: 20px;
    margin-right: 20px; }
  
  .product-details-content .category-tags li a:not(:last-child):after {
    content: ',';
    margin-right: 3px; }
  
  .product-details-content .add-to-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .product-details-content .add-to-cart input {
      width: 88px;
      padding: 8px 20px;
      margin: 10px 10px 0 0; }
    .product-details-content .add-to-cart .theme-btn {
      padding-top: 9px;
      padding-bottom: 9px;
      margin: 10px 10px 0 0; }
    .product-details-content .add-to-cart .wishlist {
      margin-top: 10px;
      padding: 8px 20px;
      border-radius: 5px;
      background: transparent;
      border: 1px solid rgba(0, 102, 255, 0.1); }
  
  .product-information-tab li:not(:last-child) {
    margin-right: 15px; }
  
  .product-information-tab li a {
    font-size: 18px;
    font-weight: 700;
    padding: 11px 65px;
    border-radius: 5px;
    display: inline-block;
    color: #293043;
    border: 1px solid rgba(0, 102, 255, 0.1); }
    @media only screen and (max-width: 575px) {
      .product-information-tab li a {
        padding: 6px 30px; } }
    @media only screen and (max-width: 375px) {
      .product-information-tab li a {
        font-size: 16px;
        padding: 5px 20px; } }
    .product-information-tab li a.active {
      color: white;
      background: #0066ff; }
  
  .review-form .ratting b {
    font-size: 20px;
    font-weight: 400;
    margin-right: 20px; }
  
  .review-form .form-group {
    margin-bottom: 30px; }
    .review-form .form-group .nice-select,
    .review-form .form-group .form-control {
      line-height: 1.4;
      background: #f7f9fd;
      border-color: #f7f9fd; }
      @media only screen and (min-width: 480px) {
        .review-form .form-group .nice-select,
        .review-form .form-group .form-control {
          font-size: 20px; } }
      .review-form .form-group .nice-select::-webkit-input-placeholder,
      .review-form .form-group .form-control::-webkit-input-placeholder {
        color: #293043; }
      .review-form .form-group .nice-select:-ms-input-placeholder,
      .review-form .form-group .form-control:-ms-input-placeholder {
        color: #293043; }
      .review-form .form-group .nice-select::-ms-input-placeholder,
      .review-form .form-group .form-control::-ms-input-placeholder {
        color: #293043; }
      .review-form .form-group .nice-select::placeholder,
      .review-form .form-group .form-control::placeholder {
        color: #293043; }
      .review-form .form-group .nice-select:focus,
      .review-form .form-group .form-control:focus {
        background: transparent;
        border-color: #293043; }
      .review-form .form-group .nice-select .current,
      .review-form .form-group .form-control .current {
        font-weight: 400; }
  
  @media only screen and (min-width: 768px) {
    .review-form .theme-btn {
      padding: 14px 55px; } }
  
  /* Cart Page */
  .shoping-table table,
  .shoping-cart-total table {
    width: 100%; }
  
  .shoping-table td, .shoping-table th,
  .shoping-cart-total td,
  .shoping-cart-total th {
    padding: 15px 10px;
    text-align: center;
    border: 1px solid rgba(0, 102, 255, 0.1); }
  
  .shoping-table th,
  .shoping-cart-total th {
    text-transform: capitalize; }
  
  .shoping-table td img,
  .shoping-cart-total td img {
    max-height: 60px; }
  
  .shoping-table td .title,
  .shoping-cart-total td .title {
    font-size: 18px;
    color: #293043; }
  
  .shoping-table td .price:before,
  .shoping-cart-total td .price:before {
    content: '$'; }
  
  .shoping-table td button,
  .shoping-cart-total td button {
    background: transparent; }
  
  @media only screen and (max-width: 767px) {
    .shoping-table thead {
      display: none; }
    .shoping-table tr {
      display: -ms-grid;
      display: grid; }
      .shoping-table tr:not(:last-child) {
        margin-bottom: 50px; }
    .shoping-table td:not(:last-child) {
      border-bottom: none; } }
  
  .quantity-input {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    .quantity-input button {
      width: 45px;
      background: transparent;
      border: 1px solid rgba(0, 102, 255, 0.1); }
    .quantity-input input {
      width: 55px;
      padding: 5px 15px;
      text-align: center;
      background: transparent; }
  
  .discount-wrapper input {
    width: 200px; }
  
  /* Checkout Page */
  .checkout-faqs .alert {
    padding: 20px 30px;
    margin-bottom: 25px; }
    @media only screen and (max-width: 375px) {
      .checkout-faqs .alert {
        padding-left: 20px;
        padding-right: 20px; } }
    .checkout-faqs .alert h6 {
      margin-bottom: 0; }
      .checkout-faqs .alert h6 a {
        padding: 0;
        color: #01c9f5;
        background: transparent; }
    .checkout-faqs .alert form {
      padding: 20px 0 10px; }
      .checkout-faqs .alert form .form-footer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 15px; }
        .checkout-faqs .alert form .form-footer button {
          margin-right: 15px; }
          @media only screen and (max-width: 375px) {
            .checkout-faqs .alert form .form-footer button {
              margin-right: 8px;
              padding-left: 20px;
              padding-right: 20px; } }
        .checkout-faqs .alert form .form-footer label {
          line-height: 1.4;
          margin: 0 0 0 5px; }
  
  .checkout-faqs .checkout-form h5 {
    margin-bottom: 20px; }
  
  /*******************************************************/
  /***************** 25. Sidebar Widgets *****************/
  /*******************************************************/
  .widget:not(:last-child) {
    margin-bottom: 40px; }
  
  .widget-title,
  .comment-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 35px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 102, 255, 0.1); }
    .widget-title:before,
    .comment-title:before {
      margin-right: 15px;
      content: url("../public/images/shapes/section-subtitle-line.png"); }
  
  .widget-search .widget-title {
    margin-bottom: 0;
    border-bottom: none; }
  
  .widget-search form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5px;
    padding: 13px 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid rgba(0, 102, 255, 0.1); }
    .widget-search form input {
      padding: 0;
      border: none;
      font-size: 16px;
      background: transparent; }
    .widget-search form button {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      background: transparent; }
  
  .widget-category ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .widget-category ul li:not(:last-child) {
      margin-bottom: 15px; }
    .widget-category ul li:before {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      content: "\f101";
      margin-right: 10px;
      font-family: "Font Awesome 5 Pro"; }
    .widget-category ul li a {
      margin-right: auto; }
      .widget-category ul li a:not(:hover) {
        color: #293043; }
  
  .widget-recent-news ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 102, 255, 0.1); }
    .widget-recent-news ul li:not(:last-child) {
      margin-bottom: 30px; }
    .widget-recent-news ul li .image {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      max-width: 90px;
      margin-right: 30px; }
      @media only screen and (max-width: 375px) {
        .widget-recent-news ul li .image {
          max-width: 70px;
          margin-right: 15px; } }
    .widget-recent-news ul li h5 {
      margin-bottom: 0; }
      @media only screen and (max-width: 375px) {
        .widget-recent-news ul li h5 {
          font-size: 15px; } }
  
  .widget-cta {
    z-index: 1;
    overflow: hidden;
    padding: 40px 0 0;
    position: relative;
    text-align: center;
    background: #0066ff; }
    .widget-cta h4 {
      color: white;
      margin-bottom: 20px; }
    .widget-cta .theme-btn.style-two {
      padding-top: 7px;
      padding-bottom: 7px; }
      .widget-cta .theme-btn.style-two:after {
        background: #fc653c; }
    .widget-cta .cta-bg-line {
      position: absolute;
      z-index: -1;
      width: 100%;
      left: 0;
      top: 30%;
      -webkit-animation: down-up-one 15s infinite;
      animation: down-up-one 15s infinite; }
    .widget-cta .cta-bg-dots {
      position: absolute;
      z-index: -1;
      right: 5%;
      top: 35%;
      max-width: 45%;
      -webkit-animation: down-up-two 15s infinite;
      animation: down-up-two 15s infinite; }
  .tag-coulds {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -10px -5px 0; }
    .tag-coulds a {
      font-size: 14px;
      font-weight: 500;
      padding: 2px 16px;
      margin: 10px 5px 0;
      color: #293043;
      text-transform: uppercase;
      background: #f7f9fd; }
      .tag-coulds a:hover {
        color: white;
        background: #0066ff; }
  
  /*******************************************************/
  /***************** 26. 404 Error Area ******************/
  /*******************************************************/
  .error-content {
    max-width: 475px; }
    @media only screen and (min-width: 768px) {
      .error-content h1 {
        font-size: 85px; } }
    @media only screen and (max-width: 375px) {
      .error-content h1 {
        font-size: 55px; } }
    .error-content h3 {
      font-weight: 400;
      margin-bottom: 15px;
      font-family: "Roboto", sans-serif; }
      @media only screen and (min-width: 576px) {
        .error-content h3 {
          font-size: 35px; } }
      @media only screen and (max-width: 375px) {
        .error-content h3 {
          font-size: 25px; } }
    .error-content .follow-us {
      padding: 0;
      border: none;
      text-align: left; }
      .error-content .follow-us h4 {
        margin-bottom: 15px;
        padding-bottom: 15px; }
  
  .error-page-area:after {
    content: '';
    width: 111%;
    opacity: 0.05;
    padding-top: 80%;
    border-radius: 50%;
    background: #0066ff;
    position: absolute;
    left: 20%;
    top: -50%;
    z-index: -1;
    -webkit-animation: slideInDown 2s;
    animation: slideInDown 2s; }
    @media only screen and (max-width: 991px) {
      .error-page-area:after {
        top: -100px; } }
  
  /*******************************************************/
  /******************* 27. Main Footer *******************/
  /*******************************************************/
  .footer-top-newsletter {
    -webkit-transform: translateY(-250px);
    -ms-transform: translateY(-250px);
    transform: translateY(-250px);
    background-position: bottom;
    margin-bottom: -250px;
    margin-top: 250px; }
    @media only screen and (max-width: 767px) {
      .footer-top-newsletter {
        padding-left: 45px;
        padding-right: 45px; } }
    @media only screen and (max-width: 479px) {
      .footer-top-newsletter {
        padding-left: 25px;
        padding-right: 25px; } }
    @media only screen and (max-width: 375px) {
      .footer-top-newsletter h2 {
        font-size: 26px; } }
    .footer-top-newsletter form {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 10px;
      background: white;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 7px;
      padding-left: 30px; }
      @media only screen and (max-width: 479px) {
        .footer-top-newsletter form {
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          padding-right: 30px; } }
      .footer-top-newsletter form label {
        position: absolute;
        left: 0;
        top: 50%;
        margin: 0;
        right: auto;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
        .footer-top-newsletter form label i {
          color: #696e7b; }
      .footer-top-newsletter form input {
        border: none;
        border-radius: 0;
        font-weight: 500;
        color: #696e7b;
        padding: 0 0 0 30px;
        background: transparent; }
        @media only screen and (max-width: 479px) {
          .footer-top-newsletter form input {
            padding-top: 15px;
            padding-bottom: 15px; } }
      .footer-top-newsletter form button {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none; }
        @media only screen and (max-width: 479px) {
          .footer-top-newsletter form button {
            width: 100%; } }
    .footer-top-newsletter .footer-newsletter-image {
      margin-bottom: -130px;
      max-width: 100%; }
      @media only screen and (min-width: 992px) {
        .footer-top-newsletter .footer-newsletter-image {
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          margin-left: auto; } }
      .footer-top-newsletter .footer-newsletter-image img {
        width: 100%; }
  
  .copyright-area {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
    .copyright-area .footer-menu {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-left: -13px;
      margin-right: -13px; }
      .copyright-area .footer-menu li {
        font-weight: 500;
        margin: 0 13px 5px; }
        @media only screen and (max-width: 479px) {
          .copyright-area .footer-menu li {
            font-weight: 400;
            margin-left: 10px;
            margin-right: 10px; } }
  
  /* Footer Widgets */
  .footer-widget {
    margin-bottom: 50px; }
  
  .footer-title {
    margin-bottom: 25px; }
  
  @media only screen and (min-width: 376px) {
    .widget_nav_menu .list-style-two {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }
  
  .widget_nav_menu .list-style-two li {
    margin-bottom: 8px; }
    @media only screen and (min-width: 376px) {
      .widget_nav_menu .list-style-two li:nth-child(odd) {
        width: 55%; }
      .widget_nav_menu .list-style-two li:nth-child(even) {
        width: 45%; } }
    .widget_nav_menu .list-style-two li a:hover {
      text-decoration: underline; }
    .widget_nav_menu .list-style-two li:before {
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: #afafaf; }
    .widget_nav_menu .list-style-two li:hover:before {
      background: #fc653c; }
  
  .widget_newsletter {
    padding: 35px 40px 45px;
    background: url("../public/images/footer/newslette-widget-bg.png") no-repeat center/cover; }
    @media only screen and (max-width: 375px) {
      .widget_newsletter {
        padding-left: 25px;
        padding-right: 25px; } }
    .widget_newsletter .footer-title {
      margin-bottom: 2px; }
    .widget_newsletter form input {
      border: none;
      margin-top: 10px;
      padding: 14px 30px;
      margin-bottom: 15px; }
  
  /* Footer Bottom */
  .footer-bottom {
    position: relative;
    border-top: 1px solid rgba(0, 102, 255, 0.1); }
    .footer-bottom .copyright-text {
      font-size: 18px;
      color: #293043; }
    .footer-bottom .scroll-top {
      position: absolute;
      top: -24px;
      left: calc(50% - 24px);
      width: 48px;
      height: 48px;
      background: #ff8a00;
      line-height: 48px;
      border-radius: 50%;
      text-align: center; }
  
  .footer-bottom-menu ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-left: -15px;
    margin-right: -15px; }
    .footer-bottom-menu ul li {
      margin: 0 15px 5px; }
      .footer-bottom-menu ul li a {
        color: #293043; }
        .footer-bottom-menu ul li a:hover {
          text-decoration: underline; }
  
  /* Footer Two */
  .main-footer.footer-two .widget_newsletter {
    border-radius: 7px;
    background-image: none;
    border: 1px solid rgba(0, 102, 255, 0.1); }
  
  .main-footer.footer-two .footer-bottom {
    border-top: none;
    background: #e9e9ec; }
  
  /*******************************************************/
  /****************** 28. Dark Version *******************/
  /*******************************************************/
  .dark-version .heading,
  .dark-version .social-style-one a,
  .dark-version .nav-search > button,
  .dark-version h1, .dark-version h2, .dark-version h3, .dark-version h4, .dark-version h5, .dark-version h6,
  .dark-version .text-white .count-text,
  .dark-version .h1, .dark-version .h2, .dark-version .h3, .dark-version .h4, .dark-version .h5, .dark-version .h6,
  .dark-version .main-menu .navigation > li > a,
  .dark-version h1 a, .dark-version h2 a, .dark-version h3 a, .dark-version h4 a, .dark-version h5 a, .dark-version h6 a,
  .dark-version .main-menu .navigation > .dropdown > .dropdown-btn {
    color: white; }
  .dark-version.home-two {
    color: #c2ceed;
    background: #0e1b38; }
    .dark-version.home-two .bgc-lighter {
      background: #0B1731; }
    .dark-version.home-two .read-more,
    .dark-version.home-two .count-text,
    .dark-version.home-two .team-member .designation,
    .dark-version.home-two .feedback-item .feedback-author .content h5 {
      color: #01c9f5; }
    .dark-version.home-two .feedback-item,
    .dark-version.home-two .team-member:hover:after,
    .dark-version.home-two .fixed-header .header-upper {
      background: #0e1b38; }
    .dark-version.home-two .social-style-one a:hover,
    .dark-version.home-two .menu-sidebar button .icon-bar,
    .dark-version.home-two .feedback-item .author-logo > i {
      background: #01c9f5; }
    .dark-version.home-two .feature-item .image {
      border-color: rgba(0, 102, 255, 0.1); }
    .dark-version.home-two .service-item:not(:hover) {
      background: #0B1731; }
    .dark-version.home-two .social-style-one a {
      background: transparent; }
    .dark-version.home-two .circle-shapes .shape {
      background: white; }
    .dark-version.home-two .team-member:after,
    .dark-version.home-two .social-style-one a,
    .dark-version.home-two .feedback-shape:after {
      border-color: rgba(255, 255, 255, 0.15); }
    .dark-version.home-two .feedback-item,
    .dark-version.home-two .team-member:hover:after {
      -webkit-box-shadow: 10px 0 60px rgba(1, 201, 245, 0.4);
      box-shadow: 10px 0 60px rgba(1, 201, 245, 0.4); }
    .dark-version.home-two .feedback-active .feedback-item {
      -webkit-box-shadow: 0px 0px 20px rgba(1, 201, 245, 0.4);
      box-shadow: 0px 0px 20px rgba(1, 201, 245, 0.4); }
  
  .dark-version.home-one {
    color: #adadb8;
    background: #17171a; }
    .dark-version.home-one .copyright-text,
    .dark-version.home-one .form-control::-webkit-input-placeholder,
    .dark-version.home-one .list-style-two a:not(:hover),
    .dark-version.home-one .pricing-item-two .price:after,
    .dark-version.home-one .social-style-two a:not(:hover),
    .dark-version.home-one .blog-item .content .author a:not(:hover) {
      color: #adadb8; }
    .dark-version.home-one .copyright-text,
    .dark-version.home-one .form-control:-ms-input-placeholder,
    .dark-version.home-one .list-style-two a:not(:hover),
    .dark-version.home-one .pricing-item-two .price:after,
    .dark-version.home-one .social-style-two a:not(:hover),
    .dark-version.home-one .blog-item .content .author a:not(:hover) {
      color: #adadb8; }
    .dark-version.home-one .copyright-text,
    .dark-version.home-one .form-control::-ms-input-placeholder,
    .dark-version.home-one .list-style-two a:not(:hover),
    .dark-version.home-one .pricing-item-two .price:after,
    .dark-version.home-one .social-style-two a:not(:hover),
    .dark-version.home-one .blog-item .content .author a:not(:hover) {
      color: #adadb8; }
    .dark-version.home-one .copyright-text,
    .dark-version.home-one .form-control::placeholder,
    .dark-version.home-one .list-style-two a:not(:hover),
    .dark-version.home-one .pricing-item-two .price:after,
    .dark-version.home-one .social-style-two a:not(:hover),
    .dark-version.home-one .blog-item .content .author a:not(:hover) {
      color: #adadb8; }
    .dark-version.home-one .bgc-lighter,
    .dark-version.home-one .cta-inner::before,
    .dark-version.home-one .counter-item.style-two:hover {
      background: #0d0d0e; }
    .dark-version.home-one .slick-arrow:hover,
    .dark-version.home-one .cta-item .details-btn:hover,
    .dark-version.home-one .social-style-one a:not(:hover),
    .dark-version.home-one .project-item .details-btn:not(:hover) {
      color: #fc653c; }
    .dark-version.home-one .slick-arrow {
      background: #fc653c; }
    .dark-version.home-one .footer-bottom,
    .dark-version.home-one .pricing-item-two,
    .dark-version.home-one .slick-arrow:hover,
    .dark-version.home-one .contact-area-form,
    .dark-version.home-one .cta-item .details-btn:hover,
    .dark-version.home-one .widget_newsletter form input,
    .dark-version.home-one .feature-item-five:not(:hover),
    .dark-version.home-one .social-style-two a:not(:hover) {
      background: #17171a; }
    .dark-version.home-one .count-text,
    .dark-version.home-one .slick-arrow,
    .dark-version.home-one .form-control,
    .dark-version.home-one .pricing-item-two .save,
    .dark-version.home-one .pricing-item-two .save span,
    .dark-version.home-one .widget_newsletter form input,
    .dark-version.home-one .testimonial-item-two .designation {
      color: white; }
    .dark-version.home-one .cta-item .details-btn:hover,
    .dark-version.home-one .counter-item.style-two:hover {
      border-color: #0d0d0e; }
    .dark-version.home-one .project-three-active .project-item .project-content {
      -webkit-box-shadow: 5px 0 15px rgba(252, 101, 60, 0.25);
      box-shadow: 5px 0 15px rgba(252, 101, 60, 0.25); }
    .dark-version.home-one .work-process-item .icon .number {
      -webkit-text-stroke-color: white; }
    .dark-version.home-one .pricing-item-two {
      -webkit-box-shadow: 10px 0 60px rgba(252, 101, 60, 0.1);
      box-shadow: 10px 0 60px rgba(252, 101, 60, 0.1); }
    .dark-version.home-one .form-control,
    .dark-version.home-one .widget_newsletter,
    .dark-version.home-one .pricing-item-two .title,
    .dark-version.home-one .counter-item.style-two:not(:hover) {
      border-color: rgba(255, 255, 255, 0.1); }
    .dark-version.home-one .for-middle-border:after,
    .dark-version.home-one .for-middle-border:before {
      background: rgba(255, 255, 255, 0.1); }
    .dark-version.home-one .slick-arrow:hover,
    .dark-version.home-one .social-style-two a:not(:hover) {
      -webkit-box-shadow: 10px 0 60px rgba(140, 140, 140, 0.3);
      box-shadow: 10px 0 60px rgba(140, 140, 140, 0.3); }
    .dark-version.home-one .form-control {
      background: transparent; }
    .dark-version.home-one .main-footer {
      background: #1e1e22; }
      p{
        font-family: "Catamaran", sans-serif;
      }

      .fixed-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000; 
      }
      
  .webcircle{
    background-color: #76ada0 !important;
  }
  .webLearnbtn{
    background-color: #78b6ab !important;
  }
 .webLearnbtn::after{
    background-color: #76ada0 !important;
  }
  .webli li::before{
    color: #76ada0 !important;
  }
  .web-createbg{
    background-color: #76ada0 !important;
  }
  .mobile-banner{
    background-color: #b1bef3 !important;
  }
  .mobilecircle{
    background-color: #93a3e4 !important;
  }
  .mobileli li::before{
    color: #93a3e4 !important;
  }
  .mobileLearnbtn{
    background-color: #b1bef3 !important;
  }
  .mobileLearnbtn::after{
    background-color: #93a3e4 !important;
  }
  .mobileLearnbtn2{
    background-color: #fff !important;
    color: #000 !important;
  }
  .mobileLearnbtn2::after{
    background-color: #93a3e4 !important;
  }
   /* .webLearnbtn:after{
    background-color: #93a3e4 !important;
  } */
  .mobile-createbg{
    background-color: #b1bef3 !important
  }
  .software-bg{
    background-color: #c8ad94 !important;
  }
  .softwarecircle{
    background-color: #c1a184 !important;
  }
.softwareHEad{
  color: #c1a184 !important;
}
.softwareLearn{
  background-color: #c8ad94 !important;
}
.softwareLearn::after{
  background-color: #c1a184 !important;
}
.softback{
  background-color: #c8ad94 !important;
}
.softwareget{
  background-color: #fff !important;
  color: #000 !important;
}
.softwareget::after{
  background-color: #c1a184 !important;
}
.softicon{
  background-color: #c8ad94 !important
}
.softicon:hover{
  background-color: #c1a184 !important;
}
.softiconbor::before{
  border-color: #c1a184 !important;
}
.devops-banner{
  background-color: #E17779;  
} 
.devops-circle{
  background-color: #D15D54 !important;  
}
.devops-screen1{
  background-color:  #E17779 !important;
}
.digital_head{
  color: #E17779 !important;
}
.devopslearn{
  background-color:  #E17779 !important;
}
.devopslearn::after{
  background-color: #D15D54 !important;  
}
.devopsHov:hover{
  background-color:  #E17779 !important;
}
.hov-boxDevops{
  color: #E17779 !important;
}
.devopsHov:hover .hov-boxDevops{
  color: #fff !important;
}
.devopsli li::before{
  background-color: #E17779 !important;
}
.devopslearnCall{
  background-color: #fff !important;
  color: #000 !important;
}
.devopslearnCall::after{
  background-color: #D15D54 !important;
}
.digital-banner{
  background-color: #aac47a !important;
}
.digitalcircle{
  background-color: #8dad52 !important;
}
.digitalHead{
  color:#aac47a !important;
}
.digitaluL li::before{
  background-color: #aac47a !important;
}
.digitalLearnbtn{
  background-color: #fff !important;
  color: #000 !important;
}
.digitalLearnbtn::after{
  background-color: #8dad52 !important;
}
.digitalHovA{
  color: #fff !important;
}
.digitalHovA:hover{
  color: #8dad52 !important;
}
.cloud-banner{
  background-color: #ecbe88 !important;
}
.cloudcircle{
  background-color: #ecb880 !important;
}
.cloudHead{
  color: #ecbe88 !important;
}
.cloudul li::before{
  background-color: #ecbe88 !important;
}
.cloudLearnbtn{
  background-color: #fff !important;
  color: #000 !important;
}
.cloudLearnbtn::after{
  background-color: #ecb880 !important;
}
.cloudboxHov .cloudboxicon{
  color: #ecb880 !important;
}
.cloudboxHov:hover {
  background-color: #ecb880 !important;
}
.cloudboxHov:hover .cloudboxicon{
  color: #fff !important;
}
.cloudlearn{
  background-color: #ecbe88 !important;
}
.cloudlearn::after{
  background-color: #ecb880 !important;
}
.aboutCall{
  background-color: #74b7c2 !important;
}
.aboutget{
  background-color: #fff !important;
  color: #000 !important;
}
.aboutget::after{
  background-color: #98d4de !important;
}
.main-header.header-two.fixed-header .header-upper1{
  background-color: #00000052 !important;
}
.row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.proj-Client{
  color: #ff497c !important;
  font-weight: 800;
}
.projP-client{
  color: #fff !important;
  font-weight: 500;
}
.convenience{
  font-weight: 600;
}
.proj-Para{
  list-style-type: none;
}
.proj-Para::before {
  content: '\2B9A'; 
  color: #c899bb !important; 
  margin-right: 8px; 
  margin-top: 2px; 
}
.convenience{
  list-style-type: none;
}
.convenience::before{
  content: '\2B9A'; 
  color: #c899bb; 
  margin-right: 8px; 
  margin-top: 2px;
}
.dotanimationProjectLast{
  width: 100%;
  background-color: #ff497c;
  height: 1px;
}
.line{
  top: 10px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #c899bb;
  z-index: 99999;
  overflow: hidden;
}
.dot{
  position: absolute;
  height: 5px;
  width: 5px;
  background: #fff;
  animation: dotleft 5s cubic-bezier(.72,.03,.75,.54) infinite;
  overflow: hidden;
}
.dot1{
}
.dot2{
  animation-delay: 2.4s;
}
.dot3{
  animation-delay: 2.8s;
}
.dot4{
  animation-delay: 5.2s;
}
.dot5{
  animation-delay: 5.6s;
}
@keyframes dotleft {
  0% {
      left: 10%;
  }
  100% {
      left: 90%;
  }
}
@media(max-width:767px){
  .proglogo{
      width: 8% !important;
  }
  .proglogo1{
      width: 7% !important;
  }
}
.webBoxlearnCall{
  background-color: #fff !important;
  color: #000 !important;
}
.webBoxlearnCall::after{
  background-color: #76ada0 !important;
}
.aboutBanner{
  background-color: #98d4de !important;
}
.aboutcircle{
  background-color: #74b7c2 !important;
}
.aboutHead{
  color: #98d4de !important;
}
.aboutbtn{
  background-color: #98d4de !important;
}
.aboutbtn::after{
  background-color: #74b7c2 !important;
}
.abouticon{
  color: #74b7c2 !important;
}
.contactBanner{
  background-color: #ffdb62 !important;
}
.contactCircle{
  background-color: #f4c939 !important;
}
.contactHead{
  color: #f4c939 !important;
}
.ConsubmitBtn{
  background-color: #ffdb62 !important;
  color: #000 !important;
}
.ConsubmitBtn::after{
  background-color: #f4c939 !important;
}
.contacticons{
  color: #f4c939 !important;
}
.iconContact{
  background-color: #fff !important;
}
.iconContact:hover{
  background-color: #f4c939 !important;
}
.iconContact:hover .contacticons{
  color: #fff !important;
}
.Projectbanner{
  background-color: #d5aac9 !important;
}
.projectCircle{
  background-color: #c899bb !important;
}
.proj-Client{
  color: #c899bb !important;
}
.projectlearnCall{
  background-color: #fff !important;
  color: #000 !important;
}
.projectlearnCall::after{
  background-color: #c899bb !important;
}
p{
  line-height: 24px !important;
}
html {
  scroll-behavior: smooth;
}
.projectSub-title{
  font-size: 25px;
  font-weight: 600;
  color: #293043;
}
.css-13cymwt-control{
  background-color: transparent !important;
  color: #fff !important;
}
.css-t3ipsp-control{
  background-color: transparent !important;
  color: #fff !important;
  border: #fff !important;
  box-shadow: none !important;
}
.css-1xc3v61-indicatorContainer{
  color: #000 !important;
}
.css-1nmdiq5-menu{
  background-color: #293043 !important;
}
.list-style-two a{
  color: rgba(0, 0, 0, .87) !important;
}
.list-style-two a:hover{
  color: #fc653c !important;
}
.continue{
  color: blue !important;
  font-weight: 600 !important;
}
@media(max-width:768px){
  .css-3nwxr6{
    width: 350px !important;
  }
}
.partner-head{
  font-size: 16px;
  font-weight: 700;
}
