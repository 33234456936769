.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.privacy-policy {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.subsection {
  margin-left: 1rem;
}

h1, h2, h3 {
  color: #333;
}

ul {
  margin-left: 2rem;
}
/* rohit */
.read-btn{
  color: white !important;
  background-color: #fc653c !important;

}

.card-div {
  margin: 0 auto;
  padding-top: 100px; 
  padding-bottom: 80px;
}

.cont-div {
  padding-top: 150px;
}

.blog-container {
  max-width: 90% !important;
  margin: 5rem auto;
  padding: 20px;
}

.blog-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;

}

.blog-image {
  height: 80vh !important;
  width: 100%;
  object-fit: cover; 
  object-position: center;
  margin-bottom: 10px;
  border-radius: 10px;
}


.blog-contents h3 {
  margin-top: 20px;
  font-weight: bold;
}

.blog-contents p {
  line-height: 1.8;
  font-size: 1.1rem;
}

.first-indent {
  text-indent: 30px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  justify-content: center;
  /* text-align: center; */
}

.col {
  display: flex;
  justify-content: center;
}
.bl{
  color: #000;
}